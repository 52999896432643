@import '../../../assets/scss/colors_new';

.add-to-pl-confirmation-dialog {
	.modal-dialog {
		max-width: 900px;
		margin: 1.75rem auto;
		text-align: center;

		.modal-title {
			display: flex;
			flex-direction: column;
			// gap: 5px;

			.warning-icon {
				font-size: 50px;

			}

			.title-process {
				font-weight: 800;
			}
		}

		.modal-body {
			display: flex;
			flex-direction: column;

			gap: 10px;

			p.message {
				margin-top: -1rem;
				font-weight: 500;
			}

			ul.products {
				font-size: 1rem;
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 15px;
			}
		}

		.table-process {
			border-bottom: 1px solid #e3e6f0 !important;
			border-top: 1px solid #e3e6f0 !important;
		}

		.form-inp {
			font-weight: 600;
		}

		.btn-add-row {
			font-weight: 600;
		}

		.total-process {
			font-weight: 600;
		}

		.btn-delete-row {
			background: transparent;
			border: none;
			box-shadow: none;
			outline: none;
			color: #192138 !important;
			font-size: 12px;
			padding: 5px;
			cursor: pointer;
			height: 25px;
			width: 25px;
			border: 2px solid #192138;
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50px;
			font-weight: 600;
		}

		.modal-actions {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		.btn-confirm {
			border-color: $color-primary;
			color: $color-primary;
			background-color: $color-light-max;

			&:hover {
				border-color: $color-primary;
				background-color: $color-primary;
				color: $color-light-max;
			}

			&:focus {
				border-color: $color-primary;
				background-color: $color-primary;
				color: $color-light-max;
			}
		}
	}

	.product-list {
		width: 100%;
		font-size: 12px;
		padding: 5px;
	}

	.product-section {
		margin-top: 1rem;
		text-align: left;
		font-size: 13px;
		width: 100%;
		background-color: #f2f2f2;
		border-radius: 6px;
		padding: 10px;

		label {
			padding-left: 5px;
			font-weight: 600;
		}
	}

	.underline-italic {
		text-decoration: underline;
		font-style: italic;
		font-weight: 600;
	}

	.space_between {
		justify-content: space-between;
	}

	.justify-end {
		justify-content: end;
	}

	.justify-center {
		justify-content: center;
	}

	.flex-direction-row {
		flex-direction: row !important;

	}

	.blur-container {
		position: relative;
	}

	.blur-container::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		backdrop-filter: blur(10px);
		z-index: -1;
	}
}