@import '../../assets/scss/colors';
@import '../../assets/scss/colors_new';

.currency-component {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: $color-text-1;
    width: 100%;

    .currencyText {
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;

        --webkit-user-select: none;
        --ms-user-select: none;
        user-select: none;
        padding: 10px;
        border: 1px solid $currency-border;
        border-radius: 50px;
    }

    .currencySelectDropdown {
        position: absolute;
        min-width: 170px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 100%;
        background-color: $color-scale-gray-8;
        padding: 10px;
        display: grid;
        grid-template-columns: 100%;
        border-radius: 5px;
        z-index: 100;
        gap: 5px;
        border: 0.5px solid $color-scale-gray-5;
        animation: slideIn 0.3s ease-out;

        .currency-item {
            padding: 10px;
            display: flex;
            gap: 10px;
            align-items: center;
            background-color: $color-scale-gray-9;
            border-radius: 5px;
            cursor: pointer;

            --webkit-user-select: none;
            --ms-user-select: none;
            user-select: none;

            &:hover {
                background-color: $color-scale-gray-6;
            }

            &.active {
                background-color: $color-scale-gray-7;
            }
        }
    }

    &.collapsed {
        .currencyText {
            border: none;
            padding: 0;
        }

        .currencySelectDropdown {
            padding: 0;
            min-width: unset;

            .currency-item {
                img {
                    display: none;
                }
            }

        }

        
    }
}

.lightMode {
    .currency-component { 
        color: $color-scale-gray-6;
    }

    .currencySelectDropdown { 
        background-color: #fff;
        border: none;
        box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;

        .currency-item {
            background-color: #fafafa;

            &:hover {
                background-color: #e5e5e5;
            }

            &.active {
                background-color: #eeeeee;
            }
        }

    }

}

@keyframes slideIn {
    0% {scale: 1;}
    50% {scale: 1.01;}
    100% {scale: 1;}
}