.btnDel.iconbtn.dropdown {
	display: contents;
}

.btnDel.iconbtn.dropdown .dropdown-toggle:after {
	display: none;
}

.card-shadow {
	box-shadow: 0 0 0 1px hsl(228, 16%, 93%), 0 1px 4px 0 rgba(21, 7, 38, 0.09) !important;
}


.mt6 {
	margin-top: 6px !important;
}

.mt-8 {
	margin-top: -8px !important;
}

.mt10 {
	margin-top: 10px;
}

.mt15 {
	margin-top: 15px;
}

.mt20 {
	margin-top: 20px;
}

.mt30 {
	margin-top: 30px;
}

.ml6 {
	margin-left: 6px;
}

.ml8 {
	margin-left: 8px;
}

.ml10 {
	margin-left: 10px;
}

.ml17 {
	margin-left: 17px;
}

.ml18 {
	margin-left: 18px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb20 {
	margin-bottom: 20px;
}

.product-preview-stock-labels {
	div {
		font-weight: 600;
		font-size: 13px;
	}
}

.product-header-summary {
	position: absolute;
	left: 130px;
}

.product-section-head {
	padding-bottom: 10px;
	/* border-bottom: 0.5px solid #e8e8ed; */
	margin-bottom: 5px;
}

.minHeight198 {
	min-height: 198px;
}

.fw600 {
	font-weight: 600;
}

.fw500 {
	font-weight: 500;
}

.fw400 {
	font-weight: 400;
}

.custom-card-padding {
	padding: 13px 15px 0px 15px;
}