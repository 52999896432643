.segregation-print-preview-content {
	width: 100%;
	color: #000;
	font-size: 1em;
    position: relative;

	.main-title {
		text-align: center;
		font-size: 1.1em;
		font-weight: 500;
		margin-bottom: 1em;
	}

	.detail-table {
		width: 100%;

		& > tr > td {
			width: 50%;

			table {
				width: 100%;

				td {
					width: 50%;
				}
			}
		}

        .barcode-div { 
            svg {
                width: 100%;
            }
        }
	}

    .actual-order-table {
        width: 100%;
        border: 1px solid black;
        margin-top: 1em;

        th {
            font-weight: 500;
        }

        thead > tr > th {
            font-weight: 600;
        }

        td, th {
            padding: 5px 5px;
            font-size: 0.9em;
        }

        tr > th {
            font-size: 0.9em;;
        }


        tr > td:first-child {
            text-wrap: nowrap;
        }
    }

    .status-table {
        width: 100%;
        border: 1px solid black;
        margin-top: 1em;

        th {
            font-weight: 500;
        }

        thead > tr > th {
            font-weight: 600;
        }

        td, th {
            padding: 5px 5px;
            font-size: 0.9em;
        }

        tr > th {
            font-size: 0.7em;;
        }

        tr > th:first-child,  tr > th:nth-child(2) {
            font-size: 0.9em;;
        }

        tr > td:first-child {
            text-wrap: nowrap;
        }
    }

    .notes-table {
        width: 100%;
        border: 1px solid black;
        margin-top: 1em;

        th {
            font-weight: 500;
        }

        thead > tr > th {
            font-weight: 600;
        }

        td, th {
            padding: 5px 5px;
            font-size: 0.9em;
        }

        tr > th {
            font-size: 0.7em;;
        }

        tr > th:first-child,  tr > th:nth-child(2) {
            font-size: 0.9em;;
        }

        tr > td:first-child {
            text-wrap: nowrap;
        }

    }
}

.print-processing-form {
    padding: 50px;
    font-size: 20px;
}