@import '../../assets/scss/colors';

.contact-details-section {
	.contact-detail-side {
		display: flex;
		flex-direction: column;

		.contact-detail-head {
			display: flex;
			flex-direction: column;
			gap: 5px;
			align-items: center;

			h2,
			p {
				padding: 0;
				margin: 0;
			}

			.contact-name {
				font-size: 18px;
				font-weight: 600;
			}

			.contact-sm {
				display: flex;
				align-items: center;
				gap: 5px;
				color: $color-light-scale-gray-4;
			}

			.contact-head-btns {
				margin-top: 10px;
				display: flex;
				justify-content: center;
				flex-direction: row;
				gap: 10px;

				span {
					color: $color-light-scale-gray-4 !important;
				}
			}

			padding-bottom: 10px;
			border-bottom: 1px solid $color-light-scale-gray-2-2;
			margin-bottom: 10px;
		}

		.contact-detail-item {
			label,
			p,
			span {
				padding: 0;
				margin: 0;
			}

			label {
				color: $color-light-scale-gray-4;
				margin-bottom: 5px;
			}

			p {
				line-height: 1.5;

				&.vat {
					display: flex;
					align-items: center;
					gap: 5px;
				}
			}

			padding-inline: 10px;

			padding-bottom: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid $color-light-scale-gray-2-2;

			&:last-of-type {
				border-bottom: none;
				// padding-bottom: 0;
			}
		}

		.sub-head {
			font-weight: 400;
		}

		.contact-cps {
			display: flex;
			flex-direction: column;
			position: relative;

			.contact-cp {
				display: flex;
				flex-direction: column;

				* {
					margin: 0;
					padding: 0;
				}

				gap: 5px;

				.cp-key {
					color: $color-light-scale-gray-4;
					font-size: 11px;
				}

				.cp-name {
					font-weight: 500;
				}

				.contact-cp-actions {
					position: absolute;
					right: 5px;
					top: 5px;
					display: flex;
					gap: 3px;
				}

				border: 1px solid $color-light-scale-gray-2-2;
				padding: 5px;
				border-radius: 5px;
			}
		}
	}

	.contact-actions-area {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 5px;
		width: 100%;
	}
}

.modal-close {
	display: flex;
	justify-content: end;
	font-size: 20px;
	cursor: pointer;
}

.main-content-client-modal {
	width: 70%;
	margin: auto;
}

.modal-client {
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}

.projectmix-table-vertical-client {
	width: 100%;
	max-width: 100%;
	border-collapse: collapse;
	margin-bottom: 1.25rem;
	empty-cells: show;
	font-size: 13px !important;
}

.projectmix-table-vertical-client tbody {
	display: table-row-group;
	vertical-align: middle;
	border-color: inherit;
}

.projectmix-table-vertical-client tr {
	display: table-row;
	vertical-align: inherit;
	border-color: inherit;
}

.projectmix-table-vertical-client th {
	font-weight: 300;
	font-size: 13px;
	color: #000000;
	text-align: left;
	white-space: nowrap;
	width: 12rem;
}

.projectmix-table-vertical-client td {
	padding: 0.375rem 0.375rem;
	vertical-align: top;
}

.message-client {
	font-size: 11px !important;
}

.draft {
	border-left: 8px solid #848484;

	.draftColumn {
		color: #848484;

		.IconDiv {
			.draftIcon {
				color: #848484;
			}
		}
	}
}

.estimated {
	.estimatedColumn {
		.colored-right-line::after {
			background-color: #00398f;
		}

		color: #00398f;

		.IconDiv {
			.estimatedIcon {
				color: #00398f;
			}
		}
	}
}

.expired {
	.expiredColumn {
		.colored-right-line::after {
			background-color: #f46b5b;
		}

		color: #f46b5b;

		.IconDiv {
			.expiredIcon {
				color: #f46b5b;
			}
		}
	}
}

.centerText {
	p {
		margin-bottom: 0;
	}
}

.Estimates-Contacts {
	display: flex;
	margin-top: 5px;
	padding: 0 10px 0 0;
	border-radius: 5px;
	max-height: 45px;

	// color: #848484;
	.column {
		flex: 1;
		padding-left: 0px;
		display: flex;
		justify-content: center;

		.colored-right-line {
			position: relative;

			height: 100%;
			background-color: #f0f0f0;
		}

		.colored-right-line::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 5px;
			/* Adjust the width of the right line */
			height: 100%;
			border-radius: 20px;
		}

		.IconDiv {
			height: 100%;
			flex: 0 0 15%;
			background-color: #f5f5f5;

			.Icon {
				height: 25px;
				margin-top: 20%;
				margin-left: 25%;
				width: 50%;
			}
		}

		.EstimateNumberType {
			flex: 0 0 80%;
			margin-left: 2%;
			overflow: hidden;

			.EstimateNumber {
				text-transform: uppercase;
				font-weight: 500;
				padding: 2px 0;
				font-size: 1rem;
				color: #000000 !important;
			}
		}

		.EstimateNumberDate {
			text-align: right;
			margin-left: auto;

			.EstimateNumber {
				text-transform: uppercase;
				font-weight: 500;
				padding: 2px;
				font-size: 1rem;
				color: #000000 !important;
			}
		}
	}

	.column-center {
		flex: 1;
		padding-left: 0px;
		display: flex;
		justify-content: center;

		.centerText {
			text-align: center;
			justify-content: center;
			width: fit-content;
			margin: auto 0;
			font-size: 1rem;
		}
	}
}

.Estimates-Contacts:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.DocumentDetailsTable {
	.DocumentDetailsTableHead {
		background-color: $color-light-scale-gray-2;

		.mr-5 {
			margin-left: 1rem;
		}
	}

	.DocumentDetailsTableBody {
		cursor: pointer;
	}

	.center {
		text-align: center;
		vertical-align: middle;

		.centerText {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
		}
	}
}

.documentTopBar {
	display: flex;
	width: 100%;
	height: 35px;
	padding: 0 6px;

	.left {
		display: flex;
		flex: 1;
		color: #9db1bd;
		margin-top: 7px;
		font-size: 16px;

		.text {
			font-size: 13px;
			padding-left: 10px;
			cursor: pointer;
		}
	}

	.right {
		flex: 1;
		text-align: right;
		font-size: 24px;

		.Iconfill {
			color: $color-light-scale-gray-3;
			border: 1px solid $color-light-scale-gray-3;
			width: 30px;
			height: 30px;
			border-radius: 5px;
			cursor: pointer;
			float: right;

			.addFolderIcon {
				padding: 3px;
				margin-top: -11px;
				margin-right: 2px;
			}
		}
	}
}

.dropdownDocuments {
	cursor: pointer;

	.VerticalHam {
		font-size: 21px;
	}
}

.colorgrey {
	// color: #848484 !important;
	font-size: 1rem !important;
}

.folder {
	background-color: #9db1bd;
}

.image {
	background-color: #91c3e3;
}

.broken {
	background-color: #e8a03c;
}

.pdf {
	background-color: #fc7c74;
}

.excel {
	background-color: #077d3f;
}

.csv {
	background-color: #84bca4;
}

.word {
	background-color: #05438f;
}

.modalImage {
	width: 100%;
	height: 400px;
}

.imageName {
	text-align: center;
	font-size: 13px;
	color: #333;
	width: 100%;
	position: absolute;
	top: 15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.btnCancel {
	text-align: right;
	margin-top: 7px;
	margin-left: 5px;
	font-size: 24px;
	display: flex;
	cursor: pointer;
}

.NameClass {
	display: flex;

	.IconFolder {
		width: 30px;
		height: 30px;
		border-radius: 5px;
		margin-top: 4px;

		.folderIcon {
			color: #fff;
			font-size: 18px;
			margin-left: 20%;
			margin-top: 20%;
		}
	}

	.NameFolder {
		margin-left: 1rem;
		margin-top: 10px;
		color: #000000;
		font-size: 1rem;
	}
}

.status-Success {
	.colored-right-line::after {
		background-color: #569e8d;
	}

	.IconDiv {
		.Icon {
			color: #569e8d;
		}
	}

	.status {
		color: #569e8d !important;
	}
}

.status {
	text-align: center;
	justify-content: center;
	width: fit-content;
	margin: auto;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 1rem;
}

.status-Default {
	.colored-right-line::after {
		background-color: #848484;
	}

	.IconDiv {
		.Icon {
			color: #848484;
		}
	}

	.status {
		color: #569e8d !important;
	}
}

.status-Warning {
	.colored-right-line::after {
		background-color: #f7a05a;
	}

	.IconDiv {
		.Icon {
			color: #f7a05a;
		}
	}

	.status {
		color: #f7a05a !important;
	}
}

.status-Danger {
	.colored-right-line::after {
		background-color: #f46b5b;
	}

	.IconDiv {
		.Icon {
			color: #f46b5b;
		}
	}

	.status {
		color: #f46b5b !important;
	}
}

.Invoice-Contacts {
	display: flex;
	margin-top: 5px;
	padding: 0 10px 0 0;
	border-radius: 5px;
	max-height: 44px;

	.column-1 {
		flex: 2;
		padding-left: 0px;
		display: flex;

		.colored-right-line {
			position: relative;
			height: 100%;
			background-color: #f0f0f0;
		}

		.colored-right-line::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 5px;
			height: 100%;
			border-radius: 20px;
		}

		.IconDiv {
			height: 100%;
			flex: 0 0 15%;
			background-color: #f5f5f5;

			.Icon {
				height: 25px;
				margin-top: 20%;
				margin-left: 25%;
				width: 50%;
			}
		}

		.EstimateNumberType {
			flex: 0 0 85%;
			overflow: hidden;

			.EstimateNumber {
				text-transform: uppercase;
				font-weight: 500;
				padding: 2px;
				font-size: 1rem;
				margin-left: 5px;
				color: #000000 !important;
			}

			.EstimateType {
				color: #848484;
				margin-left: 5px;
			}
		}
	}

	.column-2 {
		flex: 1;
		padding-left: 0px;
		display: flex;

		// color: #848484;
		.centerText {
			text-align: center;
			justify-content: center;
			width: fit-content;
			margin: auto;
			font-weight: 500;
			font-size: 1rem;
			display: flex;
			text-transform: capitalize;
			align-items: center;
		}

		.EstimateNumberDate {
			text-align: right;
			margin-left: auto;

			.EstimateNumber {
				text-transform: uppercase;
				font-weight: 500;
				padding: 2px;
				font-size: 1rem;
				color: #000000 !important;
			}
		}
	}
}

.EstimateType {
	color: #848484;
}

.Invoice-Contacts:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.invoicedetailsText {
	display: flex;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-left: 2px;
}

.Folders {
	text-align: center;

	.folderIcon {
		color: #3285a8;
		width: 50px;
		height: 50px;
		margin: auto;
	}
}

.Directorytree {
	padding: 20px;
	height: 870px;

	.BreadCrumbs {
		text-align: right;
		overflow: hidden;
		text-wrap: nowrap;
		text-overflow: ellipsis;
		font-size: 1rem;
	}

	.sizeFile {
		max-width: 15%;
		margin: 5px;
	}

	.imageIcon {
		width: 50px;
		height: 50px;
	}

	.file_name {
		font-size: 10px;
	}
}

.DragDrop {
	height: 92%;
	position: absolute;
	width: 96%;
	bottom: 0;
	margin-bottom: 15px;
}

.DragDropAvailable {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;

	.dragIconText {
		text-align: center;
		opacity: 0.5;

		.CloudIcon {
			font-size: 100px;
			margin: auto;
			padding: 10px;
			color: $color-light-scale-gray-3;
		}

		.dragText {
			color: $color-light-scale-gray-3;
			font-size: 16px;
		}
	}
}

.newrow {
	width: 100%;
}

.createDirectoryButtons {
	margin-right: 7px;
}

.imagewidth {
	width: 100%;
	text-align: center;
}

.image-model {
	height: auto;
	padding: 0 10px;
	max-width: 100%;
}

.top-image {
	display: flex;
	flex-wrap: wrap;
	height: 50px;
}

.btnDownload {
	justify-content: center;
	padding: 15px 0;
	display: flex;
	flex-wrap: wrap;
}

.backgroundData {
	height: 870px;
}
