@import '../../assets/scss/colors';

@media (max-width: 1360px) {
	.custom-col {
		width: 100%;
	}

	.custom-col-filter {
		width: 100%;
	}

	.custo {
		margin-bottom: 1rem;
	}
}

@media (min-width: 1360px) {
	.custom-col {
		width: 40%;
	}

	.custom-col-filter {
		width: 60%;
	}
}

@media (max-width: 680px) {
	.btn-area {
		display: block;
	}

	.btn-date {
		width: 100%;
	}

	.btn-create-report {
		width: 50%;
	}
}

.shadow-sm {
	.card {
		width: 100%;
		padding: 20px;
		border: 1px solid #ccc !important;
		border-radius: 8px;
		// box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
		margin: 20px 0;
		border-top-color: #2c77da !important;
		height: 160px;
		cursor: pointer;

		.heading {
			text-transform: capitalize;
		}

		.para {
			font-size: 11px;
			color: #888181;
		}

		.neon {
			font-size: 1rem;
			color: #2c77da;
			background-color: #d0effa;
			max-width: max-content;
			padding: 0 10px;
			text-align: center;
			border-radius: 5px;
		}
	}

	.bread-crumb {
		cursor: pointer;
	}

	.date-range-picker {
		position: absolute;
		background-color: #fff !important;
		z-index: 999;
		right: 0;
		top: 40px;
	}

	.btn-date {
		width: 240px;
		margin: 0 0 0 auto;
	}

	.btn-pre-cre {
		display: flex;
		gap: 5px;
	}

	.custo {
		padding: 10px;
	}

	.btn-area {
		display: flex;
		gap: 5px;
		justify-content: flex-end;
		// display: grid;
		// grid-template-columns: 60% 20% 20%;
		// gap: 5px;
	}

	// .vh-100{
	//     height: 10vh;
	// }
	.Filter-to-access {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 60vh;
		gap: 10px;
	}

	.generate-report-text {
		text-align: center;
		font-size: 13px;
	}

	.btn-filled-main {
		min-width: 100px;
		background-color: transparent;
		border: 1px solid #b8b8b8;
		color: #192138;
		text-align: center;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		font-size: 1rem;
		padding: 5px 10px;
	}

	.generate-report-icon {
		font-size: 46px;
	}

	.btn-group-cols {
		padding: 10px;
		display: flex;
		justify-content: flex-end;
	}

	.filter-cols {
		background-color: #fff;
		padding: 5px;
		position: absolute;
		inset: 0px 0px auto auto;
		border-radius: 5px;
		margin: 0px;
		transform: translate3d(-22.5px, 39.5px, 0px);
		box-shadow: 0 1px 3px rgba(27, 23, 30, 0.1);
		z-index: 3;

		ul {
			list-style-type: none;
			padding: 5px;
		}
	}

	.checkbox-row {
		display: block;
		clear: both;
		font-weight: 400;
		line-height: 20px;
		white-space: no-wrap;
		display: flex;
		justify-content: flex-start;
		gap: 5px;
		padding: 7px 5px;
		width: 120px;
	}

	.checkbox-row input[type='checkbox'] {
		display: none;
	}

	.cutomCheckbox {
		width: 16px;
		height: 16px;
		border: 2px solid rgb(25, 33, 56);
		border-radius: 4px;
		margin-right: 8px;
		cursor: pointer;
		position: relative;
	}

	.cutomCheckbox::after {
		content: '';
		width: 6px;
		height: 6px;
		background-color: #192138;
		border: 1px solid #192138;
		border-radius: 2px;
		position: absolute;
		top: 50%;
		left: 48%;
		transform: translate(-50%, -50%);
		//    opacity: 0;
		pointer-events: none;
	}

	.checkbox-row input[type='checkbox']:checked+.cutomCheckbox {
		// background-color: #33cc33;
		border-color: rgb(25, 33, 56);
	}

	.checkbox-row input[type='checkbox']:checked+.cutomCheckbox::after {
		opacity: 1;
	}

	.checkbox-row input[type='checkbox']:not(:checked)+.cutomCheckbox::after {
		opacity: 0;
	}

	.toggles {
		display: flex;
		justify-content: flex-end;
		padding: 10px;
		height: 50px;

		.toggle-value {
			// text-transform: lowercase !important;
			font-size: 1rem !important;
		}
	}

	.body-v2 {
		font-family: 'Inter', sans-serif;
		font-size: 87.5% !important;
		color: #141721;
		line-height: 1.5;
		text-rendering: optimizeLegibility;
	}

	.heading-v2 a {
		color: hsl(216, 85%, 57%);
		font-weight: 500;
		font-size: 1rem;
		line-height: 1.25;
	}

	.reports-index {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
		gap: 1rem;
	}

	.page-content {
		max-width: 72rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.para-v2 {
		font-size: 13px;
		line-height: 2.7;

		&:first-line {
			line-height: 0;
		}
	}

	.report-card {
		.icon {
			font-size: 16px;
			margin-right: 2px;
		}
	}

	.report-card p {
		display: block;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
	}

	.card-v2 {
		display: flow-root;
		background-color: #fff;
		margin-bottom: var(--box-margin-bottom);
		border-radius: 0.5rem;
		box-shadow: 0 0 0 1px hsl(228, 16%, 93%), 0 1px 2px 0 rgba(21, 7, 38, 0.09);
	}

	.card-section {
		padding: 1rem;
	}

	@media (min-width: 768px) {
		.page-content {
			max-width: 63rem;
			margin-left: auto;
			margin-right: auto;
		}

		.card-v2 {
			box-shadow: 0 0 0 1px hsl(228, 16%, 93%), 0 1px 4px 0 rgba(21, 7, 38, 0.09);
		}
	}
}

.section-today {
	display: flex;
	justify-content: center;
	gap: 5px;
	font-size: 1rem;
	text-align: center;
	margin-bottom: 0.875rem;
	color: #484e60;

	.icon-today {
		display: flex;
		vertical-align: middle;
		margin: auto 0;
	}

	p {
		margin: auto 0;
	}
}