@import './colors';
@import './colors_new';

.tableData .editBox.svb {
	width: auto !important;
}

.tableData .responsive-view {
	overflow-x: auto !important;
}

.tableData .responsive-view > table {
	width: 100% !important;
	min-width: 800px !important;
}

.found.tableData .responsive-view > table {
	width: 100% !important;
	min-width: 200px !important;
}

.privacyMode.on {
	color: transparent;
	display: block;
	background-color: rgba(0, 0, 0, 0.11);
	height: 1.2em;
	border-radius: 4px;
	-webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
	animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
}

.privacyMode.w200 {
	width: 200px;
}

.privacyMode.w100 {
	width: 100px;
}

.css-1ukovu3-MuiSkeleton-root {
	display: block;
	background-color: rgba(0, 0, 0, 0.11);
	height: 1.2em;
	border-radius: 4px;
	-webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
	animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
}

.tableData .responsive-view th,
.tableData .responsive-view td {
	white-space: nowrap !important;
}

.showdata-btn {
	cursor: pointer;
	text-decoration: underline;
}

.custom-modal .modal-dialog {
	max-width: 90%;
	max-height: 100%;
}

.custom-modal-two .modal-dialog {
	max-width: 80%;
	max-height: 100%;
}

.custom-modal-two .modal-content,
.custom-modal .modal-content {
	width: 100%;
	height: 100%;
}

/* Css  */
.movetopending {
	width: auto !important;
	padding: 0 11px !important;
}

.pac-container {
	z-index: 99999;
}

.assign.form-row {
	display: block;
	width: 100% !important;
}

.react-time-picker__wrapper {
	border: none !important;
}

.assign .custom-select__menu {
	z-index: 99999999999999999 !important;
	z-index: 99999999999999999 !important;
}

/* Date Style */

.datediv {
	background: transparent;
	--statusColor: grey;

	.boxDate {
		color: var(--statusColor);
		font-weight: 600;
		padding-block: 5px;
		padding-inline: 6px;
		border: 0.5px solid var(--statusColor);
		border-radius: 4px;
		font-size: 1rem;
	}
}

.disabled {
	pointer-events: none;
}

.pointer {
	cursor: pointer;
}

.user.vieweye {
	position: absolute;
	bottom: 19px;
	right: 25px;
	cursor: pointer;
}

.estimateCName .vatCheck .fa-times {
	position: absolute;
	right: 20px;
	top: 14px;
	color: #d8578a;
	cursor: pointer;
}

.estimatevat .vatCheck .fa-times {
	position: absolute;
	right: 20px;
	top: 14px;
	color: #d8578a;
}

.collaps {
	overflow-x: auto;
}

.collaps th,
.collaps td {
	white-space: nowrap;
}

.sort-sortBy .sort-list li label {
	margin: 0;
	cursor: pointer;
}

.suppliecreatepop .vatCheck .fa-times {
	position: absolute;
	right: 20px;
	top: 18px;
	color: #d8578a;
}

.user-filter.showAll select.showOption {
	width: 140px !important;
}

/* Back btn Design  */
/* add New product css */
.form-group.addproduct .custom-select__control {
	background-color: #1c1c24;
	color: #fafafb;
	height: 45px;
	border: none;
	width: 100%;
}

.form-group.multicreate .custom-select__control {
	background-color: #28282e;
	color: #fafafb;
	height: 60px;
	border: none;
	width: 100%;
}

.backbgcolor {
	background-color: #d8578a !important;
}

.suploader.spinner-border {
	position: absolute;
	left: 100px;
	top: 8px;
	width: 20px;
	height: 20px;
}

/* .text-area {
	background-color: #28282E;
	color: #B5B5BE;
	border: none;
	width: 100%;
} */

.text-area {
	background-color: #28282e;
	color: #b5b5be;
	border: none;
	width: 100%;
	transition: background-color 0.3s, color 0.3s;
}

.text-area:hover {
	background-color: #28282e;
	color: #b5b5be;
}

.text-area:focus {
	background-color: #28282e;
	color: #b5b5be;
	outline: none;
}

.copy {
	cursor: pointer;
	margin-top: 50px;
	margin-left: -20px;
}

/* position: absolute;
    left: 100px;
    top: 8px;
    width: 20px;
    height: 20px;
} */

.scroll-container {
	width: 100%;
	overflow-x: auto;
	white-space: nowrap;
	/* To prevent line breaks */
}

.scroll-content {
	display: inline-block;
}

.overflow-box {
	overflow-x: auto;
	white-space: nowrap;
	/* Prevent line breaks */
}

.storage-col {
	min-width: 50px;
}

.editBtn {
	background-color: #7cc3e6;
}

.editBtn:hover {
	background-color: #7eb6d2;
}

.btn {
	border-radius: 10px;
}

.login-text-gray {
	color: #b5b5be;
}

.tb_td_sub_display {
	display: flex;
	align-items: center;
	gap: 5px;
	color: #6e7681;
	font-size: 1rem;
}

.textGray {
	color: #6e7381;
}

.mt-5px {
	margin-top: -5px;
}

.mt-15px {
	margin-top: -15px;
}

.create-warehouse-container {
	display: flex;
	justify-content: center;

	.card {
		padding: 20px;
		margin-top: 20px;
		min-width: 768px;
		max-width: 768px;
		color: #000;
		font-size: 1rem;
	}

	.form-section-head {
		padding-bottom: 10px;
		border-bottom: 0.5px solid #e8e8ed;
		margin-bottom: 10px;

		h4 {
			font-size: 13px;
			margin: 0;
		}
	}

	.form-group {
		padding-left: 40px;
		margin-bottom: 5px;

		input,
		select:not(.PhoneInputCountrySelect),
		.rs-container,
		.vatCheck,
		.PhoneInput {
			width: 240px;
			color: #000 !important;
		}

		label {
			margin: 0;
			color: #000;
		}

		.form-group-body {
			display: grid;
			grid-template-columns: 30% 70%;
			align-items: center;
		}

		.form-group-error {
			padding-left: 30%;

			.error-message {
				font-size: 10px;
				position: relative;
			}
		}
	}
}

.prdct-Inactive {
	padding-top: 0 !important;
}

.prdct-inactiv-div {
	margin-top: -5px;
}

.prdct-inactiv-div-yes {
	display: flex;
	flex-direction: column;
	padding: 0px;
	margin: 0px;

	.no {
		margin-top: -5px;
	}

	span {
		font-size: 0.75rem;
		color: #484e60;
		margin-top: 0.375rem;
	}

	input {
		font-family: inherit;
		font-size: 100%;
		line-height: 1.15;
		margin: 0;
	}
}

.table-vertical-export th {
	width: 15rem !important;
	vertical-align: top;
	padding-top: 5px;
	padding-right: 12px;
}

.action-import {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

.prdct-active {
	padding-bottom: 0 !important;
}

.form-section-head {
	padding-bottom: 10px;
	border-bottom: 0.5px solid #e8e8ed;
	margin-bottom: 10px;
}

.product-card {
	padding: 10px;
	// border: 1px solid #ebebeb;
	border-radius: 10px;
	position: relative;
	color: #000;
	background: #f9f9f9;
}

.pad10-25 {
	padding: 10px 25px !important;
}

.pad25 {
	padding: 25px !important;
}

.font10 {
	font-size: 10px;
}

.font12 {
	font-size: 1rem;
}

.font18 {
	font-size: 18px;
}

.font16 {
	font-size: 16px;
}

.font14 {
	font-size: 13px !important;
}

.font13 {
	font-size: 13px;
}

.line-with-text {
	display: flex;
	align-items: center;

	.line-with-text-label {
		color: #a2adbc;
		font-style: italic;
	}

	.line-with-text-span {
		font-style: italic;
	}
}

.line {
	flex-grow: 1;
	border-top: 1px solid #a2adbc;
	/* Adjust color and style as needed */
	margin-left: 10px;
	/* Adjust the spacing between the line and text */
	margin-top: -14px;
}

.card {
	border-radius: 10px;
}

.product-thumbnail {
	max-width: 87px;
	width: 87px !important;
	min-height: 87px;
	display: flex;
}

.pad15 {
	padding: 15px;
}

.mt6 {
	margin-top: 6px !important;
}

.mt-8 {
	margin-top: -8px !important;
}

.mt10 {
	margin-top: 10px;
}

.mt15 {
	margin-top: 15px;
}

.mt20 {
	margin-top: 20px;
}

.mt30 {
	margin-top: 30px;
}

.ml6 {
	margin-left: 6px;
}

.ml8 {
	margin-left: 8px;
}

.ml10 {
	margin-left: 10px;
}

.ml17 {
	margin-left: 17px;
}

.ml18 {
	margin-left: 18px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb20 {
	margin-bottom: 20px;
}

.product-preview-stock-labels {
	div {
		font-weight: 600;
		font-size: 1rem;
	}
}

.product-header-summary {
	position: absolute;
	left: 130px;
}

.product-section-head {
	padding-bottom: 10px;
	/* border-bottom: 0.5px solid #e8e8ed; */
	margin-bottom: 5px;
}

.minHeight198 {
	min-height: 198px;
}

.minHeight230 {
	min-height: 230px;
}

.fw600 {
	font-weight: 600;
}

.fw500 {
	font-weight: 500;
}

.fw400 {
	font-weight: 400;
}

.product-warehouses {
	/* padding: 20px 0 10px; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 6px;
	// padding-left: 15px;
	margin-left: -15px;

	.actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
	}
}

.product-warehouse-card {
	border: 1px solid #b7bcc7;
	padding: 15px 15px 10px 18px;
	border-radius: 12px;
	margin-top: 15px;
}

.location-section-head {
	margin-bottom: 5px;
}

.thumbnail-display {
	padding: 60px 25px;
	border: 1px solid #d8dbe3;
	border-radius: 12px;
	margin-right: 15px;
}

.product-container-dragdrop {
	border: 2px dashed #d8dbe3;
	border-radius: 12px;
	background: #f6f6f9;
	font-size: 30px;
	color: #7a7f8b;
	padding: 20px 0px 10px 0px !important;
	font-weight: 500;
}

// .drag-drop-btn {
// 	min-width: 100px;
//     background-color: transparent;
//     border: 1px solid #d9d9d9;
//     color: #192138;
//     text-align: center;
//     border-radius: 5px;
//     // display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 5px;
//     font-size: 13px;
//     padding: 5px 10px;
//     z-index: 0;
//     border-radius: 10px !important;
//     background-color: #fff;
// }
.drag-drop-text {
	margin-left: 15px;
	font-size: 13px;
	color: #000;
	font-weight: 400;
	position: absolute;
	margin-top: 15px;
}

.span-action {
	cursor: pointer;
	color: #4e73df;
	font-size: 1rem;
	font-weight: 500;
}

.span-action:hover {
	color: #2d4ba8;
}

.pb10 {
	padding-bottom: 10px;
}

.v-align-middle {
	vertical-align: middle !important;
}

.drag-drop-btn {
	min-width: 100px;
	background-color: transparent;
	border: 1px solid $color-borders-4;
	background-color: $color-light-max;
	color: #192138;
	text-align: center;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 1rem;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
	background-color: #fff;

	&.white-var {
		background-color: #fff;
		border-color: $color-borders-4;
	}

	&.white-var {
		background-color: #fff;
		// border-color: $color-borders-4;
		position: relative;

		// &:hover {
		// 	background-color: transparent;
		// 	border-color: $color-borders-4;
		// 	color: $color-primary-2;
		// }

		&.white-var-success {
			border: $color-success 1px solid;
			color: $color-success;
		}
	}

	&:hover {
		background-color: $color-primary;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&:focus,
	&:focus-visible,
	&:active {
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3) !important;
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3) !important;
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-warning, $amount: 3) !important;
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-success, $amount: 3) !important;
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}

	&:disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}
}

.thumbnail-btn {
	min-width: auto !important;
	border: 1px solid $color-borders-4;
	background-color: $color-light-max;
	color: #192138;
	text-align: center;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 10px;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
	background-color: #fff;
	position: absolute;
	top: 0;
	margin-top: -8px;
	margin-left: -122px;

	&.white-var {
		background-color: #fff;
		border-color: $color-borders-4;
	}

	&.white-var {
		background-color: #fff;
		// border-color: $color-borders-4;
		position: relative;

		// &:hover {
		// 	background-color: transparent;
		// 	border-color: $color-borders-4;
		// 	color: $color-primary-2;
		// }

		&.white-var-success {
			border: $color-success 1px solid;
			color: $color-success;
		}
	}

	&:hover {
		background-color: $color-primary;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&:focus,
	&:focus-visible,
	&:active {
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3) !important;
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3) !important;
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-warning, $amount: 3) !important;
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-success, $amount: 3) !important;
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}

	&:disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}
}

.thumbnail-close {
	min-width: auto !important;
	border: 1px solid $color-borders-4;
	background-color: $color-light-max;
	color: #192138;
	text-align: center;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 11px;
	padding: 5px 8px;
	z-index: 0;
	border-radius: 60px !important;
	background-color: #fff;
	position: absolute;
	top: 0;
	margin-top: -22px;
	margin-left: 10px;

	&.white-var {
		background-color: #fff;
		border-color: $color-borders-4;
	}

	&.white-var {
		background-color: #fff;
		// border-color: $color-borders-4;
		position: relative;

		// &:hover {
		// 	background-color: transparent;
		// 	border-color: $color-borders-4;
		// 	color: $color-primary-2;
		// }

		&.white-var-success {
			border: $color-success 1px solid;
			color: $color-success;
		}
	}

	&:hover {
		background-color: $color-primary;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&:focus,
	&:focus-visible,
	&:active {
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3) !important;
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3) !important;
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-warning, $amount: 3) !important;
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-success, $amount: 3) !important;
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}

	&:disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}
}

.thumbnail-btn-active {
	background: #0066ff !important;
	color: #fff !important;
	border: 1px solid #0066ff !important;
}

.imei-list-item-cancel {
	position: absolute;
	right: 0;
	top: 0;
	margin-right: 50px;
	margin-top: 20px;
	cursor: pointer;
}

.mentioned-user {
	color: #42739B;
	text-decoration: underline;
	cursor: pointer;
	// padding-inline: 3px;

	// &::before {
	// 	content: '@';
	// }
}
