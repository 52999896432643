@import '../../assets/scss/colors';

.Order-number {
	margin-right: 0rem;
	color: #0065ff;
}

.Order-name {
	color: black;
	font-size: 13px;
	font-weight: 500;
}

.mr-rem {
	margin-right: 1.25rem;
}

.align-right {
	font-weight: 500;
	color: #484e60;
	vertical-align: bottom;
	text-align: right;
}

.comments-indication-column {
	font-size: 1rem;
}

.table-cell-indented {
	color: #0d1117 !important;
	margin-left: 2rem;
}

.backorder-unavailable {
	color: hsl(0, 75%, 60%);
}

.backorder-available {
	color: hsl(160, 100%, 32%);
}

.comments-indication-icon {
	color: #6e7381;
	margin: 0 0.25rem;
	cursor: pointer;
}

.text-center-custom {
	text-align: center !important;
}