@import '../../../assets/scss/colors_new';

.popup-bg {
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(11px);
	background-color: rgba(107, 122, 144, 0.2);
	position: absolute;
	width: 100%;
	// height: calc(100% - 46px);
	height: 100%;
	z-index: 999;
	left: 0;
	// top: 55px;
	top: 0px;
	// height: calc(100% - 46px);
}

.order-form {
	display: flex;
	padding-inline: 20px;
	width: 100%;
	gap: 20px;

	.est-detail-item {
		display: flex;

		label {
			flex: 200px;
			font-weight: 500;
		}

		p {
			flex: 100%;
			font-weight: 500;
		}
	}

	.col-inv {
		flex: auto;
		position: relative;
		max-width: 690px;

		.orf-head {
			margin: 0;
			padding: 0;
			padding-bottom: 20px;
			border-bottom: 1px solid $color-borders-2;
		}

		.add-contact-action {
			padding-block: 20px;
		}

		.est-contact-section {
			gap: 10px;
		}

		.contact-details {
			padding: 10px 20px 20px 5px;
			margin: 0;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		.contact-details-edit {
			// padding: 20px 20px;
			// margin: 0; 
			// position: absolute;
			// margin-top: -100px;
			// button {
			// 	min-width: none;
			// }
			/* padding: 20px 20px; */
			margin: 0;
			/* position: absolute; */
			// display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: -88px;
			margin-left: 10px;
			height: 90px;
		}

		.inv-table-container {
			margin-top: 30px;
		}

		.inv-footer-text {
			margin: 0;
			padding: 15px 0;
			color: $color-borders-1;
		}

		.inv-table {
			thead {
				tr th {
					border-top: 1px solid $color-borders-2;
				}
			}

			tbody {
				tr td {
					border-top: 1px solid $color-borders-2;
					padding: 12px 12px;
				}
			}

			tfoot {
				tr:first-child td {
					border-top: 1px solid $color-borders-2;
				}

				tr:last-child td {
					border-bottom: 1px solid $color-borders-2;
				}
			}

			.qty-col {
				max-width: 20%;
			}

			.product-col {
				max-width: 30%;
				text-wrap: wrap;
			}

			.price-col {
				max-width: 28%;
			}

			.vat-col {
				max-width: 7%;
			}

			.comment-col {
				max-width: 15%;
			}
		}
	}

	.inv-prods-add-table {

		th,
		td {
			padding: 6px 5px;
			// vertical-align: middle;
		}

		tbody tr {
			&:hover {
				background-color: transparent;
			}
		}

		button.btn-remove {
			display: flex;
			justify-content: center;
			outline: none !important;
			box-shadow: none !important;
			align-items: center;
			// background-color: $color-light-max;
			// border: 1px solid $color-borders-4;
			color: $color-dark-max;

			border-radius: 100% !important;
			padding: 5px;
			font-size: 1rem;
			border-radius: 10px;

			&:hover {
				// background-color: $color-primary;
				// border: 1px solid $color-primary;
				// color: $color-light-max;

				background-color: #e9e9e9;
				border: 1px solid #e9e9e9;
				color: $color-dark-max;
			}
		}

		.add-comment-link {
			height: 29px;
			display: block;
			display: flex;
			align-items: center;
			line-height: 1;
			font-size: 11px;
			padding-inline: 5px;
			margin: 0;
		}

		.comment-input {
			border-bottom: 1px solid #e0e0e0;
			width: 70%;
			display: flex;
			gap: 5px;
			padding: 3px 5px;
			font-size: 11px;

			input {
				border: none;
				outline: none;
				box-shadow: none;
				flex: 100%;
			}

			button {
				flex: 1;
				// border: none;
				// outline: none;
				// box-shadow: none;
				// background-color: transparent;

				display: flex;
				justify-content: center;
				outline: none !important;
				box-shadow: none !important;
				align-items: center;
				background-color: $color-light-max;
				border: 1px solid $color-borders-4;
				color: $color-dark-max;

				border-radius: 100% !important;
				padding: 5px;
				font-size: 10px;

				&:hover:not(.cls) {
					background-color: $color-primary;
					border: 1px solid $color-primary;
					color: $color-light-max;
				}

				&.cls {
					border: none;
					outline: none;
					box-shadow: none;
					background-color: transparent;
				}
			}
		}

		.qty-col {
			width: 9%;
		}

		.inv-col {
			width: 20%;
		}

		.model-col {
			// width: 30%;
			width: 44%;
		}

		.vat-col {
			width: 8%;
		}

		.uprice-col {
			width: 14%;
		}

		.tprice-col {
			width: 15%;
		}

		.remove-col {
			width: 5%;
		}
	}

	.col-details {
		// flex: 250px;
		// width: 250px;
		width: 100%;
		flex-grow: 0;
		padding-top: 75px;
	}

	.popup {
		position: absolute;
		top: 70px;
		// transform: translateY(-50%);
		// width: 100%;
		width: 700px;
		background-color: $color-light-max;
		z-index: 1000;
		border-radius: 10px;
		padding: 10px;
		animation: slideIn 0.3s ease-out;
		max-height: 90%;
		overflow: scroll;
		border-radius: 18px;

		&.agreement-popup {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
			max-height: unset;
			width: 100%;
			height: 100%;
			padding-inline: 15px;
			overflow: visible;

			p {
				font-size: 13px;
			}

			.agreement-img {
				width: 100px;
			}
		}
	}

	.product-popup {
		width: 800px;
	}

	.order-agreements-bg {
		-webkit-backdrop-filter: blur(4px);
		backdrop-filter: blur(4px);
		background-color: rgba(107, 122, 144, 0.2);
	}
}

.order-agreements-detail-section {
	padding: 10px 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-align: center;

	.order-agreements-detail {
		display: flex;
		flex-direction: column;
		gap: 5px;

		h6,
		p {
			margin: 0;
			padding: 0;

			font-size: 13px;
		}

		h6 {
			color: $color-text-1;
			font-weight: 500;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: -15px;
				transform: translateY(-50%);
				width: 8px;
				height: 8px;
				border-radius: 50%;
				// background-color: $color-primary;
			}
		}

		p {
			color: $color-text-2;
		}

		padding-left: 15px;
	}
}

@keyframes slideIn {
	0% {
		scale: 1;
	}

	50% {
		scale: 1.01;
	}

	100% {
		scale: 1;
	}
}

@media screen and (max-width: 744px) {
	.popup-bg {
		top: 0;
		height: 100%;
		position: fixed;
		width: 100dvw;
		height: 100dvh;
	}

	.order-form {
		padding: 0;

		.popup-container {
			position: fixed;
			width: 100dvw;
			height: 100dvh;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1000;

			.popup {
				position: relative !important;
				top: 0 !important;
				left: 0 !important;
				max-width: 98%;
			}
		}

		flex-direction: column;

		.col-details {
			width: 100%;
			padding-top: 0;
		}

		.col-inv {
			width: 100%;

			.orf-head {
				padding-bottom: 15px;

				.company-logo {
					justify-content: center;

					.est-detail-logo {
						height: 20px !important;
					}
				}

				.company-details {
					display: none;
				}
			}

			.inv-table {
				tr {
					padding: 0;
					margin: 0;
				}

				// .qty-col {
				// 	max-width: 20%;
				// }

				// .product-col {
				// 	max-width: 30%;
				// 	text-wrap: wrap;
				// }

				// .price-col {
				// 	max-width: 25%;
				// }

				// .vat-col {
				// 	max-width: 10%;
				// }

				// .comment-col {
				// 	max-width: 15%;
				// }
			}
		}

		.inv-prods-add-table {
			thead {
				display: none;
			}

			tbody {
				tr {
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					padding: 10px;
					position: relative;
					border-radius: 10px;
					border: 1px solid $color-borders-2;
					margin-bottom: 5px;
				}

				.hr-row {
					display: none;
				}

				.shipping-row {
					td {
						display: flex;
						align-items: center;
					}
				}
			}

			td.qty-col {
				flex: 20%;
				width: 20%;

				&::before {
					content: 'Qty ';
				}
			}

			td.inv-col {
				flex: 50%;
				width: 50%;

				&::before {
					content: 'Invoice ';
				}
			}

			td.model-col {
				flex: 80%;
				width: 80%;

				&::before {
					content: 'Model ';
				}
			}

			td.vat-col {
				flex: 46%;
				width: 46%;

				&::before {
					content: 'VAT ';
				}
			}

			td.uprice-col {
				flex: 46%;
				width: 46%;

				&::before {
					content: 'Price ';
				}
			}

			td.tprice-col {
				display: none;
			}

			td.remove-col {
				// position: absolute;
				// top: -3px;
				// right: -3px;

				flex: 8%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-bottom: 0;

				div {
					// height: auto !important;
					margin-top: 12px;

					button {
						font-size: 10px;
					}
				}
			}
		}
	}
}

@media print {

	button,
	.btn,
	.btn-primary {
		display: none !important;
	}

	.new-nav-bar {
		display: none !important;
	}

	#mainNav {
		display: none !important;
	}

	.section-head {
		display: none !important;
	}

	.col-details {
		display: none !important;
	}

	body,
	p,
	div,
	span,
	label,
	table,
	table tr td,
	table tr th {
		font-size: 13px !important;
	}
}

.order-agreement-action-group {
	display: flex;
	text-align: center;
	justify-content: center;
	margin-top: 26px;
}

.order-agreement-action-confirm {
	min-width: 15%;
}

.order-agreement-action-edit {
	margin-left: 15px;
	margin-top: 8px;
}

.contact-details-edit {
	display: none;
}

// .contact-details:hover {
.contact-details {
	.contact-details-edit {
		display: flex !important;
		// margin-top: -70px;
		height: 88px;
		justify-content: center;
		text-align: start;
	}

	background: #f3f3f3;
	color: #b2b2b2;
}

.order-form-edit-sections {
	background: #0066FF;
	display: flex;
	width: 50px;
	text-align: center;
	justify-content: center;
	color: #fff;
	font-size: 13px;
	padding: 2px 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.contact-container {
	margin-left: -21px;
	font-size: 13px;
	min-width: 150px;
}

.order-form-section-effect {

	.contact-details-edit2,
	.product-details-edit {
		.div-btn {
			display: none;
		}
	}

	.products-container {
		width: 100%;
		margin: 0;
		// margin-top: -25px;
	}
}

.product-details-edit {
	display: flex;
	justify-content: center;
	align-items: center;
}

.orderform-product-btn {
	position: absolute;
}

.order-form-section-effect:hover {
	.contact-container {
		margin-left: -129px;
		font-size: 13px;
		min-width: 150px;
	}

	.products-container {
		margin: 0;
		background: #f3f3f3;
	}

	.contact-details-edit2 {
		.div-btn {
			display: block;
		}
	}

	.contact-details-edit2 {
		color: #b2b2b2;
		background: #f3f3f3;
	}

	.product-details-edit {
		.div-btn {
			display: block;
		}

		background: #f3f3f3;
	}
}

.contact-details-edit2 {
	// height: 100px;
	display: flex;
	justify-content: center;
	/* text-align: center; */
	align-items: center;
	// background: #f3f3f3;
	border-left: 1px solid #0066FF;
	padding: 10px 35px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.border-left-blue1 {
	border-left: 1.5px solid #0066FF;
}

.border-left-blue2 {
	border-left: 2px solid #0066FF;
}

.product-details-edit:hover {
	.products-container {
		.inv-table-container {
			.tableData {
				table {
					tr {

						th,
						td {
							color: #b2b2b2;
						}
					}
				}
			}
		}
	}
}


.round {
	position: relative;
}

.round label {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	cursor: pointer;
	height: 20px;
	left: 0;
	position: absolute;
	top: 0;
	width: 20px;
}

.round label:after {
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	content: "";
	height: 6px;
	left: 3px;
	opacity: 0;
	position: absolute;
	top: 5px;
	transform: rotate(-45deg);
	width: 12px;
}

.round input[type="checkbox"] {
	visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
	background-color: #34C880;
	border-color: #34C880;
}

.round input[type="checkbox"]:checked+label:after {
	opacity: 1;
}