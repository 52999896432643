$color-scale-gray-0: #f0f6fc;
$color-scale-gray-1: #c9d1d9;
$color-scale-gray-2: #b1bac4;
$color-scale-gray-3: #8b949e;
$color-scale-gray-4: #6e7681;
$color-scale-gray-5: #373d44;
$color-scale-gray-6: #30363d;
$color-scale-gray-7: #21262d;
$color-scale-gray-8: #161b22;
$color-scale-gray-9: #0d1117;


$color-purple-1: #6b4a9f;
$color-pink-1: #d8578a;
$color-pink-2: #c8467a;
$color-green-1: #45ad51;
$color-green-2: #6bd076;
$color-green-3: #449e4f;
$color-success: #569E8D;
$color-danger: #F46B5B;
$color-warning: #F7A05A;
$color-blue-dark: #192138;
$color-blue-secondary:#00398F;
$color-primary: $color-blue-secondary;
$color-primary-2: $color-blue-dark;


//Light Theme

$color-light-scale-gray-0: #fff;
$color-light-scale-gray-1: #fafafa;
$color-light-scale-gray-1-1: #f4f4f4;
$color-light-scale-gray-2: #f1f1f1;
$color-light-scale-gray-2-2: #ebebeb;
$color-light-scale-gray-3: #b8b8b8;
$color-light-scale-gray-4: #6e7681;
$color-light-scale-gray-5: #373d44;
$color-light-scale-gray-6: #30363d;
$color-light-scale-gray-7: #21262d;
$color-light-scale-gray-8: #161b22;
$color-light-scale-gray-9: #0d1117;
