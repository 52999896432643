@import '../../assets/scss/colors';

.aspect-ratio-a4 { 
    height: 90vh;
    padding-left: calc(0.82 * 90vh);
    position: relative;
}
.printDialog {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: calc(0.03 * 90vh);
    padding-bottom: calc(0.03 * 90vh);

    z-index: 1050;
    display: flex;
    flex-direction: column;
        
    width: 100%;
    height: 100%;

    background: #fff;

    .printDialog-head {
        p{
            margin: 0;
        }
        padding-inline: calc(0.03 * 90vh);
        border-bottom: 1px solid $color-light-scale-gray-2-2;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .printDialog-body {
        padding-inline: calc(0.03 * 90vh);
        flex: 1 1 auto;
        font-size: 1rem;
        overflow-y: auto;
    }

    .printDialog-footer {
        padding-inline: calc(0.03 * 90vh);
        border-top: 1px solid $color-light-scale-gray-2-2;
        padding-top: 10px;
        margin-top: 10px;

    }
}
