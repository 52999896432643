@import '../../../assets/scss/colors';

.inbounding-imeis {
	.card {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.input-form {
		padding: 5px;
		.form-group {
			width: 100%;
		}
	}

	.product-list {
		width: 100%;
		font-size: 1rem;
		padding: 5px;
		border: $color-light-scale-gray-3 1px solid;
		border-radius: 4px;

		table {
			width: 100%;
			th,
			td {
				padding: 5px;
			}
		}
	}
}

.custom-dropdown-menu {
	transform: translate3d(-90px, 45px, 0px) !important;
}
