@import '../../../assets/scss/colors';
@import '../../../assets/scss/colors_new';

.item-repairs-dialog {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
		text-align: center;

		.modal-title {
			display: flex;
			flex-direction: column;
			justify-content: left;
			align-items: flex-start;
			color: #000;

			// gap: 5px;

			span {
				font-size: 50px;
			}
		}

		.modal-body {
			display: flex;
			flex-direction: column;
			color: #000;

			gap: 10px;

			p.message {
				margin-bottom: 15px;
			}

			ul.products {
				font-size: 1rem;
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 15px;
			}

			.waiting-for-approval {
				flex: 1;
				display: flex;
				gap: 10px;
				// justify-content: center;
				align-items: center;
				small {
					color: $color-light-scale-gray-3;
					font-size: 9px;
				}
			}
		}

		.modal-actions {
			display: flex;
			flex-direction: row;
			gap: 5px;
			justify-content: flex-end;
		}

		input {
			box-shadow: none !important;
			border-color: #d1d3e2 !important;
		}

		.new-entry {
			border: none;
			outline: none;
			background: transparent;
			box-shadow: none;
			padding: 0;
			font-size: 1rem;
			text-align: left;
			-webkit-appearance: none;

			&:focus,
			&:focus-visible,
			&:focus-visible,
			&:active {
				border: none;
				outline: none;
				background: transparent;
				box-shadow: none;
			}
		}

		select.new-entry:invalid,
		select.new-entry option[value=''] {
			color: #fe0000;
		}

		.rs-newentry-container {
			flex: 1;
			.rs-newentry__control {
				border: none !important;
				padding: 0;
				margin: 0;
				height: auto;
				width: auto;
				min-height: unset;
				box-shadow: none !important;
			}

			.rs-newentry__value-container {
				padding: 0;
				margin: 0;
				height: auto;
			}

			.rs-newentry__single-value {
				margin: 0;
				padding: 0;
			}

			.rs-newentry__input-container {
				padding: 0;
				margin: 0;
			}

			.rs-newentry__indicators {
				display: none;
			}

			.rs-newentry__placeholder {
				margin: 0;
			}

			.rs-newentry__menu {
				z-index: 2;
			}
		}

		.btn-confirm {
			border-color: $color-primary;
			color: $color-primary;
			background-color: $color-light-max;

			&:hover {
				border-color: $color-primary;
				background-color: $color-primary;
				color: $color-light-max;
			}

			&:focus {
				border-color: $color-primary;
				background-color: $color-primary;
				color: $color-light-max;
			}

			&.danger {
				border-color: red !important;
				color: red !important;

				&:focus {
					border-color: red !important;
					color: red !important;
				}
			}
		}

		.repairs-checklist-title {
			max-width: 400px;
			min-width: 400px;
			// text-align: left;
		}

		.repairs-checklist {
			// max-width: 400px;
			// min-width: 400px;
			width: 100%;
			margin-bottom: 20px;
			font-size: 1rem;
		}
	}
}

.rs-container {
	text-align: left;
	.rs__menu {
		z-index: 20 !important;
	}
}
