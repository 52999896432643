.est-inv-container {
    font-size: 1rem;
    color: #000;
    padding: 10px 30px;
    .customer-details {

    }

    .est-ref {
        font-size: 20px;
        font-weight: 600;
    }

    .est-inv-items {
        table {
            th {
                font-weight: 500;
            }

            td, th {
                padding: 5px 0;
            }
        }
    }
}