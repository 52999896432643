@import '../../../assets/scss/colors';

.process-status-indicator-container-client {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-bottom: 1rem;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.process-status-indicator {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	width: 50% !important;

	color: $color-light-scale-gray-3;

	.process-connector-line {
		width: 100%;
		flex: 100%;
		height: 6px;
		background: #e2e2e2;
	}

	.process {
		display: flex;
		flex-direction: column;
		flex: auto;
		align-items: center;
		gap: 10px;
		// min-width: 90px;

		.indicator-check {
			border: $color-light-scale-gray-3 1px solid;
			color: $color-light-scale-gray-3;
			border-radius: 100px;
			width: fit-content;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				display: flex;
			}
		}

		&.completed {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: #fff;
				background-color: $color-primary-2;

				span {
					opacity: 1;
				}
			}

			& ~ div.process-connector-line {
				background: #70dd36;
			}
		}

		&:not(.completed) ~ div.process-connector-line {
			background: $color-light-scale-gray-3;
		}

		&.current {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: $color-primary-2;
			}

			&.inprogress .indicator-check {
				span {
					opacity: 1;
				}
			}
		}
	}
}

.process-status-indicator-mobile {
	display: flex;
	align-items: left;
	justify-content: center;
	font-size: 12px;

	color: $color-light-scale-gray-3;

	.process-connector-line {
		width: 100%;
		flex: 100%;
		background: #e2e2e2;
	}

	.process {
		display: flex;

		flex: auto;
		align-items: center;
		gap: 10px;
		// min-width: 90px;

		.indicator-check {
			border: $color-light-scale-gray-3 1px solid;
			color: $color-light-scale-gray-3;
			border-radius: 100px;
			width: fit-content;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				display: flex;
			}
		}

		&.completed {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: #fff;
				background-color: $color-primary-2;

				span {
					opacity: 1;
				}
			}

			& ~ div.process-connector-line {
				background: #70dd36;
			}
		}

		&:not(.completed) ~ div.process-connector-line {
			background: $color-light-scale-gray-3;
		}

		&.current {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: $color-primary-2;
			}

			&.inprogress .indicator-check {
				span {
					opacity: 1;
				}
			}
		}
	}
}
