@import '../../../assets/scss/colors_new';

.accept-confirmation-dialog {
	.order-agreements-detail-section{
		h6::before {
			display: none;
		}
		padding: 0;
		border: 1px solid #e8e8ed;
		padding: 10px;
		border-radius: 10px;

		.order-agreements-detail-empty {
			padding-block: 15px;
			color: $color-text-2;
			font-style: italic;
		}
	}

	.modal-dialog {
		max-width: 400px;
		margin: 1.75rem auto;
		text-align: center;

		.modal-title {
			display: flex;
			flex-direction: column;
			// gap: 5px;

			span {
				font-size: 50px;
			}
		}

		.modal-body {
			display: flex;
			flex-direction: column;

			gap: 10px;

			p.message {
				margin-bottom: 15px;
			}

			ul.products {
				font-size: 1rem;
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 15px;
			}
		}

		.modal-actions {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		.btn-confirm {
			border-color: $color-primary;
			color: $color-primary;
			background-color: $color-light-max;

			&:hover {
				border-color: $color-primary;
				background-color: $color-primary;
				color: $color-light-max;
			}

			&:focus {
				border-color: $color-primary;
				background-color: $color-primary;
				color: $color-light-max;
			}

			&.danger {
				border-color: red;
				color: red;

				&:hover {
					background-color: red !important;
					border-color: red !important;
					color: white !important;
				}

				&:active,
				&:focus {
					background-color: red !important;
					border-color: red !important;
					color: white !important;
				}
			}
		}
	}
}
