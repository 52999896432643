.darkMode {
	#wrapper #mainNav {
		background: #000;
		border-bottom: 1px solid #181818;
	}

	#wrapper #mainNav #nav-page-title h5 {
		color: #fff;
	}

	.new-nav-bar {
		background-color: #000;
		border-right: 1px solid #181818;
	}

	.new-nav-bar li.nav-item a.nav-link {
		color: #fff;
	}

	.new-nav-bar li.nav-item.active a.nav-link {
		// color: #0066ff;
        color: #fff;
        // background-color: #fff;
	}

	.new-nav-bar .currency-div {
		background-color: #000;
	}

	.currency-component {
		color: #fff;
	}

	#wrapper #content-wrapper {
		background-color: #191919;
	}

	.topListCom li {
		color: #fff;
	}

	.topListCom li.active {
		color: #0066ff;
	}

	.new-nav-bar li.nav-item a.nav-link:hover {
		background-color: #414141;
	}

	.btn-outlined-main.white-var,
	.btn-outlined-main {
		background-color: #414141;
		color: #fff;
		border-color: #1b1a1a;
	}

	.btn-outlined-main.disabled,
	button.btn-outlined-main[disabled] {
		background-color: #414141 !important;
		color: #fff !important;
		border-color: #1b1a1a !important;
	}

	.card {
		background-color: #000 !important;
	}

	table.table.pm-table thead tr th,
	table.table.pm-table tbody tr td {
		color: #fff;
	}

	#wrapper #mainNav .nav-actions,
	#wrapper #mainNav .nav-actions #alertsDropdown {
		color: #fff !important;
	}

	#wrapper #mainNav .nav-search .nav-search-input input {
		background: #414141 !important;
        border: none;
	}

	.taskgrid-container .task-card {
		color: #fff;
	}

	.TaskDetails {
		color: #fff !important;
	}

	.est-detail-item,
	.est-labels {
		color: #fff !important;
	}

	.estimate-notes .estimate-note .estimate-note-value,
	.estimate-notes .date-group {
		color: #fff !important;
	}

	a {
		color: #0a6ce9;
	}

	table.table.pm-table thead tr th:first-child {
		border-left: #676767 1px solid;
	}

	table.table.pm-table thead tr th {
		border-top: #676767 1px solid;
		border-bottom: #676767 1px solid;
	}

	table.table.pm-table thead tr th:last-child {
		border-right: #676767 1px solid;
	}

	table.table.pm-table tbody tr td {
		border-bottom: #262626 1px solid;
	}

	.sticky-header {
		background: #000 !important;
		box-shadow: #676767 1px 1px;
	}

	.table.table.pm-table tbody tr:hover,
	tr.selected-row {
		background-color: #676767;
	}

	.est-inv-container {
		color: #fff;
	}

	.create-contact-container .card,
	.create-contact-container .form-group label {
		color: #fff;
	}

	.rs-container .rs__input-container,
	.rs-container-est .rs__input-container,
	.rs-container-est-white .rs__input-container,
	.rs-container .rs__single-value,
	.rs-container-est .rs__single-value,
	.rs-container-est-white .rs__single-value {
		color: #fff;
	}

	.form-control {
		background-color: #000 !important;
		color: #fff !important;
	}

	.create-contact-container .form-group input,
	.create-contact-container .form-group select:not(.PhoneInputCountrySelect),
	.create-contact-container .form-group .rs-container,
	.create-contact-container .form-group .vatCheck,
	.create-contact-container .form-group .PhoneInput {
		color: #fff !important;
	}

	.PhoneInputInput {
		background: #000 !important;
	}
    .profile-details-dropdown {
        .bg {
            
            filter: blur(2px);
            background: #161819;
            opacity: 0.95;
            border: 1px solid #fff;
            height: 262px;
        }
    }
    .profile-details-dropdown.bg {
        filter: blur(2px);
        background: #161819;
        opacity: 0.95;
        border: 1px solid #fff;
        height: 262px;
    }
    .profile-details-dropdown.pdb-container {
        background: transparent;
    }
    .profile-details-dropdown-menu-item {
        &:hover {
                background: #414141 !important;
                color: #fff ;
        }
    }
    .new-nav-bar li.nav-item.main-menu:hover,
    .new-nav-bar li.nav-item.sub-menu a.nav-link:hover 
    {
        background-color: #414141 !important;
    }
    .sidebar-collapsable-menu {
        .nav-item {
            color: #fff; 
            .nav-link {
                span {
                    color: #fff; 
                }
            }
        }
    }
    .new-nav-bar.collapsed {
        background: #000000 !important;
        .navbar-nav-items {

            .sidebar-title-backside {
                position: absolute;
                height: 42px;
                background: #414141 !important;
                filter: blur(2px);
                box-shadow: 1px 1px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                border: 1px solid #fff;
            }
        }
        .nav-item.main-menu.active {
            a {
                background: #414141 !important;
                color: #fff;
            }

        }
    }
    .nav-item.main-menu  {
        color: #fff; 
    }
    #wrapper #mainNav .nav-actions .user-icon-item {
        background: #414141;
        border: none !important;
        // display: flex;
        // gap: 5px;
        // border: 1px solid rgba(217, 225, 231, 0.8);
        // border-radius: 50px;
        // color: #17181A;
        // padding: 5px;
        // align-items: center;
    }
    .collapse-tag 
    {
        background: #414141 !important;
        color: #fff !important;
    }
    .new-nav-bar.collapsed .navbar-nav-items .nav-item.main-menu:hover .nav-link {
        background: #414141 !important;
    }
    .TableSerachBar .navbar-search input.form-control {
        background-color: #414141 !important;
    }
    .form-control.bg-white {
            background-color: #414141 !important;
    }
    .filter-container .filter-buttons button {
        background-color: #414141 !important;
        border: none !important;
    }
    .btn-outlined-main-primary {
        color: #fff;
        &:hover {
            background-color: #014cba;
        }
    }
    .new-nav-bar li.nav-item.active a.nav-link .menuIcon {
        color: #fff;
    }
    .currency-component.collapsed {
        background: #414141;
        color: #fff;
        border-radius: 6px;
    }
    .currencyText {
        background-color: #414141;
        border: none !important;
    }
}
