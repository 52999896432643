@import '../../assets/scss/colors';

.process-status-indicator {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
    width: 30%;

	color: $color-light-scale-gray-3;

	.process-connector-line {
		width: 100%;
		flex: 100%;
		height: 1px;
		background: $color-light-scale-gray-3;
	}

	.process {
		display: flex;
		flex-direction: column;
		flex: auto;
		align-items: center;
		gap: 10px;
		// min-width: 90px;

		.indicator-check {
			border: $color-light-scale-gray-3 1px solid;
			color: $color-light-scale-gray-3;
			border-radius: 100px;
			width: fit-content;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
                display: flex;
			}
		}

		&.completed {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: #fff;
				background-color: $color-primary-2;

				span {
					opacity: 1;
				}
			}

			& ~ div.process-connector-line {
				background: $color-primary-2;
			}
		}

		&:not(.completed) ~ div.process-connector-line {
			background: $color-light-scale-gray-3;
		}

		&.current {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
                color: $color-primary-2;
			}

            &.inprogress .indicator-check {
				span {
					opacity: 1;
				}
			}
		}
	}
}
