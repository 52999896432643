/*@font-face {
	font-family: 'Sofia Pro';
	src: url('../fonts/SofiaProBold.woff2') format('woff2'), url('../fonts/SofiaProBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sofia Pro';
	src: url('../fonts/SofiaProBold.woff2') format('woff2'), url('../fonts/SofiaProLight.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sofia Pro';
	src: url('../fonts/SofiaProRegular.woff2') format('woff2'), url('../fonts/SofiaProRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sofia Pro';
	src: url('../fonts/SofiaProMedium.woff2') format('woff2'), url('../fonts/SofiaProMedium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}*/

@import './colors';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,700;0,800;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

body {
	font-family: sans-serif;
	font-family: 'Roboto', sans-serif;
	// background-color: $color-scale-gray-9;
	letter-spacing: -0.3px;
	color: #000;
	font-size: 1rem;
	font-family: 'poppins';
}

a {
	color: $color-primary;
	text-decoration: none;
	background-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox 
input[type='number'] {
	-moz-appearance: textfield;
}*/

.rounded-xl {
	border-radius: 10px;
}

.cardSmallText {
	font-size: 13px;
	display: block;
}

.boxArrow {
	background-color: #795aaa;
	position: relative;
}

.boxArrow img {
	position: absolute;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	top: 10px;
	left: 12px;
}

.boxArrow:hover img {
	transform: rotate(45deg);
	transform-origin: center;
}

.btn-outline-primary {
	color: #00ccf2;
	border-color: #00ccf2;
}

.btn-outline-primary:hover {
	background-color: #00ccf2;
	border-color: #00ccf2;
}

.btn-outline-danger {
	color: #ff077c;
	border-color: #ff077c;
}

.btn-outline-danger:hover {
	background-color: #ff077c;
	border-color: #ff077c;
}

.modal-backdrop.show {
	opacity: 0.75;
}

.bg-danger {
	color: #d14141 !important;
	background-color: #fbd9d9 !important;
}

.bg-success {
	color: rgb(39, 144, 81) !important;
	background-color: rgb(227, 247, 235) !important;
}

.font-weight-bold {
	font-weight: 500 !important;
}

.rounded-pill {
	border-radius: 4px !important;
}

.table td {
	padding: 8px 0.75rem;
}

.font12 {
	font-size: 1rem;
}
.text-12 {
	font-size: 12px !important;
}

.font14 {
	font-size: 13px;
}
.font15 {
	font-size: 15px;
}

.font22 {
	font-size: 22px;
}

.stcokBox {
	background-color: #ff007c;
}

.stcokBox a.boxArrow {
	background-color: #a72d68;
}

.PenInvoices {
	background-color: #0a6ce9;
}

.PenInvoices a.boxArrow {
	background-color: #789af3;
}

.MonDown {
	background-color: #7d00b5;
}

.MonDown a.boxArrow {
	background-color: #ba58e6;
}

.PenInvoices span.small,
.MonDown span.small {
	display: block;
	max-width: 80%;
	line-height: 1.2;
}

.bg-login-image {
	// background: $color-scale-gray-9;
	background-position: center;
	background-size: cover;
}

.loginScr-bg {
	background: url(../images/login-bottom-right.png) right bottom no-repeat;
}

.border-bottom {
	border-bottom: 1px solid #44444f !important;
}

.btn-primary {
	background-color: #795aaa;
	border-color: #795aaa;
	border: none;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
	background-color: #6b4a9f;
	border-color: #6b4a9f;
	box-shadow: none;
}

.btn label,
.btn span.text,
.btn span.icon {
	margin: 0;
	position: relative;
	cursor: pointer;
	font-weight: normal;
	font-size: 13px;
}

.btn span.msp-overlay {
	z-index: -1;
	display: none;
	position: absolute;
	width: 120%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) scale(0, 0);
	-ms-transform: translate(-50%, -50%) scale(0, 0);
	transform: translate(-50%, -50%) scale(0, 0);
	-webkit-transition: -webkit-transform 450ms cubic-bezier(0.75, 0.1, 0.25, 0.9);
	transition: transform 450ms cubic-bezier(0.75, 0.1, 0.25, 0.9);
}

.btn span.msp-overlay::after {
	position: relative;
	content: '';
	border-radius: 50%;
	display: block;
	padding-top: 100%;
	background-color: #29d3f2;
}

.btn:hover span.msp-overlay {
	-webkit-transform: translate(-50%, -50%) scale(1, 1);
	-ms-transform: translate(-50%, -50%) scale(1, 1);
	transform: translate(-50%, -50%) scale(1, 1);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #6b4a9f;
	border-color: #6b4a9f;
}

.inputchechbox input[type='checkbox'] {
	margin-right: 12px;
}

.uploadRow label {
	width: 225px;
	margin: 0;
}

.custom-control-label::after {
	background-repeat: no-repeat;
	background-size: 78%;
	background-position: 1px 1px;
	background-color: $color-scale-gray-9;
	border: 2px solid #b5b5be;
	border-radius: 4px;
}

.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: none;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
	// padding-bottom: 0;
}

.uploadRow input[type='file']::file-selector-button {
	border: none;
	padding: 0.2em 15px;
	border-radius: 7px;
	background-color: #1f1f26;
	transition: 1s;
	color: #b5b5be;
	margin-right: 10px;
}

.h60 {
	height: 60px;
}

.navbar-nav {
	position: relative;
}

.navbar-nav .userName {
	font-size: 13px;
}

.navbar-nav .userName small {
	display: block;
}

form.user.LoginForm .form-control {
	height: 60px;
	border: 1px solid #e8e8ed;
	// background: $color-scale-gray-6 0% 0% no-repeat padding-box;
	border-radius: 4px;
	padding: 0 50px 0 50px;
	font-size: 1rem;
	color: #fff;
}

// .form-row .form-group .form-control,
// .form-row .form-control-select .MuiSelect-select,
// .transit {
// 	border: 1px solid transparent;
// 	// background-color: $color-scale-gray-6;
// 	font-size: 1rem;
// 	border-radius: 4px;
// 	padding: 10px 15px;
// 	// color: #b5b5be;
// 	height: auto;
// }
.form-row .form-group .form-control:focus,
.transit:focus {
	// border: 1px solid $color-scale-gray-4;
	box-shadow: none;
	// color: #b5b5be;
}

.form-row.createDate .form-group input[type='date'].form-control {
	height: 40px;
	font-size: 0.85rem;
}

.userForm .form-group label {
	margin-bottom: 1.1rem;
	// color: #fff !important;
}

.formActBtns .btn.rounded-pill {
	border-radius: 10px !important;
}

.parent-group {
	display: flex;
	align-items: center;
	gap: 10px;
}

.projectmix-table-vertical {
	width: 100%;
	max-width: 100%;
	border-collapse: collapse;
	margin-bottom: 1.25rem;
	empty-cells: show;
}

.purchase-order-status-label {
	display: inline-block;
	min-width: 1.5rem;
	padding: 0.25rem 0.375rem;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	border-radius: 0.375rem;
	background-color: #e4e6ec;
	letter-spacing: 0;
	white-space: nowrap;
	color: #141721;
	border: 1px solid transparent;
}

.purchaseorder-status-label-received {
	background-color: hsl(160, 100%, 32%);
	border-color: hsl(160, 100%, 32%);
	color: #fff;
}

.purchaseorder-status-label-purchased {
	background-color: hsl(220, 97%, 66%);
	border-color: hsl(220, 97%, 66%);
	color: #fff;
}

.purchaseorder-status-label-concept {
	background-color: hsl(30, 100%, 57%);
	border-color: hsl(30, 100%, 57%);
	color: #fff;
}

.tr-nth {
	border-bottom: 0px solid #fff !important;
	border-top: 2px solid #d5d3d3 !important;
}

.purchase-order-status-label {
	display: inline-block;
	min-width: 1.5rem;
	padding: 0.25rem 0.375rem;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	border-radius: 0.375rem;
	background-color: #e4e6ec;
	letter-spacing: 0;
	white-space: nowrap;
	color: #141721;
	border: 1px solid transparent;
}

.purchaseorder-status-label-received {
	background-color: hsl(160, 100%, 32%);
	border-color: hsl(160, 100%, 32%);
	color: #fff;
}

.purchaseorder-status-label-purchased {
	background-color: hsl(220, 97%, 66%);
	border-color: hsl(220, 97%, 66%);
	color: #fff;
}

.purchaseorder-status-label-concept {
	background-color: hsl(30, 100%, 57%);
	border-color: hsl(30, 100%, 57%);
	color: #fff;
}

.tr-nth {
	border-bottom: 0px solid #fff !important;
	border-top: 2px solid #d5d3d3 !important;
}

.purchase-order-status-label {
	display: inline-block;
	min-width: 1.5rem;
	padding: 0.25rem 0.375rem;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	border-radius: 0.375rem;
	background-color: #e4e6ec;
	letter-spacing: 0;
	white-space: nowrap;
	color: #141721;
	border: 1px solid transparent;
}

.purchaseorder-status-label-received {
	background-color: hsl(160, 100%, 32%);
	border-color: hsl(160, 100%, 32%);
	color: #fff;
}

.purchaseorder-status-label-purchased {
	background-color: hsl(220, 97%, 66%);
	border-color: hsl(220, 97%, 66%);
	color: #fff;
}

.purchaseorder-status-label-concept {
	background-color: hsl(30, 100%, 57%);
	border-color: hsl(30, 100%, 57%);
	color: #fff;
}

.tr-nth {
	border-bottom: 0px solid #fff !important;
	border-top: 2px solid #d5d3d3 !important;
}

.projectmix-table-vertical tbody {
	display: table-row-group;
	vertical-align: middle;
	border-color: inherit;
}

.projectmix-table-vertical tr {
	display: table-row;
	vertical-align: inherit;
	border-color: inherit;
}

.projectmix-table-vertical th {
	font-weight: normal;
	color: #6e7381;
	text-align: right;
	width: 10rem;
}

.projectmix-table-vertical td {
	padding: 0.375rem 0.375rem;
	vertical-align: top;
}

.events-none {
	pointer-events: none;
}

.btn-group {
	position: relative;
	display: inline-flex;
	align-items: baseline;
	white-space: nowrap;
	border: 1px solid $color-light-scale-gray-3 !important;
	border-radius: 11px !important;
}

.reserved {
	max-height: 50px;
	display: flex;
	flex-direction: column;

	label {
		margin-bottom: 0 !important;
	}

	.reserved-amount {
		font-size: 0.75rem;
		color: #6e7381;
	}
}

.positive {
	color: hsl(162, 80%, 33%) !important;
	font-weight: 500;
}

.negative {
	color: hsl(0, 75%, 60%) !important;
	font-weight: 500;
}

.settings-page-start {
	margin: 10px;
	padding: 10px;
}

.setting-page {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5%;
}

.settings-card {
	width: 100%;
	max-width: 380px;
	padding: 20px;
	height: 120px;
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	margin-bottom: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	text-decoration: none;
	color: #17181a;
}

.icon-set-react {
	font-size: 36px;
	font-weight: 800;
}

@media (min-width: 768px) {
	.settings-card {
		width: 380px;
	}
}

.setting-info {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	span {
		font-size: 15px;
		font-weight: 600;
	}

	p {
		font-size: 13px;
	}
}

.setting-card-icon {
	padding: auto;
	font-size: 28px;
}

.settings-card:hover {
	//background-color: #f5f5f5;

	text-decoration: none;
	cursor: pointer;
	color: #0066ff;
	border: 1px solid #0066ff;

	.icon-set {
		fill: #0066ff;
	}
}

.heading-inner-settig {
	span {
		font-size: 15px;
		font-weight: 600;
		letter-spacing: normal;
	}

	hr {
		margin-top: 5px !important;
		margin-bottom: 2rem !important;
	}
}

.form-control-picer {
	width: 100%;
	padding: 0.375rem 0.875rem;
	border: 1px solid hsl(225, 13%, 76%);
	border-radius: 0.375rem;
	color: #141721;
	font-weight: 400;
	font-size: 0.875rem;
	background: none;
	line-height: 1.5;
	cursor: text;
	background-color: #fff;
}

.reason-change-stock {
	cursor: pointer;
	text-decoration: none;
	color: hsl(216, 85%, 57%);
	font-weight: 400;
}

.location {
	border: 1px solid hsl(225, 13%, 76%);
	border-radius: 0.5rem;
	padding: 0.375rem 0.875rem;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	display: flex;
	max-height: 50px;
	justify-content: space-between;
}

.input-group form-cntrl {
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	margin-bottom: 0;
	height: 40px !important;
}

.input-group-lg {
	width: 100% !important;
}

.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}

.input-group-btn {
	position: relative;
	font-size: 0;
	white-space: nowrap;
	height: 40px;
	border: 1px solid $color-light-scale-gray-3 !important;
}

.input-change {
	flex: 1;
	height: 40px;
	border-right: 0px;
	border-left: 0px;
	border-top: 1px solid #b8b8b8 !important;
	border-bottom: 1px solid #b8b8b8 !important;
}

.input-group-plus-minus {
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.width-100 {
	width: 100% !important;
}

.upper-case {
	text-transform: uppercase;
}

.btn-left {
	border-right: 1px solid $color-light-scale-gray-3 !important;
}

.btn-middle {
	border-left: 1px solid $color-light-scale-gray-3 !important;
}

.btn-right {
	border-left: 1px solid $color-light-scale-gray-3 !important;
}

.status-complete {
	padding: 10px 20px;
	background-color: hsl(160, 100%, 32%);
	border-color: hsl(160, 100%, 32%);
	color: #fff;
	display: inline-block;
	min-width: 1.5rem;
	padding: 0.25rem 0.375rem;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	border-radius: 0.375rem;
	letter-spacing: 0;
	white-space: nowrap;
}

.btn-add-stock {
	display: flex;
	justify-content: center;
	gap: 5px;
}

.btn-purchase-main {
	border: 1px solid $color-light-scale-gray-3 !important;
	border-radius: 5px !important;
	padding: 5px 10px;
}

.btn-purchase-main svg {
	font-size: 20px;
	margin-right: 5px;
}

.flex-attachment {
	display: flex;
	justify-content: space-between;
	padding: 10px;
}

.concept {
	background-color: #707070 !important;
	color: white !important;
}

.purchased {
	background-color: #c28100 !important;
	color: white !important;
}
.po_paid {
	background-color: #33c880 !important;
	color: white !important;
}

.received {
	background-color: #007bff !important;
	// background-color: hsl(160, 100%, 32%) !important;
	color: white !important;
}

.check-head {
	display: flex;
	gap: 15px;
	align-items: center;
}

.product-amount-field {
	color: #141721;
	font-weight: 400;
	font-size: 0.875rem;
	--webkit-appearance: none;
	// border-top: 1px solid $color-light-scale-gray-3 !important;
	// border-bottom: 1px solid $color-light-scale-gray-3 !important;
	// border-left: none !important;
	// border-right: none !important;
	background: none;
	line-height: 1.5;
	border: none;
	height: 30px;
	cursor: text;
	background-color: #fff;
}

.btn {
	display: inline-block;
	// border: 1px solid $color-light-scale-gray-3 !important;
	text-decoration: none;
	background-color: #fff;
	color: #141721;
	font-size: 0.875rem;
	text-align: center;
	--webkit-appearance: none;
	line-height: 1.5;
	cursor: pointer;
	white-space: nowrap;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}

.btn-group input {
	width: 2.75rem;
	text-align: center;
	flex-grow: 1;
}

.btn .btn-sm {
	padding: 0.25rem 0.625rem;
	font-weight: 400;
	border-radius: 0.375rem;
}

.cstm-section-head {
	width: 100%;
	border-bottom: 1px solid $color-light-scale-gray-3 !important;
}

.close-Icon {
	font-size: 24px;
	margin-left: auto;
	display: flex;
	align-items: center;
	margin-right: 10px;
	cursor: pointer;
}

.remove-icon-button {
	border-right: 1px solid $color-light-scale-gray-3 !important;
	background-color: transparent !important;
	border: none !important;
}

.section-head-new {
	padding: 0px 0 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 15px;

	h2 {
		font-size: 25px;
		font-weight: 600;
		color: #000;
		margin: 0;
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
		margin-bottom: 2px;

		select {
			height: 100%;
			min-width: 100px;
			background-color: transparent;
			border: 1px solid $color-light-scale-gray-3 !important;
			color: $color-primary-2 !important;
			// border: 1px solid $color-primary;
			// color: $color-primary;
			text-align: center;
			border-radius: 5px;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// gap: 5px;
			font-size: 1rem;
			padding: 5px 10px;
		}
	}
}

.section-head {
	padding: 10px 0 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 15px;

	h2 {
		font-size: 25px;
		font-weight: 600;
		color: #000;
		margin: 0;
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
		margin-bottom: 2px;

		select {
			height: 100%;
			min-width: 100px;
			background-color: transparent;
			border: 1px solid $color-light-scale-gray-3 !important;
			color: $color-primary-2 !important;
			// border: 1px solid $color-primary;
			// color: $color-primary;
			text-align: center;
			border-radius: 5px;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// gap: 5px;
			font-size: 1rem;
			padding: 5px 10px;
		}
	}
}

.black {
	color: #000;
}

.canBtn {
	font-size: 13px;
	padding: 8px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 5px;
	background-color: $color-pink-1;
}

.canBtn:hover,
.canBtn:focus,
.canBtn:not(:disabled):not(.disabled):active {
	background-color: $color-pink-2;
}

.canBtn:not(:disabled):not(.disabled):active:focus {
	background-color: lighten($color: $color-pink-2, $amount: 1.2);
}

.canBtn.disabled,
.canBtn:disabled {
	background-color: $color-pink-1;
	border-color: $color-pink-1;
}

.greyBtn {
	font-size: 13px;
	padding: 8px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 5px;
	background-color: $color-scale-gray-6;
}

.greyBtn:hover,
.greyBtn:focus,
.greyBtn:not(:disabled):not(.disabled):active {
	background-color: $color-scale-gray-7;
}

.greyBtn:not(:disabled):not(.disabled):active:focus {
	background-color: lighten($color: $color-scale-gray-7, $amount: 1.2);
}

.greyBtn.disabled,
.greyBtn:disabled {
	background-color: $color-scale-gray-7;
	border-color: $color-scale-gray-7;
}

.greenBtn {
	font-size: 13px;
	padding: 8px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 5px;
	background-color: $color-green-1;
}

.greenBtn:hover,
.greenBtn:focus,
.greenBtn:not(:disabled):not(.disabled):active {
	background-color: $color-green-2;
}

.greenBtn:not(:disabled):not(.disabled):active:focus {
	background-color: $color-green-3;
}

.greenBtn.disabled,
.greenBtn:disabled {
	background-color: $color-green-1;
	border-color: $color-green-1;
}

.formActBtns .blueBtn {
	background-color: #639fbd;
}

.formActBtns .blueBtn:hover,
.formActBtns .blueBtn:not(:disabled):not(.disabled):active {
	background-color: #6cb5da;
}

.btn-link {
	color: $color-primary;
	font-size: 1rem;
	min-width: unset !important;
}

.cstm-flex {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.footer-left {
	display: flex;
	align-items: center;
	gap: 20px;
	font-size: 13px;
}

.footer-left div {
	display: flex;
	gap: 10px;
}

.section-footer-btn {
	min-width: 100px;
}

.section-footer-btn .btn {
	min-width: 100px;
}

.section-footer {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px 10px 20px;
	border-top: 1px solid $color-light-scale-gray-3 !important;
}

.footer-right {
	display: flex;
}

.toaster-styles-2 {
	margin-top: 50px;
}

.darkMode .toaster-styles {
	border-radius: 5px !important;
	background: $color-scale-gray-6 !important;
	color: #fff !important;
}

.form-row .form-group .form-control.textArea {
	height: 120px;
	border-radius: 4px;
	padding: 20px 15px;
}

.form-row .form-control-select .MuiSelect-select {
	display: flex;
	align-items: center;
	min-height: 52px;
}

.MuiSelect-select .MuiBox-root div {
	background-color: #41414b;
	color: #d5d5dc;
}

.MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
	color: #fff;
}

.form-group .react-autosuggest__input {
	background: $color-scale-gray-6;
	border: 1px solid $color-scale-gray-6 !important;
	padding: 10px 25px;
	font-size: 16px;
	border-radius: 4px;
	height: 60px;
	width: 100%;
}

.form-group .react-autosuggest__input:focus {
	background-color: #272732;
	border: 1px solid #272732 !important;
	box-shadow: none;
	color: #fafafb;
}

.form-group .react-autosuggest__input--focused {
	outline: none;
}

.form-row .form-control-select .MuiSelect-select[aria-expanded='true'] {
	border: 1px solid #0a6ce9;
}

.form-row .form-control-select .MuiOutlinedInput-notchedOutline {
	border: none;
}

.form-row .form-control-select:hover {
	border-color: red !important;
}

.form-row .MuiInputLabel-formControl {
	color: #9c9ca5 !important;
	font-size: 22px;
}

.form-row .form-control-select .MuiChip-deleteIcon {
	width: 20px;
	color: #fb8585;
}

.form-row .MuiFormControl-root {
	margin-top: 0;
}

.MuiFormControl-root,
.MuiInputBase-formControl {
	// margin: 10px 0 0 0;
}

.custom-selection > div:nth-child(1),
.custom-selection > div:nth-child(2) {
	border: 1px solid #e2e2e2;
	background: #fff;
	min-height: 58px;
	border-radius: 100px;
	padding: 0 20px;
}

.custom-selection > div:nth-child(1):hover,
.custom-selection > div:nth-child(2):hover {
	border: 1px solid #e2e2e2;
}

.custom-selection > div:nth-child(3) {
	z-index: 2;
}

.estiInfo .form-group .custom-select__control,
.estiInfo .form-group .custom-select__menu {
	width: 280px;
}

.estiInfo .form-group input.form-control {
	height: 40px;
	width: 280px;
}

.estiInfo .form-group .custom-select__menu .addNewCus {
	color: #fafafb;
	border-top: 1px solid #3f3f49;
	border-radius: 0;
	width: 100%;
	text-align: left;
}

.estiInfo .form-group .custom-select__menu .addNewCus:hover {
	background-color: #31313d;
}

.form-group .custom-select__control {
	background-color: $color-scale-gray-6 !important;
	color: #fafafb;
	height: auto !important;
	border: 1px solid #3f3f4a;
	width: 100%;
	padding: 10px 15px;
}

.form-group .custom-select__indicator-separator {
	margin: 0 !important;
}

.form-group .custom-select__indicator.custom-select__dropdown-indicator {
	padding: 0 5px;
}

.form-group .custom-select__control > div {
	padding: 0;
}

.form-group .custom-select__menu,
.form-group .custom-select__menu .custom-select__option {
	background-color: $color-scale-gray-6;
	color: #fafafb;
}

.form-group .custom-select__menu .custom-select__option:hover,
.form-group .custom-select__menu .custom-select__option--is-focused {
	background-color: $color-scale-gray-5;
}

.form-group .custom-select__control .custom-select__single-value,
.form-group .custom-select__control .custom-select__input-container,
.form-group .custom-select__control .custom-select__placeholder {
	color: #fafafb;
	line-height: 1;
}

.form-group .custom-select__control:hover,
.form-group .custom-select__control:focus,
.form-group .custom-select__control:active,
.form-group .custom-select__control:visited {
	outline: none;
	border-color: #3f3f4a;
}

.LoginForm .loginBtn {
	height: 60px;
	overflow: hidden;
}

.LoginForm .btn-primary.loginBtn {
	font-size: 22px;
	font-weight: 500;
}

.estimateCName .form-group select.form-control {
	height: 32px;
	font-size: 13px;
}

.estimateCName .form-group select.form-control {
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.45em;
	background-size: 5px 5px, 5px 5px, 1px 1.3em;
	border: 1px solid #97979766;
	background-color: $color-scale-gray-5;
}

.estimateCName .form-group select.form-control:focus {
	background-position: calc(100% - 15px) calc(1em + 0px), calc(100% - 20px) calc(1em + 0px), calc(100% - 2.5em) 0.45em;
	background-size: 5px 5px, 5px 5px, 1px 1.3em;
	border: 1px solid #97979766;
}

/*.LoginForm .btn label{font-size: 22px; font-weight: 500;}*/
.LoginForm .text-right a {
	color: #0a6ce9;
}

form.user.LoginForm .form-control:focus {
	// background-color: #1f1f25;
	// border: 1px solid #1f1f25;
	box-shadow: none;
	// color: #fafafb;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: #fff !important;
	background-color: #1f1f25;
}

.LoginForm .form-group {
	position: relative;
}

.LoginForm .form-group .text-danger {
	position: absolute;
	margin-top: 5px;
}

.LoginForm .form-group img.env,
.LoginForm .form-group img.lock {
	position: absolute;
	left: 25px;
	bottom: 23px;
	width: 16px;
}

.LoginForm .form-group img.lock {
	width: 14px;
	bottom: 21px;
}

.LoginForm .form-group i.far.fa-eye {
	position: absolute;
	right: 25px;
	top: 54px;
	cursor: pointer;
}

.form-control {
	font-size: 1rem;
	border: 1px solid #e8e8ed;
	color: $color-light-scale-gray-8 !important;

	&:hover,
	&:active,
	&:focus {
		box-shadow: none;
		border: 1px solid $color-primary;
	}

	transition: all 0.3s ease-in-out;
}

.bg-login-image {
	position: relative;
	overflow: hidden;
}

.bg-login-image:after {
	position: absolute;
	left: 0;
	top: 0;
	content: '';
	background: url(../images/path-289.svg) center bottom no-repeat;
	width: 100%;
	height: 250px;
	filter: grayscale(1);
	opacity: 0.1;
}

.bg-login-image:before {
	position: absolute;
	left: 0;
	bottom: 0;
	content: '';
	background: url(../images/path-291.svg) top right no-repeat;
	width: 350px;
	height: 78%;
	filter: grayscale(1);
	opacity: 0.1;
}

.bg-login-image img.loginGrp {
	display: none;
	position: absolute;
	right: -35px;
	bottom: -25px;
}

.bg-login-image .carousel-indicators {
	position: relative;
	position: static;
	justify-content: left;
	margin: 0;
}

.carousel.slide {
	display: flex;
	flex-direction: column;
}

.carousel.slide .carousel-indicators {
	order: 2;
	padding-top: 25px;
}

.carousel-indicators button {
	width: 10px;
	height: 10px;
	border: none;
	border-radius: 100%;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	margin: 0 2px;
	opacity: 0.5;
}

.carousel-indicators button.active {
	width: 30px;
	border-radius: 5px;
}

.bg-login-image .sliderSec {
	position: relative;
	z-index: 11;
}

.CenterpageTitle {
	position: relative;
	margin-top: -14px;
}

.CenterpageTitle::before {
	content: '';
	width: 160px;
	height: 2px;
	background-color: #fff;
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: -10px;
}

.topListCom {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	margin-top: 10px;
	// border-bottom: $color-light-scale-gray-2-2 1px solid;
	border-bottom: 0 !important;
	padding-bottom: 5px;
	margin-bottom: 5px;
}

.topListCom li {
	list-style: none;
	color: #fafafb;
	font-size: 13px;
	position: relative;
	padding: 0 5px;
	margin: 0 5px;
	cursor: pointer;
	font-weight: 300;
}

// .topListCom li:hover::before {
// 	content: '';
// 	width: 100%;
// 	height: 2px;
// 	background-color: #fff;
// 	position: absolute;
// 	margin: auto;
// 	left: 0;
// 	right: 0;
// 	top: -10px;
// }

// .topListCom li.active {
// 	font-weight: 500;
// }
.vatTotal {
	background-color: transparent !important;
	border-bottom: none !important;
}

.vatTotal .vatInput {
	position: relative;
}

.vatTotal .vatInput .fa-percent {
	position: absolute;
	left: 75px;
	top: 15px;
	font-size: 10px;
}

.vatTotal .vatInput.vatPer .fa-percent {
	top: 8px;
}

.vatTotal .vatInput input.numField {
	margin-left: -5px;
}

.vatTotal td:nth-child(3),
.vatTotal td:nth-child(4),
.vatTotal td:nth-child(5),
.vatTotal td:nth-child(6) {
	// background-color: $color-scale-gray-5 !important;
}

.vatTotal.graybg td:nth-child(4),
.vatTotal.graybg td:nth-child(5),
.vatTotal.graybg td:nth-child(6) {
	background-color: $color-scale-gray-8 !important;
	border-bottom: none !important;
	padding: 4px 0.75rem !important;
}

.vatTotal.graybg td:nth-child(3) {
	background-color: transparent !important;
}

.item.search-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	color: $color-scale-gray-0;
	font-size: 18px;
	cursor: pointer;
}

.sticky-header {
	position: sticky;
	top: 0;
	background: #fff !important;
	border-bottom: none !important;
	box-shadow: $color-light-scale-gray-2-2 0px 1px;
	z-index: 2;
}

.payment-status-indicator {
	padding: 5px 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	border: none;
	font-size: 1rem;

	&.unpaid {
		background-color: $color-danger;
		color: #fff;
	}

	&.partial {
		background-color: $color-warning;
		color: #fff;
	}

	&.paid {
		background-color: $color-success;
		color: #fff;
	}
}

.custom-control-input.shadowdisable:disabled ~ .custom-control-label::before,
.custom-control-input.shadowdisable[disabled] ~ .custom-control-label::before {
	background-color: #ebebeb !important;
}

.welcomeCard .welList {
	margin: 0;
	padding: 0 0 15px 0px;
}

.welcomeCard .welList li {
	list-style: none;
	line-height: 1.1;
}

.welcomeCard .welList li::before {
	content: '•';
	color: #ff007c;
	display: inline-block;
	font-size: 25px;
	width: 0.8em;
}

.instockList {
	overflow-y: auto;
	overflow-x: hidden;
	height: 400px;
	padding: 0;
	margin: 0;
}

.instockList li {
	list-style: none;
	position: relative;
	padding-left: 95px;
	margin-bottom: 20px;
	min-height: 76px;
}

.instockList li a:hover {
	text-decoration: none;
}

.instockList li img {
	border-radius: 100px;
	border: 2px solid #e8e8e8;
	width: 76px;
	position: absolute;
	left: 0;
	top: 0;
}

.instockList li h6 {
	color: #272d3b;
	font-size: 18px;
	margin: 0 0 5px 0;
}

.instockList li a span {
	display: block;
	font-size: 13px;
	color: #767676;
}

.instockList li a:hover h6 {
	color: #0a6ce9;
}

// .showAll select.showOption {
// 	font-weight: 500;
// 	background-color: $color-scale-gray-9;
// 	width: 90px;
// 	font-size: 0.95rem;
// }
.showAll select.showOption:focus,
.showAll select.showOption:focus-visible {
	border: none;
	outline: none;
}

.paginationcon {
	border-top: 1px solid #44444f;
}

.tableData .table {
	margin-bottom: 0;
	/*border-bottom: 1px solid #44444f;*/
	// color: #b5b5be !important;
}

.tableData .table tr td {
}

.tableData .detailBtn {
	cursor: pointer;
	background-color: $color-green-1;
	border-radius: 5px;
	color: #fff !important;
	font-size: 1rem;
	height: auto;
	display: inline-block;
	line-height: 1.5;
	padding: 5px 10px;
	min-width: 60px;
	text-shadow: none;
}

.tableData .detailBtn:hover {
	background-color: $color-green-2;
}

.tableData .detailBtn.graybtn {
	background-color: $color-scale-gray-5;
}

.tableData .detailBtn.graybtn:hover {
	outline: 1px solid #44444f;
}

.tableData .editBox {
	text-align: center;
	background-color: #639fbd;
	border-radius: 5px;
	color: #fff !important;
	font-size: 1rem;
	height: auto;
	display: inline-block;
	line-height: 1.5;
	padding: 5px 10px;
	min-width: 60px;
	text-shadow: none;
}

.tableData .editBox:hover {
	background-color: #67b1d6;
}

.tableData a.btnEdit {
	color: #00ccf2;
}

.tableData a.btnEdit:hover {
	color: #0a6ce9;
	text-decoration: none;
}

.tableData a.btnDel {
	color: #ff007c;
}

.tableData a.btnDel:hover {
	color: #0a6ce9;
	text-decoration: none;
}

.modal-dialog .form-row .form-group .form-control {
	background-color: $color-scale-gray-9;
}

.modal-dialog .form-row .form-group .form-control:focus {
	background-color: #15151d;
}

.modal-dialog .form-row .form-group .form-control:disabled {
	background-color: $color-scale-gray-9;
}

.custom-file-upload-input {
	background-color: $color-scale-gray-9;
	font-size: 1rem;
	border-radius: 5px;
	padding: 6px 15px;
	color: #b5b5be;
	height: auto;
	border: 1px solid transparent;
	display: flex;
	gap: 10px;
	cursor: pointer;
	align-items: center;

	.icon {
		display: contents;
	}
}

.payment-view {
	p {
		color: $color-scale-gray-3;
		margin: 0;
		font-weight: 400;
		font-size: 11px;
	}

	h6 {
		margin: 0;
	}
}

.PhoneInputInput {
	height: 32px;
	border: 1px solid #e8e8ed;
	// background-color: #28282e;
	font-size: 1rem;
	border-radius: 4px;
	padding: 0 15px;
}

input.numField {
	border: 1px solid #696974;
	border-radius: 8px;
	background-color: transparent;
	padding: 5px 10px;
	color: #fff;
	width: 85px;
	// height: 32px;
	height: calc(1.5em + 0.75rem + 5px) !important;
	outline: none;
	text-align: center;
	border-radius: 10px;
}

.blue-border {
	border: 0.5px solid $color-primary !important;
}

.not-allowed-pointer {
	cursor: not-allowed !important;
}

input.numField:focus,
input.numField:focus-visible {
	border-color: #696974;
}

.CurrencySel .transit {
	width: 110px;
}

.notes {
	background-color: $color-scale-gray-9;
	font-weight: normal;
}

.uploadBtn {
	position: relative;
}

.uploadBtn .updBtn {
	position: absolute;
	top: 0;
	right: 25px;
}

.estimate-notes {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.date-group {
		font-size: 1rem;
		color: #000;
	}

	.estimate-note {
		// border-top: 1px solid #e8e8ed;
		// border-bottom: 1px solid #e8e8ed;
		padding: 10px 5px;

		.note-content {
			display: flex;
			align-items: flex-start;
		}

		.note-extra-content {
			display: none;
			overflow: hidden;
			padding-left: 25px;
			font-size: 11px;

			&.visible {
				display: block;
			}

			p {
				margin: 0;
				padding: 0;
			}

			a {
				margin: 0;
				padding: 0;
			}
		}

		.estimate-note-icon {
			flex: 25px;
			width: 25px;
			min-width: 25px;
			max-width: 25px;
			height: 18px;
			font-size: 15px;
			display: flex;
			align-items: center;
		}

		.estimate-note-button {
			flex: 40px;

			span {
				color: $color-primary !important;

				svg {
					transition: 0.1s ease-in-out;
				}

				&.expanded {
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}

		.estimate-note-value {
			flex: 100%;
			font-size: 1rem;
			color: #000;
		}

		.estimate-note-value p {
			padding: 0;
			margin: 0;
		}
	}
}

.est-detail-item {
	display: flex;
	justify-content: space-between;
	color: #000;
	font-size: 1rem;

	p {
		margin: 0;
	}
}

.vatCheck {
	position: relative;

	input {
		padding-right: 35px !important;
	}

	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
	}
}

.vatCheck .fa-check {
	position: absolute;
	right: 20px;
	top: 21px;
	color: $color-green-2;
}

.vatCheck .fa-times {
	position: absolute;
	right: 20px;
	top: 23px;
	color: $color-pink-1;
}

.fillterOpt option {
	font-family: 'Sofia Pro', Arial, Helvetica, sans-serif;
}

.fillterOpt span.fbox {
	border-radius: 10px;
	background-color: $color-scale-gray-9;
	width: 38px;
	height: 38px;
	text-align: center;
	display: inline-block;
}

.sort-sortBy {
	padding: 9px 14px;
	font-size: 13px;
	color: #92929d;
	cursor: pointer;
	position: relative;
	width: 200px;
	-webkit-box-sizing: border-box;
	border: 1px solid #44444f;
	box-sizing: border-box;
	border-radius: 10px;
	background: url(../images/icons/ic_chevron.svg) top 16px right 15px no-repeat $color-scale-gray-9;
}

.fillterOpt span.fbox img {
	margin-top: 11px;
}

.sort-sortBy span {
	color: #d5d5dc;
	font-weight: 500;
	padding-left: 5px;
}

.sort-sortBy:hover {
	border: 1px solid #44444f;
}

.sort-sortBy:hover .sort-list {
	display: block;
}

.sort-sortBy .sort-list {
	width: 200px;
	background-color: $color-scale-gray-9;
	position: absolute;
	top: 31px;
	left: -1px;
	margin: 0;
	padding: 5px 0 0;
	padding-left: 0px;
	z-index: 99;
	display: none;
	border: 1px solid #44444f;
	border-top: none;
	-webkit-box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
	border-radius: 0 0 10px 10px;
	overflow: hidden;
}

.sort-sortBy .sort-list li {
	list-style: none;
	padding: 8px 15px;
}

.sort-sortBy .sort-list li label {
	margin: 0;
}

.sort-sortBy .sort-list li:hover {
	background-color: #272732;
}

.customersDataList {
	height: 100%;
}

.customersDataList li {
	list-style: none;
	padding-bottom: 40px;
	padding-right: 20px;
}

.customersDataList li label {
	font-weight: bold;
	display: block;
	margin: 0;
}

.table-sm.table-borderless {
	// color: #fff /*#b5b5be*/;
	font-size: 15px;
}

.table-sm.table-borderless tr td:first-child {
	font-weight: 600;
}

.settingsPage .profilePic {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.settingsPage .profilePic span.imgCon {
	overflow: hidden;
	border-radius: 100%;
	width: 150px;
	height: 150px;
	display: block;
	margin: 0;
	border: 5px solid #f4f7fe;
}

.settingsPage .profilePic span.imgCon img {
	max-width: 100%;
}

.settingsPage .profilePic .uploadpImg {
	display: block;
	position: absolute;
	bottom: 0;
	transform: translateY(50%);
}

.settingsPage .profilePic .uploadpImg i.fa-pencil-alt {
	font-size: 16px;
}

.settingsPage .nav-tabs .nav-link.active,
.settingsPage .nav-tabs .nav-link:hover {
	color: #0a6ce9;
	border: none;
	border-bottom: 2px solid #0a6ce9;
}

.settingsPage .nav-tabs .nav-link {
	border: none;
	background: none;
}

.passwordReset .form-group {
	position: relative;
}

.passwordReset .form-group .fa-eye {
	position: absolute;
	right: 25px;
	top: 54px;
	cursor: pointer;
}

.passwordReset .form-group .text-danger {
	position: absolute;
	left: -10px;
	bottom: -42px;
	margin: 0;
	font-size: 15px;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #2e2f37;
	background-color: #eaecf4;
}

.TaskList .row .col-lg-6 .cardBox {
	/*margin-right: 2.5rem;*/
	margin-bottom: 1.5rem;
}

.cardBox {
	background-color: #262626;
	position: relative;
	color: #fff /*#b5b5be*/ !important;
	font-size: 0.875rem;
}

.cardBox::after {
	position: absolute;
	left: 20px;
	top: 0;
	width: 125px;
	height: 5px;
	content: '';
	background-color: #fff;
	border-radius: 0 0px 5px 5px;
}

.cardBox h4 {
	font-size: 1.25rem;
}

.cardBox .userName span.userImg {
	width: 32px;
	height: 32px;
	background-color: #59626f;
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
	overflow: hidden;
}

.dataTables_info,
.paging_simple_numbers {
	font-size: 1rem;
}

.cardBox .actBtn a.btn-primary {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	vertical-align: middle;
}

.cardBox .actBtn a.btn-primary:hover {
	text-decoration: none;
}

.cardBox .actBtn a.btn-primary.acceptBtn {
	background-color: #498ae5;
}

.cardBox .actBtn a.btn-primary.acceptBtn:hover {
	background-color: #3d7dd8;
}

.cardBox .actBtn a.btn-primary.detailsBtn {
	background-color: #f1f4f5;
	color: #59626f;
}

.cardBox .actBtn a.btn-primary.detailsBtn:hover {
	background-color: #dee2e4;
}

.cardBox .actBtn a.reportBtn {
	color: #fff;
}

.cardBox .actBtn a.reportBtn:hover {
	text-decoration: none;
}

.cardBox .boxDate {
	background-color: #fff;
	color: #59626f;
	border: 1px solid #fff;
	padding-top: 0.15rem;
	padding-bottom: 0.1rem;
	font-weight: 600;
}

.cardBox.dueDate-24 .boxDate,
.TaskDetails.dueDate-24 .boxDate,
.TaskDetails.dueDate-24 .boxDate:active,
.cardBox.dueDate-24 .boxDate:active {
	color: #e98282;
	border-color: #e98282;
	background-color: #feebeb;
}

.cardBox.dueDate-24::after,
.TaskDetails.dueDate-24 .boxDate:after {
	background-color: #e98282;
}

.TaskDetails {
	position: relative;
	margin-top: 24px;
}

.TaskDetails .topBtnSign {
	--statusColor: grey;
	background-color: var(--statusColor);
	border-radius: 0;
	padding: 1px 5px;
	border-radius: 0 0 3px 3px;
	position: absolute;
	z-index: 3;
	font-size: 10px;
	color: #fff;
	left: 26px;
	min-width: 81px;
	text-align: center;
}

.TaskDetails .topBtnSign .fa {
	font-size: 6px;
	margin-right: 5px;
}

.cardBox.dueDate-far:after {
	background-color: #82aee9;
}

.cardBox.accepted:after {
	background-color: #59626f;
}

.TaskDetails {
	.detail-item {
		display: flex;
		flex-direction: column;
		gap: 4px;

		.detail-label {
			margin: 0;
			font-size: 11px;
		}

		.detail-value {
			font-size: 13px;
		}
	}

	.product-list-box {
		tr {
			&:hover {
				background-color: $color-light-scale-gray-2;
			}

			td {
				padding-block: 10px;
			}
		}
	}

	.estimate-product-list-box {
		padding: 10px;
		border: 1px solid $color-light-scale-gray-3;
		border-radius: 10px;

		.epi-header {
			padding-inline: 5px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid $color-light-scale-gray-2;
			padding-bottom: 5px;

			h6 {
				font-size: 13px;
				margin: 0;
			}

			p {
				font-size: 1rem;
				margin: 0;
			}
		}

		.epi-footer {
			padding-inline: 5px;
			display: flex;
			justify-content: space-between;
			border-top: 1px solid $color-light-scale-gray-2;
			padding-top: 5px;
			font-size: 1rem;

			.barcode {
				svg {
					width: 100% !important;
				}
			}
		}

		tr {
			&:hover {
				background-color: $color-light-scale-gray-2;
			}

			td {
				padding-block: 10px;
			}
		}
	}
}

.col-md-20 {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	position: relative;
}

@media (min-width: 768px) {
	.col-md-20 {
		flex: 0 0 20%;
		max-width: 20%;
	}
}

.gray-border {
	border-color: #59626f !important;
}

.inspectRow {
	position: relative;
}

.inspectRow .switchIcons {
	position: absolute;
	width: 24px;
	left: 0;
	right: 0;
	margin: auto;
	top: 50%;
	z-index: 99;
}

.inspectRow .switchIcons span {
	cursor: pointer;
	display: block;
	height: 17px;
	font-size: 18px;
}

.inspectRow .switchIcons span:hover path {
	fill: #fff;
}

.inspectRow .switchIcons span.active path {
	fill: $color-light-scale-gray-8;
}

.inspectRow .switchIcons span.disable {
	pointer-events: none !important;
}

.inspectRow .switchIcons span.disable path {
	fill: $color-light-scale-gray-3;
	pointer-events: none !important;
}

.TaskDetails {
	color: #000000 !important;
	font-size: 0.875rem;
}

.plusMinusAct svg {
	cursor: pointer;
}

.plusMinusAct svg path {
	fill: #59626f;
}

.plusMinusAct svg:hover path {
	fill: #fff;
}

.table td {
	border: none;
	font-size: 13px;
}

.table th {
	border: none;
	font-size: 13px;
	font-weight: 600;
	text-transform: capitalize;
	padding: 8px 12px;
	border-bottom: none !important;
}

.estimateCollapse > td {
	padding: 0 !important;
}

.estimateCollapse > td > div.row {
	animation: rollDown 1s ease-out;
	width: 100%;
	padding-top: 10px;
}

@keyframes rollDown {
	from {
		max-height: 0;
		overflow: hidden;
	}

	to {
		max-height: 1000px;
		overflow: hidden;
	}
}

.darkMode .productsTable .table thead tr:nth-child(odd) {
	background-color: $color-scale-gray-6;
}

.darkMode .productsTable .table tr:nth-child(odd) {
	background-color: $color-scale-gray-5;
	border-bottom: 1px solid #2225294a;
}

.darkMode .productsTable.foundListPopup .table thead tr:nth-child(odd) {
	background-color: $color-scale-gray-9;
}

.darkMode .productsTable.foundListPopup .table tr:nth-child(odd) {
	background-color: #31313d;
}

.darkMode .productsTable.foundListPopup .table tr:nth-child(even) {
	background-color: $color-scale-gray-9;
}

.tableData.CollapseTable .table thead tr:nth-child(odd) {
	background-color: #131319;
}

.tableData.CollapseTable .table tr:nth-child(odd) {
	background-color: #31313d;
}

.tableData.CollapseTable .table tr:nth-child(even) {
	background-color: #131319;
}

.darkMode .estimateCollapse .table-sm.table-borderless,
.darkMode .estimateCollapse .table-sm.table-borderless tr:nth-child(odd),
.darkMode .estimateCollapse .table-sm.table-borderless tr:nth-child(even) {
	background-color: $color-scale-gray-5;
}

.darkMode .estimateCollapse > td {
	padding-top: 0 !important;
}

.foundListPopup .form-group .custom-select__control {
	height: 30px;
	background: transparent;
}

.darkMode .bg-success {
	background-color: #36483f !important;
	color: $color-success !important;
}

.darkMode .bg-danger {
	background-color: #4d2f3a !important;
	color: $color-danger !important;
}

ul.pagination .page-link {
	background-color: transparent;
	border: none;
	color: #858796;
}

.page-item.active .page-link,
.page-item .page-link:hover {
	border: none;
	color: #4e73df;
}

ul.pagination .previous,
ul.pagination .next {
	border: 1px solid #44444f;
	border-radius: 6px;
}

$pagination-color: transparent;

ul.pagination .paginate_button {
	border: solid $pagination-color;
	border-width: 1px 0.5px;
	border-radius: 0;

	a {
		color: $pagination-color;
	}

	&:first-child {
		border-width: 1px 0.5px 1px 1px;
		border-radius: 6px 0 0 6px;
	}

	&:last-child {
		border-width: 1px 1px 1px 0.5px;
		border-radius: 0px 6px 6px 0px;
	}

	&.active {
		background: $pagination-color;

		a {
			background: $pagination-color;
			color: $color-scale-gray-0;
		}
	}

	a.disabled {
		color: #858796;
	}
}

.darkMode .dropdown-menu {
	background-color: $color-scale-gray-9;
	border: 1px solid #2b2b34;
	color: #92929d;
}

.darkMode .dropdown-divider {
	border-color: #2b2b34;
}

.darkMode .dropdown-item {
	color: #92929d;
}

.darkMode .dropdown-item:focus,
.darkMode .dropdown-item:hover {
	background-color: #272732;
}

.darkMode .modal-content {
	background-color: $color-scale-gray-5;
}

.darkMode .modal-content .modal-header,
.darkMode .modal-content .modal-footer,
.darkMode .modal-content .modal-body {
	border-color: #44444f;
	color: #fafafb;
	font-size: 0.9rem;
}

.darkMode .modal-content .close {
	color: #fff;
	text-shadow: none;
}

.darkMode input[type='date'] {
	color-scheme: dark;
}

.darkMode input[type='date']::-webkit-calendar-picker-indicator {
	cursor: pointer;
	border-radius: 4px;
	margin-right: 2px;
	filter: invert(0);
}

select.form-control {
	background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%),
		linear-gradient(to right, #ccc, #ccc);
	background-position: calc(100% - 20px) 50%, calc(100% - 15px) 50%, calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 60%;
	background-repeat: no-repeat;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
}

select.form-control:focus {
	background-image: linear-gradient(45deg, #fff 50%, transparent 50%), linear-gradient(135deg, transparent 50%, #fff 50%),
		linear-gradient(to right, #ccc, #ccc);
	background-position: calc(100% - 15px) 50%, calc(100% - 20px) 50%, calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 60%;
	background-repeat: no-repeat;
	outline: 0;
}

/* Custom CSS */
.iconbtn {
	margin-right: 12px;
	cursor: pointer;
	min-width: unset !important;
}

.iconbtn i.fas {
	font-size: 13px;
}

.tableData span.btnEdit {
	color: #69737d;
}

.tableData span.btnEdit:hover {
	color: #73768a;
	text-decoration: none;
}

.tableData span.btnDel {
	color: #ef466f;
}

.tableData span.btnDel:hover {
	color: #d32c56;
	text-decoration: none;
}

.passwordReset .form-group .fa-eye,
.passwordReset .form-group .passeye {
	position: absolute;
	right: 25px;
	bottom: 21px;
	cursor: pointer;
}

.LoginForm .form-group i.far.vieweye {
	position: absolute;
	right: 25px;
	bottom: 21px;
}

/* Switch Color */
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
	background-color: #1cc88a;
}

.Mui-checked .MuiSwitch-thumb {
	// color: #1cc88a;
}

/* Button Blink CSS */
.login-button {
	position: relative;
	display: inline-block;
	padding: 8px 16px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.login-button:active {
	transform: scale(0.95);
}

.login-button.loading {
	pointer-events: none;
	color: #fff;
}

.login-button.loading:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 24px;
	height: 24px;
	margin-top: -12px;
	margin-left: -12px;
	border-radius: 50%;
	border: 4px solid transparent;
	/* border-top-color: #007bff; */
	animation: spin 1s infinite linear;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

/* Roles  */
.roles {
	max-width: 100%;
	font-size: 0.8125rem;
	/* display: -webkit-inline-box; */
	/* display: -webkit-inline-flex; */
	display: -ms-inline-flexbox;
	display: inline-flex;
	/* -webkit-align-items: center; */
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	height: 25px;
	color: #dad0e9;
	background-color: #3e3947;
	border-radius: 16px;
	white-space: nowrap;
	-webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	cursor: default;
	outline: 0;
	-webkit-text-decoration: none;
	text-decoration: none;
	padding: 0px 12px;
	vertical-align: middle;
	box-sizing: border-box;
	margin: 3px;
}

.activeStatus {
	word-break: break-word;
	color: #fff;
	padding: 1px 5px;
	font-size: 10px;
	border-radius: 3px;
	display: inline-block;
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	min-width: 43px;
}

.inactiveStatus {
	word-break: break-word;
	color: #fff;
	padding: 1px 5px;
	font-size: 10px;
	border-radius: 3px;
	display: inline-block;
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	min-width: 43px;
}

h6.password-heading {
	color: #fafafb;
	font-size: 20px;
}

.error-message {
	font-size: 13px;
	position: absolute;
	transition: 0.5s;
}

.down-error {
	font-size: 13px;
	transition: 0.5s;
}

/* Custom Select */
.custom-select > div {
	min-height: 60px;
	border: 1px solid #e2e2e2;
	background: #fff;
	font-size: 1rem;
	border-radius: 100px;
	padding: 10px 25px;
}

.MuiMenu-list {
	// height: 250px;
}

.action-col {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

// .print-repair-label {
// 	@media print {
// 		@page { size: landscape; }
// 	  }
// }

.plain-iconbtn {
	background: transparent;
	border: none;
	box-shadow: none;
	outline: none;
	color: $color-primary-2 !important;
	font-size: 1rem;
	padding: 5px;
	cursor: pointer;
	height: 25px;
	width: 25px;
	border: 1px solid $color-light-scale-gray-3;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;

	&:hover {
		background-color: #fff;
		border-color: $color-primary-2;
	}

	.dropdown-toggle {
		white-space: nowrap;
		padding: inherit;
		width: 20px;
	}

	&.disabled {
		background-color: $color-light-scale-gray-2;
		color: $color-light-scale-gray-3 !important;
	}
}

.plain-textbtn {
	background: transparent;
	border: none;
	box-shadow: none;
	outline: none;
	color: $color-primary-2 !important;
	font-size: 1rem;
	cursor: pointer;
	height: 25px;
	border: 1px solid $color-light-scale-gray-3;
	padding: 0 10px;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	gap: 5px;

	&:hover {
		background-color: #fff;
		border-color: $color-primary-2;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3);
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3);
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover {
			background-color: darken($color: $color-warning, $amount: 3);
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover {
			background-color: darken($color: $color-success, $amount: 3);
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2;
		color: $color-light-scale-gray-3 !important;
	}
}

.overview-more {
	.dropdown-toggle::after {
		display: none;
	}

	.dropdown-toggle {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 3px;
	}
}

.not-found-container {
	color: $color-light-scale-gray-3;
	height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;

	.not-found-icon {
		font-size: 50px;
	}

	.not-found-text {
		font-size: 16px;
	}

	height: 40vh;
}

.whitebg {
	background: #fff !important;
}

.nav-title {
	font-size: 13px;
}

.productRow .inner-col {
	border: 1px solid #44444f;
	padding: 20px;
	border-radius: 10px;
	display: flex;
	height: 60vh;
	flex-direction: column;
}

.productRow .bottombtns {
	align-self: end;
	flex: auto;
	width: 100%;
}

.productRow .inner-col h4 {
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 25px;
}

.productRow .inner-col input.form-control {
	min-height: 40px;
	border: 1px solid transparent;
	background-color: $color-scale-gray-6;
	font-size: 1rem;
	border-radius: 4px;
	padding: 2px 15px;
	color: #b5b5be;
	font-size: 13px;
}

.productRow .inner-col input.form-control:focus {
	background-color: #272732;
	border: 1px solid #272732;
	box-shadow: none;
	color: #b5b5be;
}

.productRow .inner-col .imeiList {
	list-style: none;
	min-height: 40vh;
}

.productRow .inner-col .imeiList li {
	font-size: 13px;
	line-height: 24px;
}

.productRow .bottombtns .icon img {
	width: 16px;
}

.productRow .bottombtns a:hover {
	text-decoration: none;
}

.productRow .reviewList {
	min-height: 400px;
	overflow-y: auto;
}

.productRow .reviewList ul {
	gap: 15px;
}

.productRow .reviewList li {
	list-style: none;
	font-size: 13px;
	flex: 1 1 auto;
}

.productRow .reviewList li.infoIcon {
	width: 10px;
	text-align: center;
}

.productRow .reviewList li.textEllipsis {
	min-width: 150px;
	max-width: 150px;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
}

.productRow .reviewList li.textEllipsis.imeiNo {
	min-width: 120px;
	max-width: 120px;
}

/*Auto Suggestion add by Ravi*/

.react-autosuggest__container {
	position: relative;
}

/* .react-autosuggest__input--open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
  } */

.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	top: 63px;
	width: 100%;
	border: 1px solid #e2e2e2;
	background-color: #fff;
	font-family: Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	border-radius: 10px;
	z-index: 2;
	box-shadow: 0 0 5px #e1d9d9;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #ddd;
}

.footer {
	margin: 9px 20px;
	font-size: 1rem;
	color: #777;
}

#pfile.MuiButton-containedInfo {
	background-color: #5d5c5c;
}

.estimate-title {
	display: flex;
	align-items: center;
	gap: 10px;
}

.status-badge {
	padding: 3px 7px;
	font-weight: 400;
	font-size: 11px;
	// height: fit-content;
}

.card-head {
	border-bottom: 1px solid $color-scale-gray-5;
}

.custom-control-label::before,
.custom-control-label::after {
	background-color: transparent;
}

.estimate-company {
	td {
		padding: 0.1rem 0.3rem;
	}
}

.btn.i-icon-btn {
	padding: 2px;
	border: none;
	outline: none;
	box-shadow: none;
}

.form-group-inline {
	display: flex;
	align-items: center;

	& label {
		width: 120px;
		margin: 0;
	}
}

.notes-card {
	background-color: $color-scale-gray-6;
	border-radius: 5px;
	padding: 10px;
	color: #fff;

	h5 {
		padding: 0;
		margin: 0;
		font-size: 13px;
	}

	p {
		font-size: 1rem;
	}

	p.text-center {
		color: $color-scale-gray-2;
	}
}

.estimation-table-area {
	display: flex;

	table {
		width: 100%;
	}

	.table-side {
		width: 100%;
	}

	div.estimate-detail-container {
		width: 100%;
		animation: estimateDetailsExpand 0.3s;
		background: #fafafa;
		border-radius: 5px;
		margin-bottom: 5px;
		margin-right: 14px;

		.est-title {
			font-size: 13px;
			font-weight: 600;
			padding: 0.3rem;
			margin: 0;
		}

		position: relative;
	}
}

.custom-list-checkbox {
	padding-left: 10px !important;
	min-height: unset !important;

	label {
		height: 100% !important;
	}

	label::before {
		left: -10px !important;
		top: 50% !important;
		translate: 0 -50%;
	}

	label::after {
		left: -10px !important;
		top: 50% !important;
		translate: 0 -50%;
	}
}

.medium-sized-container {
	min-width: 768px;
	max-width: 768px;
	color: #000;
	font-size: 1rem;

	.tableData .responsive-view > table {
		min-width: 700px !important;
	}
}

.tableData .responsive-view > table.min-width-unset {
	min-width: unset !important;
}

.task-date-col {
	display: flex;
	position: relative;

	.priority-line {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 3px;
	}
}

@keyframes bounceIn {
	0% {
		scale: 1;
	}

	50% {
		scale: 1.01;
	}

	100% {
		scale: 1;
	}
}

@keyframes estimateDetailsExpand {
	0% {
		width: 0px;
	}

	100% {
		width: 100%;
	}
}

.estimate-details-actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
	position: absolute;
	background-color: #fafafa;
	bottom: 0;
	left: 0;
	border-radius: 5px;
	padding-block: calc(0.3rem + 8px);
}

.cursor-pointer {
	cursor: pointer;
}

.view-toggle {
	svg {
		font-size: 13px;
	}

	button {
		background-color: transparent;
		border-color: $color-light-scale-gray-3;
		color: $color-primary-2;

		&.Mui-selected {
			background-color: $color-light-scale-gray-2;
		}

		&:not(:first-of-type).Mui-selected {
			background-color: $color-light-scale-gray-2;
			border-color: $color-light-scale-gray-3 !important;
		}
	}
}

.btn-add-variations {
	float: right;
}

.estimate-option-card {
	border-radius: 5px;
	padding: 10px;

	.no-records {
		width: 100%;
		height: 50px;
		font-size: 1rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-light-scale-gray-4;
	}

	.estimate-option-card-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		margin: 0;
		margin-bottom: 10px;

		h5 {
			padding: 0;
			margin: 0;
			font-size: 1rem;
		}

		.btn {
			padding: 5px;
			font-size: 1rem;
		}
	}

	.estimate-option-items {
		display: flex;
		flex-wrap: wrap;
		gap: 2%;
		row-gap: 5px;
		color: #fff;

		.estimate-option-item {
			// width: 40%;
			width: 100%;

			box-shadow: 0px 0px 5px 0px #f1f1f1;

			.estimate-option-item-body {
				display: flex;
				// flex-direction: column;
				justify-content: space-between;
				align-items: center;
				padding: 10px 10px;
				border-radius: 4px;
				gap: 0px;
				position: relative;
				overflow: hidden;

				p {
					margin: 0;
					color: inherit;
					font-size: 1rem;
				}

				.pay-amount {
					color: $color-green-1;
					font-weight: 500;
				}
			}

			.estimate-option-item-expansion {
				max-height: 0;
				overflow: hidden;
				box-sizing: border-box;
				width: 100%;

				img {
					width: 100%;
				}

				max-height: 0;
				padding-inline: 0;
				padding-bottom: 0;

				&.expand {
					max-height: 100%;
					padding-inline: 20px;
					padding-bottom: 20px;
				}

				transition: 0.3s ease-in-out;
			}

			.control-view {
				position: absolute;
				width: 100%;
				height: 100%;
				background: #1a1a1a56;
				backdrop-filter: blur(2px);
				border-radius: 5px;
				left: -100%;
				z-index: 5;
				justify-content: center;
				align-items: center;
				top: 0;
				display: flex;
				transition: 0.2s ease-in;
				gap: 5px;

				&:hover {
					left: 0;
				}

				.i-icon-btn.view {
					color: rgb(254, 254, 254);
				}

				.i-icon-btn.edit {
					color: rgb(254, 254, 254);
				}

				.i-icon-btn.delete {
					color: rgb(254, 254, 254);
				}
			}

			.control-icon {
				position: absolute;
				right: 10px;
				top: 5px;
				color: $color-scale-gray-4;
				width: 10px;
				height: 10px;
				z-index: 6;
				font-size: 1rem;
				cursor: pointer;

				&:hover ~ div.control-view {
					left: 0;
				}
			}

			.download-actions {
				display: flex;
				font-size: 10px;
				width: 100%;
				gap: 5px;
				padding: 0px 5px;
				padding-top: 5px;

				.btn {
					flex: 1;
					font-size: 10px;
				}
			}
		}
	}
}

.tbl-space {
	border-collapse: separate;
	border-spacing: 0 0px;
	// border-spacing: 0 5px;
}

.download-option {
	justify-content: end;
}

.status-Success {
	color: #569e8d !important;
}

.status-Warning {
	color: #f7a05a !important;
}

.blur {
	filter: blur(8px);
	transition: filter 0.3s ease-in-out; // Added transition
}

.status-Danger {
	color: #f46b5b !important;
}

/*.userForm .form-card {
    position: relative;
    height: 60vh; 
	overflow: hidden;
    overflow-y: auto; 
  }*/

/*  Webkit (Chrome, Safari, Opera, Edge) / */
/* width */
::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-thumb {
	background: #ffffff91;
}

body {
	scrollbar-width: thin;
	/* Set the width of the scrollbar */
	scrollbar-color: #fff transparent;
	/* Set thumb and track colors */
}

/* Style the thumb of the scrollbar */
body::-webkit-scrollbar-thumb {
	background: #ffffff91;
	/* This rule will only be applied by WebKit browsers */
}

/* Track */
/* ::-webkit-scrollbar-track {
	background: #f1f1f1; 
  } */

/* Handle */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
	background: #fff; 
  } */
.form-col {
	padding: 10px 10px;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: end;
}

.form-inp {
	width: 70%;
}

.labl-form {
	width: 20%;
	margin-left: 10%;
	text-align: right;
}

.product-add {
	display: flex;
	justify-content: center;
}

.btn-add-producct {
	width: 20% !important;
}

.btn-add-producct:hover {
	background-color: #0066ff;
	color: #fff;
	border: none;
}

.cstm-not-found {
	background-color: #f9f9f9;
	border-radius: 10px;
}

.product-history-list {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;

	.product-history {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid $color-scale-gray-6;
		padding: 10px;
		border-radius: 5px;
		flex: 100%;
		width: 100%;

		& > div:first-child {
			display: flex;
			gap: 10px;
			align-items: center;
		}
	}
}

.product-attachments-list {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;

	.product-attachment {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid $color-scale-gray-6;
		padding: 10px;
		border-radius: 5px;
		flex: 48%;
		width: 48%;

		& > div:first-child {
			display: flex;
			gap: 10px;
			align-items: center;
		}
	}
}

.imei-section {
	display: flex;
	flex-direction: column;
	height: 100%;

	border: 1px solid #44444f;
	padding: 15px 10px;
	border-radius: 10px;
	text-align: center;

	input.form-control {
		background-color: #28282e;
		border: 1px solid transparent;
		border-radius: 4px;
		color: #b5b5be;
		font-size: 1rem;
		font-size: 1rem;
		min-height: 0;
		padding: 2px 15px;
	}

	h4 {
		font-size: 13px;
	}

	& > div:first-child {
		flex: 70px;
		height: 70px;
	}

	& > div:nth-child(2) {
		flex: auto;
		height: auto;
		overflow-y: scroll;
		font-size: 13px;
	}

	& > div:last-child {
		flex: 20px;
		height: 20px;
	}

	.imei-list-item {
		display: flex;
		justify-content: space-between;

		span {
			&:hover {
				color: #fff;
			}
		}
	}

	.imei-section-actions {
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		padding-inline: 10px;
		color: $color-light-scale-gray-0;

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5px;
			cursor: pointer;
		}
	}
}

.review-section {
	display: flex;
	flex-direction: column;
	height: 100%;

	border: 1px solid #44444f;
	padding: 15px 20px;
	border-radius: 10px;
	text-align: center;

	input.form-control {
		background-color: #28282e;
		border: 1px solid transparent;
		border-radius: 4px;
		color: #b5b5be;
		font-size: 1rem;
		font-size: 1rem;
		min-height: 0;
		padding: 2px 15px;
	}

	h4 {
		font-size: 13px;
	}

	& > div:first-child {
		flex: 20px;
		height: 20px;
	}

	& > div:nth-child(2) {
		flex: auto;
		height: auto;
		overflow-y: scroll;
		font-size: 13px;
	}

	& > div:last-child {
		flex: 20px;
		height: 20px;
	}

	.review-section-actions {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
		padding-inline: 10px;
		color: $color-light-scale-gray-0;

		a {
			cursor: pointer;
			color: #fff;

			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5px;

			&.disabled {
				color: #a3a3a3;
			}
		}
	}
}

.modelDataList li {
	padding-bottom: 20px;
}

.estimate-side-info {
	display: flex;
	flex-direction: column;

	padding-left: 20px;
	text-align: right;

	gap: 10px;

	.estimate-info-item {
		display: flex;
		gap: 10px;
		align-items: center;
		// justify-content: flex-end;

		label {
			margin: 0;
			font-size: 11px;
		}

		.estimate-info-value {
			color: #000;

			margin: 0;
			font-size: 1rem;
			display: flex;
			align-items: center;
			gap: 5px;

			p {
				margin: 0;
			}

			span {
				// font-size: 9px;
				color: $color-primary;
			}
		}
	}
}

.estlist {
	table {
		td,
		th {
			font-size: 1rem;

			&:first-child {
				padding-left: 0;
			}
		}
	}
}

.est-labels {
	font-size: 1rem;
	color: #000;
}

.invalid-row {
	background-color: #ff777729 !important;
}

.ship-summary-card {
	font-size: 1rem;

	.ship-summary-card-head {
		color: $color-light-scale-gray-5;
	}
}

.rs-filter-container {
	.rs-filter__control {
		background-color: transparent;
		padding: 5px 10px;
		min-height: unset;

		&:hover {
			box-shadow: none;
		}
	}

	.rs-filter__input {
		font-size: 1rem;
	}

	.rs-filter__control--is-focused {
		box-shadow: none;
	}

	.rs-filter__value-container {
		padding: 0;
	}

	.rs-filter__placeholder {
		margin: 0;
		font-size: 1rem;
	}

	.rs-filter__input-container {
		margin: 0;
		padding: 0;
	}

	.rs-filter__indicator {
		padding: 0 0px 0 8px;
		box-sizing: border-box;
	}

	.rs-filter__multi-value {
		margin: 0;
		margin-inline: 2px;
	}

	.rs-filter__indicators {
		gap: 2px;
	}
}

.filter-btn {
	min-width: unset;
	padding: 5px 15px;
}

.error-input {
	border-color: $color-danger !important;
}

@media (min-width: 1680px) {
	/*.TaskList .row .col-lg-6:nth-child(3n-1) .cardBox{ margin-right: 2.5rem; margin-bottom: 1.5rem; margin-left: 2.5rem; }*/
}

@media (min-width: 1380px) {
	.p-5 {
		padding: 7rem !important;
	}

	.welcomeCard .welImg {
		margin-top: -50px;
	}

	.TableSerachBar .navbar-search input.form-control {
		width: 250px;

		&:placeholder-shown {
			width: 150px;
		}

		transition: width 0.3s ease-in-out;

		&:focus {
			width: 250px;
		}
	}
}

@media (max-width: 1580px) {
	body {
		font-size: 1rem;
	}

	// .h4,
	// h4 {
	// 	font-size: 1.25rem;
	// }
	// .h5,
	// h5 {
	// 	font-size: 1.05rem;
	// }
	// .h3,
	// h3 {
	// 	font-size: 1.35rem;
	// }
	// .h2,
	// h2 {
	// 	font-size: 1.55rem;
	// }
	// .form-row .form-group .form-control,
	// .transit {
	// 	font-size: 0.9rem;
	// 	min-height: 50px;
	// }
	.form-row .form-control-select .MuiSelect-select {
		min-height: 42px;
	}

	.PhoneInputInput {
		height: 50px !important;
		font-size: 0.12px !important;
	}

	.userForm .form-group label,
	.form-row .MuiInputLabel-formControl {
		margin-bottom: 10px;
		font-size: 1rem;
	}

	.form-row .MuiInputLabel-formControl {
		font-size: 20px;
	}

	.cardBox h4 {
		font-size: 1.1rem;
		max-width: 220px;
	}

	.cardBox {
		font-size: 0.75rem;
	}

	.cardBox .btn-sm {
		font-size: 0.75rem;
	}

	.form-row .MuiFormControl-root .MuiBox-root {
		margin-bottom: 25px;
	}

	.userName .pr-name {
		max-width: 100px;
	}

	.table-sm.table-borderless {
		font-size: 1rem;
	}
}

@media (max-width: 1440px) {
	.userName .pr-name {
		max-width: 80px;
	}

	// .form-row .form-group .form-control,
	// .transit {
	// 	height: 45px;
	// 	font-size: 0.8rem;
	// }

	.table th {
		font-size: 13px;
	}

	.table td {
		font-size: 13px;
	}

	.topListCom li {
		font-size: 13px;
	}
}

@media (max-width: 1380px) {
	.userName .pr-name {
		max-width: 65px;
	}
}

@media (max-width: 1180px) {
	.userName .pr-name {
		max-width: 100%;
	}

	.bg-login-image img.loginGrp {
		width: 280px;
	}
}

@media (max-width: 1024px) {
	.form-row .form-group .form-control,
	.h60 {
		height: 45px;
	}

	.loginScr-bg {
		min-height: 100dvh;
	}
}

@media (max-width: 768px) {
	.h2,
	h2 {
		font-size: 1.5rem;
	}

	.TaskList .row .col-lg-6 .cardBox {
		/*margin-right: 2.5rem;*/
		margin-bottom: 1rem;
	}
}

@media (max-width: 680px) {
	.loginScr-bg {
		height: 100dvh;
	}

	.btn {
		font-size: 0.8rem;
		/*padding: 6px 12px !important;*/
	}

	.customersDataList {
		padding: 0;
		border: none !important;
	}

	.tableData tr {
		// margin: 0 10px 15px;
		// display: block;
		border: 1px solid #e3e6f0;
		border-top: none;
	}

	.tableData tbody tr:first-child {
		// margin-top: 10px;
	}

	.tableData tr:nth-child(odd) {
		background-color: #f7faff;
	}

	.tableData thead {
		// display: none;
	}

	/*.tableData tr td:first-child {
		display: none;
	}*/
	.tableData tr td {
		// display: block;
		// padding: 0.45rem;
	}

	.tableData tr td::before {
		color: #333;
		font-weight: 600;
	}

	.customersDataList li {
		padding-bottom: 20px;
	}
}

.btn:hover {
	background: #0066ff;
}

.customer-paper-bg {
	margin-top: 50px;
}

.page-agreement-container {
	margin-top: -20px;
}

/* Medium devices (≥992px) */
.page-agreement-bg {
	background-image: url('../images/curved-bg.svg');
	width: fit-content;
	// padding: 63px 116px 71px 101px;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin-top: -650px;
}

.returns-daterangepicker-container {
	.rdrDateRangePickerWrapper {
		.rdrDefinedRangesWrapper {
			display: none !important;
		}
	}

	.rdrDateRangePickerWrapper {
		border: 1px solid #cecece;
	}
}

@media (min-width: 992px) {
	.customer-paper-bg {
		background-image: url('../images/agreement-bg-customer.svg');
		width: fit-content;
		padding: 163px 400px 100px 400px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		aspect-ratio: 1655 / 1488;
	}

	.page-agreement-container {
		margin-top: -100px !important;
	}

	.confirm-agreement-container {
		width: 93vw;
	}

	.returns-popup-body {
		padding: 0.5rem 50px;
	}

	.modal-card-padding {
		padding: 20px 30px;
	}
}

/* Small devices (≥768px) */
@media (min-width: 768px) {
	.customer-paper-bg {
		background-image: url('../images/agreement-bg-customer.svg');
		width: fit-content;
		padding: 163px 400px 100px 400px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		aspect-ratio: 1655 / 1488;
	}

	.page-agreement-container {
		margin-top: -100px !important;
	}

	.confirm-agreement-container {
		width: 93vw;
	}

	.returns-popup-body {
		padding: 1.5rem 30px;
	}
}

/* Extra small devices (≥576px) */
@media (min-width: 576px) {
}

@import './dashboard-light';
@import './dashboard-dark';

.borderless-table-custom {
	width: 100%;
}

.borderless-table-custom th,
.borderless-table-custom td {
	border: none;
	padding: 10px;
}

.custom-modal-two .modal-dialog,
.custom-modal-2 .modal-dialog {
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom-modal-two .modal-content,
.custom-modal-2 .modal-content {
	padding: 0;
	/* Remove padding */
	min-width: 1000px;
	/* Set min width */
	margin: auto;
	/* Center the content */
}

.custom-modal-2 {
	max-width: 1000px !important;
}

.hover-card {
	box-sizing: border-box;
	border: 2px solid transparent !important;
}

.hover-card.hover-card:hover {
	border-color: #0065ff !important;
}

.hover-card.hover-card:hover a {
}

.no-underline-link:hover {
	text-decoration: none;
}

.dropzone {
	width: 100%;
	height: 200px;
	border: 2px dashed #888888;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 20px;
}

.uploaded-files:hover {
	background-color: #f2f2f2;
	border-radius: 10px;
}

.uploaded-files {
	padding: 10px;
	border-bottom: 1px solid #e8e8ed;
	border-radius: 5px;
}

.text-gray-2 {
	color: #b5b5be;
}