input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: #fff !important;
	background-color: #1f1f25;
}

.login-form-field {
	background: #131314;
	color: #fff !important;
	border-radius: 8px;
}

.MuiInputLabel-root {
	color: #fff !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	border-style: none !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.MuiOutlinedInput-input,
.MuiOutlinedInput-input:-webkit-autofill {
	color: #b5b5be !important;
}

.signin-button {
	height: 45px !important;
	border-radius: 8px !important;
}

.divider-text {
	width: 100%;
	text-align: center;
	line-height: 0.1em;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.divider-text span {
	background: #181823;
	padding: 10px 10px;
	font-size: 13px;
	z-index: 10;
}

.divider-text .line {
	height: 0px;
	border-bottom: 1px solid #6e7681;
	padding: 0 50px;
	margin-top: -1px;

	width: 95%;
	position: absolute;
	// width: 100%;
}

.font12 {
	font-size: 1rem;
}

.MuiFormLabel-root,
.MuiInputBase-input,
.MuiFormControlLabel-label {
	font-size: 1rem !important;
}

.login-with-google-btn {
	// width: 100%;
	margin-bottom: 15px;
	transition: background-color 0.3s, box-shadow 0.3s;

	padding: 12px 16px 12px 42px;
	border: 1px solid #3c3e44;
	border-radius: 3px;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

	color: #fff;
	font-size: 13px;
	font-weight: 500;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;

	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
	background-color: #1f1f2a;
	background-repeat: no-repeat;
	background-position: 15px 13px;

	&:hover {
		box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
	}

	&:active {
		background-color: #1f1f2a;
	}

	&:focus {
		outline: none;
		box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
	}

	&:disabled {
		filter: grayscale(100%);
		background-color: #ebebeb;
		box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
		cursor: not-allowed;
	}
}

@media (max-width: 899.95px) {
	.hidden-sm-grid-item {
		display: none !important;
	}
}

.otp-input .MuiInputBase-input {
	font-size: 24px !important;
}