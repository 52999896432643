@import '../../assets/scss/colors_new';

.quick-actions-component {
	display: flex;
	justify-content: center;
	align-items: center;

	.quick-actions-trigger {
		padding: 7px;
		width: 100%;
		background-color: $color-primary;
		border: none;
		color: $color-light-max;
		// border: 1px solid $color-primary;
		// color: $color-primary;
		text-align: center;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		font-size: 13px;

		--webkit-user-select: none;
		--ms-user-select: none;
		user-select: none;

		cursor: pointer;

		&.collapsed {
			width: 30px;
			height: 30px;
		}
	}

	.quick-action-popover-container {
		-webkit-backdrop-filter: blur(4px);
		backdrop-filter: blur(4px);
		background-color: rgba(107, 122, 144, 0.2);
		width: 100%;
		height: 100dvh;
		position: absolute;
		left: 0;
		top: 100%;
		z-index: 100;
		min-width: unset;

		&.collapsed {
			width: calc(100vw - 50px);
		}
	}

	.quick-action-popover {
		position: absolute;
		right: 28px;
		top: 5px;
		padding: 10px;
		z-index: 100;
		width: auto;
		display: grid;
		grid-template-columns: 50% 50%;
		gap: 5px;
		border-radius: 5px;
		animation: slideIn 0.3s ease-out;

		.quick-action-item-section {
			// min-width: 100px;
			width: max-content;
			padding: 5px;

			p {
				margin: 0;
				margin-bottom: 10px;
			}

			.link-list {
				list-style: none;
				display: flex;
				flex-direction: column;
				padding: 0;
				gap: 15px;

				a {
					text-decoration: none;
					color: $color-text-1;
					background-color: #ffffff;
					padding: 6px 10px;
					border-radius: 5px;
					display: flex;
					gap: 10px;
					align-items: center;
					font-size: 13px;
					min-width: 130px;
				}

				.link-list-item {
					display: flex;
					gap: 10px;
					align-items: center;
					border-radius: 10px;
					padding: 10px 10px;
					border: 1px solid transparent; // Add this line

					& * {
						margin: 0;
						padding: 0;
					}

					span {
						border-radius: 100px;
						width: 25px;
						height: 25px;
						// background-color: #E4EEFF;
						// color: #809FB8;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					&:hover {
						color: $color-primary;
						border: 1px solid $color-primary;
					}
				}
			}
		}
	}
}

.cstm-prtl-titl {
	font-size: 13px;
}

.refer-link {
	display: flex;
	text-align: center;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

@keyframes slideIn {
	0% {
		scale: 1;
	}

	50% {
		scale: 1.01;
	}

	100% {
		scale: 1;
	}
}

@media only screen and (max-width: 744px) {
	.quick-actions-component .quick-action-popover {
		right: 0;
		top: 0;
	}
}