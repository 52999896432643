@import './colors';
@import './colors_new';


// COLOR PALETTES
.sub-font-color {
	color: #707070;
}


a {
	color: $color-link;
	text-decoration: none;
	background-color: transparent;
}

.btn {
	border-radius: 10px;
}

#parent-wrapper {
	height: 100dvh;
	display: flex;
	flex-direction: row;
}

.form-control {
	width: 100%;
}

.bg-white {
	background-color: #fff !important;
}

.card {
	background-color: #fff !important;
	box-shadow: none;
	border: none;
}

#wrapper {
	display: flex;
	height: 100dvh;
	width: 100%;
	overflow: hidden;
	flex-direction: column;

	#mainNav {
		display: flex;

		& .navbar-light .navbar-nav .nav-item .nav-link {
			color: inherit;
		}

		#nav-page-title {
			min-width: fit-content;

			& * {
				padding: 0;
				margin: 0;
			}

			h5 {
				color: $color-text-1;
				font-weight: 600;
			}

			p {
				color: $nav-head-color-2;
			}
		}

		.nav-search {
			width: 100%;
			flex: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-inline: 15px;

			.nav-search-input {
				color: #acacac;
				font-size: 17px;
				display: flex;
				align-items: center;
				padding: 0;
				position: relative;

				input {
					padding: 5px;
					padding: 5px 30px 5px 15px;
					border: 0;
					outline: 0;
					box-shadow: none;
					border: 1px solid #acacac;
					font-size: 1rem;
					transition: all 0.3s ease-in-out;
					width: 250px;
					border-radius: 50px;

					&::placeholder {
						color: #898c9a;
						opacity: 1;
						/* Firefox */
					}

					&::-ms-input-placeholder {
						/* Edge 12-18 */
						color: #898c9a;
					}

					&:focus {
						width: 350px;
					}
				}

				.nav-search-input-icon {
					position: absolute;
					right: 10px;
					top: 50%;
					color: #b7bcc7;
					transform: translateY(-55%);
				}
			}
		}

		.nav-actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: fit-content;
			gap: 15px;
			color: $color-text-1;

			#alertsDropdown {
				color: $color-text-1 !important;
			}

			.noti-badge {
				position: absolute;
				width: 10px;
				height: 10px;
				margin-top: -1px;
				margin-left: -7px;
				background-color: $color-primary;
				min-width: 10px;
				min-height: 10px;
				max-width: 10px;
				max-height: 10px;
				font-size: 1px;
			}

			.user-icon-item {
				display: flex;
				gap: 5px;
				border: 1px solid $currency-border;
				border-radius: 50px;
				color: $color-text-1;
				padding: 5px;
				align-items: center;

				.dropdown-toggle::after {
					content: none !important;
				}

				& .nav-link {
					padding: 0 !important;
				}

				span {
					padding: 0;
					margin: 0;
				}
			}
		}

		& {
			background: $color-light-max;
			box-shadow: none;

			border-bottom: none;
			box-shadow: none;
			border-bottom: 1px solid #d9e1e7cc;
			color: #fff;
		}

		& .nav-item .nav-link .img-profile {
			height: 3rem;
			width: 3rem;
		}
	}

	#content-wrapper {
		background-color: $color-main-bg;
		width: 100%;
		overflow-x: hidden;
		height: 100%;
		// padding-top: 20px;
	}

	#content-wrapper-2 {
		background-color: $color-main-bg;
		width: 100%;
		overflow-x: hidden;

		// padding-top: 20px;
	}
}

.nav-opacity-bg {
	position: fixed;
	width: 100dvw;
	height: 100dvh;
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	background-color: rgba(107, 122, 144, 0.2);
	top: 0;
	left: 0;
	z-index: 5;
}

.order-list-client {
	border: 1px solid $color-borders-2;
	border-radius: 10px;
}

.order-list-client.active {
	background-color: #f2f2f2;
}

.order-item-client {
	border-bottom: 1px solid $color-borders-2;
}

.order-container {
	height: 94%;
	overflow-y: auto;
	overflow-x: hidden;
}

.hide-buttons {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease-out;
	animation: slideUpAndFadeOut 0.5s forwards; // Add this line
}

@keyframes slideUpAndFadeOut {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(-100%);
		opacity: 0;
	}
}

.show-buttons {
	max-height: 1000px;
	/* adjust as needed */
	transition: max-height 0.5s ease-in;
	animation: slideInFromTop 0.5s forwards;
}

@keyframes slideInFromTop {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.new-nav-bar {
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
	gap: 18px;
	// padding: 10px 20px;
	// padding: 10px 10px;
	padding: 10px 2px;
	background-color: $color-sidebar-bg;
	width: 200px;
	min-width: 200px;
	position: relative;
	height: 100dvh;
	border-right: 1px solid #f1f5f7;
	transition: all 0.2s ease-in-out;
	z-index: 6;

	.nav-bar-mid-section {
		flex: auto;
	}

	.navbar-nav-items {
		display: flex;
		flex-direction: column;
		gap: 0px;
		overflow: auto;
		flex: auto;
		font-size: 13px;
	}

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	div.nav-brand-logo {
		height: 40px;
		flex: 40px;
		flex-grow: 0;

		a.sidebar-brand {
			margin-bottom: 20px;
			margin-top: 5px;
		}
	}

	li.quick-action {}

	li.nav-break {
		// margin: 10px 10px;
	}

	li.nav-item {
		padding: 0;
		margin: 0;
		margin-bottom: 0px;
		margin-left: 5px;

		a {
			text-decoration: none;
			color: #000;
		}

		a.nav-link {
			padding: 7px 10px;
			margin: 0;
			display: flex;
			gap: 5px;
			align-items: center;
			color: $color-text-1;
			border-radius: 10px;

			.menuIcon {
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: transparent;
				border-radius: 100%;
				width: 20px;
			}
		}
		&.main-menu {
			
			display: flex;
			&:hover {
				background-color: $color-borders-2;
			}
		}
		&.sub-menu {
			
			a.nav-link {
				&:hover {
					// color: $color-primary;
					background-color: $color-borders-2;
				}
			}
		}

		&.active {
			a.nav-link {
				// font-weight: 500;
				// color: $color-dark-max;
				color: $color-primary;

				.menuIcon {
					color: $color-primary;
					background-color: transparent;
				}
			}
		}

		.collapse {
			position: absolute;
			left: 110% !important;
			z-index: 7;
			top: 50%;
			transform: translateY(-50%);
			margin-top: -50px;
			// top: 40px;

			.sidebar-brand-text {
				padding: 5px;
				padding-bottom: 0;
			}

			&.show {
				animation: slideIn 0.3s ease-out;
			}

			.collapse-inner {
				display: flex;
				flex-direction: column;
				gap: 15px;
				padding-left: 15px;

				.collapse-item {
					display: flex;
					gap: 10px;
					padding: 10px 10px;
					border-radius: 10px;
					color: $color-text-1;
					background: #fff;
					width: 150px;
					transition: 0.1s ease-in-out;

					&.active {
						font-weight: 400;
						color: $color-primary;
					}

					&:hover {
						transform: scale(1.05);
						color: $color-primary;
						border: 1px solid $color-primary;
					}
				}

				margin-left: 5px;
				padding-block: 15px;
			}

			position: absolute;
			z-index: 4;
			// background: #fff;
			left: 100%;
		}
	}
	&.expanded {
		.currency-div {
			.collapse-tag {
				margin-left: -17px !important;
			}
		}
	}
		.currency-div {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 600;
			background-color: $color-sidebar-bg;
			height: 50px;
			flex: 50px;
			flex-grow: 0;
			position: relative;
	
			.collapse-tag {
				position: absolute;
				left: calc(100% + 20px);
				top: 50%;
				transform: translateY(-50%);
				background-color: #e8e8e8;
				height: 30px;
				display: flex;
				align-items: center;
				padding: 3px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				cursor: pointer;
				z-index: 5;
			}
		}

	&.collapsed {
		width: 50px;
		min-width: 50px;
		padding: 5px;
		// animation: minimizeSideBar 0.3s;

		.nav-item .nav-link {
			span:last-child {
				display: none;
			}
		}

		.sidebar-brand-text {
			width: 30px;
		}

		.navbar-nav-items {
			padding-inline: 5px;
			align-items: center;
			gap: 0;
		}

		li.nav-item a.nav-link {
			.menuIcon {
				width: 30px;
				height: 30px;
			}
		}

		.collapse-tag {
			left: calc(100% + 5px);
		}
	}
}

.btn,
.btn-outlined-main,
.btn-outlined-danger,
.plain-textbtn,
.plain-iconbtn,
.drag-drop-btn {
	transition: 0.1s ease-in-out;

	&:active {
		scale: 0.95;
	}
}

a.nav-link.dropdown-toggle {
	padding: 0;
}

ul.pl-items-list {
	display: flex;
	flex-direction: column;
	list-style: none;
	gap: 5px;
	padding: 10px;
	margin: 0;
	min-width: 50px;

	background-color: #fff;
	color: $color-text-1;

	li {
		margin: 0;
		padding: 0;
	}
}

header.mobileHead {
	display: none;
}

.navMobile {
	display: none;
}

.navMobileMenu {
	position: fixed;
	height: calc(100dvh - 50px);
	top: 0;
	left: 0;
}

.new-nav-bar.mobile-menu {
	display: none;
}

.navMobileMenu {
	display: none;
}

.sticky-header {
	position: sticky;
	top: 0;
	background: #fff !important;
	border-bottom: none !important;
	box-shadow: #ebebeb 0px 1px;
	z-index: 2;
}

table.table.pm-table {
	thead tr {
		th {
			// border-top: $color-border-thead 1px solid;
			border-top: 0 !important;
			border-bottom: $color-border-thead 1px solid;
			padding: 15px 10px 15px 10px;
			color: $color-text-1;
			// font-size: 12px;
			font-size: 13px;
		}

		th:first-child {
			border-left: 0 !important;
			// border-left: $color-border-thead 1px solid;
		}

		th:last-child {
			border-right: 0 !important;
			// border-right: $color-border-thead 1px solid;
		}
	}

	tbody tr {
		td {
			border-bottom: $color-borders-2 1px solid;
			// padding: 7px 10px 7px 10px;
			padding: 4px 10px 4px 10px;
			vertical-align: middle;
			color: $color-text-1;
			font-size: 13px;
		}

		&:hover {
			background-color: $color-borders-2;
		}
	}
}

.bd-left {
	border-left: 2px solid #848484 !important;
}

.bd-left-success {
	border-left: 2px solid $color-success !important;
}

.bd-left-warning {
	border-left: 2px solid $color-warning !important;
}

.bd-left-danger {
	border-left: 2px solid $color-danger !important;
}

.page-item.active .page-link {
	z-index: 1;
}

.topListCom {
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 0;
	padding-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	gap: 10px;
	flex-wrap: wrap;
	// margin-bottom: 5px;
	// margin-bottom: -6px;
	margin-top: 0;
}

.btn,
.btn-primary,
.est-labels,
.order-agreement-action-edit {
	font-size: 13px !important;
}

span,
p,
td,
.order-agreements-detail p,
.order-agreements-detail h6,
input,
label,
.form-group {
	font-size: 13px;
}

.topListCom li {
	list-style: none;
	color: #fafafb;
	font-size: 13px;
	position: relative;
	padding: 3px 5px;
	margin: 0 5px;
	cursor: pointer;
	font-weight: 400;
	color: $color-text-1;

	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: center;

	span {
		line-height: 1;
		font-size: 13px;
	}

	&:hover {
		background-color: $color-borders-2;
	}

	padding: 10px;
	border-radius: 10px;
}

.dropdown .dropdown-menu {
	font-size: 1rem;
}

.topListCom li.active {
	color: $color-primary;

	&::before {
		content: '';
		width: 100%;
		height: 2.5px;
		background-color: $color-primary;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		bottom: -1px;
		border-radius: 50px;
		z-index: 2;
	}
}

.topListCom.mini li.active {
	&::before {
		content: '';
		width: 100%;
		height: 2.5px;
		background-color: $color-primary;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 50px;
		z-index: 2;
	}
}

.TableSerachBar {
	padding: 10px 10px;
}

.btn:hover {
	color: #fff !important;
}

.TableSerachBar .tableAction {
	width: 138px;
	padding: 8px 10px;
	height: 38px;
	font-size: 13px;
	border-radius: 5px;
}

.TableSerachBar .searchIcon {
	position: absolute;
	right: 5px;
	width: 30px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $color-borders-3;
	z-index: 1;
	margin-top: -37px;
}

.input-icon {
	position: relative;
}

.input-icon .icon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.TableSerachBar select.form-control.tableAction {
	background-position: calc(100% - 20px) calc(1em + 3px), calc(100% - 15px) calc(1em + 3px), calc(100% - 2.5em) 0.45em;
	background-size: 5px 5px, 5px 5px, 1px 1.7em;
}

.TableSerachBar select.form-control.tableAction:focus {
	background-position: calc(100% - 15px) calc(1em + 3px), calc(100% - 20px) calc(1em + 3px), calc(100% - 2.5em) 0.45em;
	background-size: 5px 5px, 5px 5px, 1px 1.7em;
}

.TableSerachBar .navbar-search input.form-control {
	border-radius: 50px !important;
	background-color: $color-light-max !important;
}

.navbar-search .input-group .form-control {
	height: auto;
	padding: 5px 35px 5px 20px;
	font-size: 1rem;
	border-radius: 5px;
	background: none !important;
	color: #fafafb;
	width: 100%;
}

.navbar-search .input-group {
	position: relative;
}

.navbar-search .input-group img.sIcon {
	position: absolute;
	left: 13px;
	top: 9px;
	z-index: 99;
}

.navbar-search .input-group .form-control {
	border-color: $color-borders-3 !important;
	background: none !important;
	color: $color-text-1;
}

.tableData {
	padding: 0 10px;
}

.table-card {
	padding: 10px;
	border-radius: 15px;
	margin-top: -10px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px !important;
}

.bulk-action {
	span {
		font-size: 13px;
	}
}

button {
	span {
		font-size: 13px !important;
	}
}

.bulk-actions {
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;

	.bulk-action {
		animation: bounceIn 0.3s ease-out;
		border-radius: 10px;
	}
}

.btn-main {
	font-size: 1rem;
	padding: 8px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 10px;
	background-color: $color-light-max;
	border: 1px solid $color-borders-4;
	color: $color-dark-max;
	outline: none !important;
	box-shadow: none !important;

	&:hover {
		background-color: $color-primary;
		border: 1px solid $color-primary;
		color: $color-light-max;
	}

	&.btn-main-full {
		background-color: $color-primary;
		border: 1px solid $color-primary;
		color: $color-light-max;
	}
}

.btn-main-primary {
	font-size: 1rem;
	padding: 8px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 10px;
	background-color: $color-light-max;
	border: 1px solid $color-borders-4;
	color: $color-dark-max;
	outline: none !important;
	box-shadow: none !important;
	background-color: $color-primary;
	border: 1px solid $color-primary;
	color: $color-light-max;

	&:hover {
		background-color: darken($color: $color-primary, $amount: 3);
		border: 1px solid darken($color: $color-primary, $amount: 3);
		color: $color-light-max;
	}
}

button.modal-close-icon {
	display: flex;
	justify-content: center;
	outline: none !important;
	box-shadow: none !important;
	align-items: center;
	// background-color: $color-light-max;
	// border: 1px solid $color-borders-4;
	color: $color-dark-max;

	border-radius: 100% !important;
	padding: 10px;
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 1rem;
	border-radius: 10px;

	& svg {
		fill: $color-dark-max;
	}

	&:hover {
		// background-color: $color-primary;
		// border: 1px solid $color-primary;
		// color: $color-light-max;
		background-color: #e9e9e9;
		border: 1px solid #e9e9e9;
		color: $color-dark-max;

		& svg {
			fill: $color-dark-max;
		}

		// color: #000;
		// fill: #000;
	}
}

button.chat-close-icon {
	display: flex;
	justify-content: center;
	outline: none !important;
	box-shadow: none !important;
	align-items: center;
	// background-color: $color-light-max;
	// border: 1px solid $color-borders-4;
	color: white;

	border-radius: 100% !important;
	padding: 10px;
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 1rem;
	border-radius: 10px;

	& svg {
		fill: white;
	}

	&:hover {
		// background-color: $color-primary;
		// border: 1px solid $color-primary;
		// color: $color-light-max;
		background-color: #5a5a5a;
		border: 1px solid #000000;
		color: $color-dark-max;

		& svg {
			fill: white;
		}

		// color: #000;
		// fill: #000;
	}
}

button.remove-file-icon {
	display: flex;
	justify-content: center;
	outline: none !important;
	box-shadow: none !important;
	align-items: center;
	// background-color: $color-light-max;
	// border: 1px solid $color-borders-4;
	color: #000000;

	border-radius: 100% !important;
	padding: 10px;
	position: relative;
	font-size: 1rem;
	border-radius: 10px;

	& svg {
		fill: #000000;
	}

	&:hover {
		// background-color: $color-primary;
		// border: 1px solid $color-primary;
		// color: $color-light-max;
		background-color: #f7f7f7;
		border: 1px solid #f3f3f3;
		color: #f75868;

		& svg {
			fill: #f75868;
		}

		// color: #000;
		// fill: #000;
	}
}

.btn-rounded {
	border-radius: 50px !important;
}

.btnarea {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.btnarea .btn {
	min-width: 100px;
}

.btn-outlined-main {
	min-width: 100px;
	background-color: transparent;
	border: 1px solid $color-borders-4;
	background-color: $color-light-max;
	color: #192138;
	text-align: center;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 1rem;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
	background-color: #fff;

	&.white-var {
		background-color: #fff;
		border-color: $color-borders-4;
	}

	&.white-var {
		background-color: #fff;
		// border-color: $color-borders-4;
		position: relative;

		// &:hover {
		// 	background-color: transparent;
		// 	border-color: $color-borders-4;
		// 	color: $color-primary-2;
		// }

		&.white-var-success {
			border: $color-success 1px solid;
			color: $color-success;
		}
	}

	&:hover {
		background-color: $color-primary;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&:focus,
	&:focus-visible,
	&:active {
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3) !important;
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3) !important;
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-warning, $amount: 3) !important;
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-success, $amount: 3) !important;
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}

	&:disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}
}

.btn-outlined-danger {
	min-width: 100px;
	background-color: transparent;
	border: 1px solid $color-borders-4;
	background-color: $color-light-max;
	color: #192138;
	text-align: center;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 1rem;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
	background-color: #fff;

	&.white-var {
		background-color: #fff;
		border-color: $color-borders-4;
	}

	&.white-var {
		background-color: #fff;
		// border-color: $color-borders-4;
		position: relative;

		// &:hover {
		// 	background-color: transparent;
		// 	border-color: $color-borders-4;
		// 	color: $color-primary-2;
		// }

		&.white-var-success {
			border: $color-success 1px solid;
			color: $color-success;
		}
	}

	&:hover {
		background-color: $color-danger;
		border-color: $color-danger !important;
		color: $color-light-max;
	}

	&:focus,
	&:focus-visible,
	&:active {
		background-color: $color-danger !important;
		border-color: $color-danger !important;
		color: $color-light-max;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3) !important;
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3) !important;
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-warning, $amount: 3) !important;
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-success, $amount: 3) !important;
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}

	&:disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}
}

.btn-outlined-approve {
	min-width: 100px;
	background-color: transparent;
	border: 1px solid #28a745;
	background-color: $color-light-max;
	color: #28a745;
	text-align: center;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 1rem;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
	background-color: #fff;

	&.white-var {
		background-color: #fff;
		border-color: $color-borders-4;
	}

	&.white-var {
		background-color: #fff;
		// border-color: $color-borders-4;
		position: relative;

		// &:hover {
		// 	background-color: transparent;
		// 	border-color: $color-borders-4;
		// 	color: $color-primary-2;
		// }

		&.white-var-success {
			border: $color-success 1px solid;
			color: $color-success;
		}
	}

	&:hover {
		background-color: #28a745;
		border-color: #28a745 !important;
		color: $color-light-max;
	}

	&:focus,
	&:focus-visible,
	&:active {
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3) !important;
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3) !important;
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-warning, $amount: 3) !important;
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-success, $amount: 3) !important;
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}

	&:disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}
}

.btn-outlined-reject {
	min-width: 100px;
	background-color: transparent;
	border: 1px solid #f54e60;
	background-color: $color-light-max;
	color: #f54e60;
	text-align: center;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 1rem;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
	background-color: #fff;

	&.white-var {
		background-color: #fff;
		border-color: $color-borders-4;
	}

	&.white-var {
		background-color: #fff;
		// border-color: $color-borders-4;
		position: relative;

		// &:hover {
		// 	background-color: transparent;
		// 	border-color: $color-borders-4;
		// 	color: $color-primary-2;
		// }

		&.white-var-success {
			border: $color-success 1px solid;
			color: $color-success;
		}
	}

	&:hover {
		background-color: #f54e60;
		border-color: #f54e60 !important;
		color: $color-light-max;
	}

	&:focus,
	&:focus-visible,
	&:active {
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3) !important;
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3) !important;
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-warning, $amount: 3) !important;
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-success, $amount: 3) !important;
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}

	&:disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}
}

tr.strike-through-row {
	position: relative;

	td {
		color: grey !important;
		font-style: italic !important;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: grey;
		transform: translateY(-50%);
	}
}

.modal-content {
	border-radius: 18px;
}

.custom-modal .modal-content {
	margin: 20px;
}

.modal790 {
	max-width: 790px !important;
}

.export-history-box {
	max-height: 40px;
	overflow: hidden;
	padding: 10px;
	border: 1px solid #cccccc;
	border-radius: 10px;
	margin-top: 15px;

	.export-expand-icon {
		cursor: pointer;

		svg {
			transition: all 0.3s ease-in-out;
		}
	}

	transition: all 0.3s ease-in-out;

	&.expanded {
		max-height: 250px;
		overflow: scroll;

		.export-expand-icon {
			svg {
				transform: rotate(180deg);
			}
		}
	}
}

.rdrDefinedRangesWrapper {
	width: 190px !important;
}

.est-row-error {
	color: red;
	font-size: 1rem;
	padding: 0 10px;
	text-align: center;
	width: 100%;
	font-weight: 500;
}

.PhoneInput {
	gap: 5px;
}

.PhoneInput .PhoneInputCountry {
	border: 1px solid #e8e8ed;
	padding-left: 10px;
	padding-right: 10px;
	margin-right: 3px !important;
	border-radius: 5px;
	font-size: 1rem !important;
	height: 32px !important;
}

.PhoneInput .PhoneInputInput {
	border-radius: 5px;
	outline: none;
	padding-left: 5px;
	font-size: 1rem !important;
	height: 32px !important;
}

.form-control {
	border-radius: 5px;
}

.text-default {
	color: $color-text-1;
}

.PhoneInputCountryIcon--border {
	background-color: transparent !important;
	box-shadow: none !important;
}

.order-quick-stats {
	background-color: $color-bg-2;
	padding: 20px 20px;
	border-radius: 10px;
	margin-bottom: 10px;

	h5,
	p {
		padding: 0;
		margin: 0;
	}

	animation: slideIn 0.3s;
}

.quick-stats-bar-item {
	--width-item: 0px;
	flex: var(--width-item);
	width: var(--width-item);
	min-width: var(--width-item);
	max-width: var(--width-item);
	animation: extendIn 0.3s;
	left: 0;
	transform-origin: 0% 50%;
}

.multiselect-check {
	border: 1px solid #e8e8ed;
	border-radius: 10px;
	padding: 10px;
	row-gap: 10px;
}

@keyframes extendIn {
	0% {
		transform: scaleX(0);
	}

	100% {
		transform: scaleX(1);
	}
}

.marginTop65 {
	margin-top: 65px;
}

.btn-remove-circle {
	display: flex;
	justify-content: center;
	outline: none !important;
	box-shadow: none !important;
	align-items: center;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	color: #000;
	border-radius: 100% !important;
	padding: 5px;
	font-size: 1rem;
}

.inv-cards-list {
	display: flex;
	flex-direction: column;
	font-size: 1rem;

	.order-list-card {
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding: 10px 10px;
		border-bottom: 1px solid $color-borders-2;
		transition: 0.1s ease-in-out;

		&.active-action:active {
			background-color: $color-borders-2;
			scale: 1.01;
			border-bottom: 1px solid $color-light-max;
		}

		&.selected {
			background-color: $color-borders-2;
			scale: 1.01;
			border-bottom: 2px solid $color-light-max;
		}

		&.bordered {
			border: 1px solid #e8e8ed;
			border-radius: 10px;
		}

		.order-list-card-header {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			* {
				padding: 0;
				margin: 0;
				line-height: 1;
			}

			h6 {
				font-size: 1rem;
				font-weight: 500;
			}

			p {
				font-size: 11px;
			}
		}

		.order-list-card-body {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.order-contacts {
				display: flex;
				flex-direction: column;

				* {
					padding: 0;
					margin: 0;
				}

				.order-customer {
					font-size: 1rem;
				}

				.order-transit {
					font-size: 11px;
				}
			}

			.total-val {
				padding: 0;
				margin: 0;
				font-size: 13px;
				font-weight: 500;
			}
		}

		.order-list-card-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.order-icon-items {
				display: flex;
				gap: 10px;
				align-items: center;
				font-size: 11px;

				.order-icon-item {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 5px;

					* {
						line-height: 1;
					}
				}

				.order-bordered-status {
					border: 1px solid $color-borders-1;
					font-weight: 500;
					border-radius: 3px;
					padding-inline: 5px;
				}
			}

			.order-manager {
				color: $color-borders-1;
			}

			.order-unpaid {
				color: $color-danger;
			}

			.order-partial-paid {
				color: $color-warning;
			}

			.order-paid {
				color: $color-success;
			}

			.order-status {
				background: $color-borders-1;
				color: #fff;
				padding: 2px;
				min-width: 65px;
				text-align: center;
				border-radius: 3px;
				font-size: 11px;
				padding-inline: 5px;

				&.order-status-accepted {
					background: #4d64da;
				}

				&.order-status-open {
					background: $color-warning;
				}

				&.order-status-pending {
					background: #50a3bd;
				}

				&.order-status-closed {
					background: $color-success;
				}

				&.order-status-sourced {
					background-color: #7bcae4;
					/* Light Blue */
				}

				&.order-status-under-process {
					background-color: #00ff00;
					/* Lime */
				}

				&.order-status-ready-to-ship {
					background-color: #0000ff;
					/* Blue */
				}

				&.order-status-sending-to-transit {
					background-color: #ffa500;
					/* Orange */
				}

				&.order-status-shipped {
					background-color: #ffff00;
					/* Yellow */
				}

				&.order-status-all {
					background-color: #ffffff;
					/* White */
				}

				&.order-status-inspection {
					background-color: #abb571;
					/* Light Green */
				}

				&.order-status-inspecting {
					background-color: #b9c962;
					/* Light Blue */
				}

				&.order-status-inspected {
					background-color: #9b9b43;
					/* Light Yellow */
				}

				&.order-status-need-collection {
					background-color: #800080;
					/* Dark Purple */
				}

				&.order-status-collecting {
					background-color: #8a2be2;
					/* Blue Violet */
				}

				&.order-status-collected {
					background-color: #ff69b4;
					/* Hot Pink */
				}

				&.order-status-segregating {
					background-color: #d3d3d3;
					/* Light Grey */
				}

				&.order-status-ready-for-testing {
					background-color: #0fc1c1;
					/* Cyan */
				}

				&.order-status-test-ongoing {
					background-color: #7fffd4;
					/* Aquamarine */
				}

				&.order-status-ready-for-inbounding {
					background-color: #ffd700;
					/* Gold */
				}

				&.order-status-inbounding {
					background-color: #ff8c00;
					/* Dark Orange */
				}

				&.order-status-inbounded {
					background-color: #a35aa2;
					/* Fire Brick */
				}

				&.order-status-qc-processing {
					background-color: #ff6347;
					/* Tomato */
				}

				&.order-status-ready-for-repair {
					background-color: #ffb6c1;
					/* Light Pink */
				}

				&.order-status-repairing {
					background-color: #4682b4;
					/* Steel Blue */
				}

				&.order-status-waiting-for-re-testing {
					background-color: #20b2aa;
					/* Light Sea Green */
				}

				&.order-status-retesting {
					background-color: #00ff7f;
					/* Spring Green */
				}

				&.order-status-ready-for-rework {
					background-color: #ffa07a;
					/* Light Salmon */
				}

				&.order-status-reworking-repair {
					background-color: #cd5c5c;
					/* Indian Red */
				}

				&.order-status-ready-for-qc-re-processing {
					background-color: #ffdab9;
					/* Peach Puff */
				}

				&.order-status-qc-re-processing {
					background-color: #2e8b57;
					/* Sea Green */
				}

				&.order-status-quality-control-passed {
					background-color: #a9a9a9;
					/* Dark Grey */
				}

				&.order-status-ready-for-packing {
					background-color: #9370db;
					/* Medium Purple */
				}

				&.order-status-packing {
					background-color: #20b2aa;
					/* Light Sea Green */
				}

				&.order-status-inspection-approval {
					background-color: #ffd700;
					/* Gold */
				}

				&.order-status-waiting-to-ship {
					background-color: #ff4500;
					/* Orange Red */
				}

				&.order-status-shipped-to-transit {
					background-color: #696969;
					/* Dim Grey */
				}

				&.order-status-sending-to-customer {
					background-color: #ff6347;
					/* Tomato */
				}

				&.order-status-shipped-to-customer {
					background-color: #800080;
					/* Purple */
				}

				&.order-status-cancelled {
					background-color: #ff0000;
					/* Red */
				}
			}
		}
	}
}

.rs-container,
.rs-container-est,
.rs-container-est-white {
	.rs__control {
		background-color: transparent;
		border: 1px solid #e8e8ed;
		padding: 5px 10px;
		font-size: 1rem;
		line-height: 1.5;
		min-height: unset;

		&:hover {
			// border-color: $color-primary;
			border-color: '#cecece';
			box-shadow: none;
		}
	}

	.rs__indicator-separator {
		display: none;
	}

	.rs__single-value {
		font-size: 1rem;
		line-height: 1.5;
		color: $color-text-1;
	}

	.rs__input {
		font-size: 1rem;
		line-height: 1.5;
		color: $color-text-1 !important;
	}

	.rs__control--is-focused {
		border-color: $color-primary;
		box-shadow: none;
	}

	.rs__value-container {
		font-size: 1rem;
		line-height: 1.5;
		padding: 0;
		color: $color-text-1;
		row-gap: 5px;
	}

	.rs__placeholder {
		margin: 0;
		font-size: 1rem;
		line-height: 1.5;
		color: $color-light-scale-gray-3;
	}

	.rs__input-container {
		margin: 0;
		padding: 0;
		color: $color-text-1;
		font-size: 1rem;
		line-height: 1.5;
	}

	.rs__indicator {
		padding: 0 0px 0 8px;
		box-sizing: border-box;
		font-size: 1rem;
		line-height: 1.5;
	}

	.rs__multi-value {
		margin: 0;
		margin-inline: 2px;
		font-size: 1rem;
		line-height: 1;
	}

	.rs__multi-value {
		background-color: $color-light-scale-gray-2;
		font-size: 1rem;
		line-height: 1;
		border-radius: 7px;
	}

	.rs__multi-value__label {
		color: #000;
		font-size: 11px;
		line-height: 1;
	}

	.rs__multi-value__remove:hover {
		background-color: $color-light-scale-gray-2-2;
		color: #000;
	}

	.rs__menu {
		background: #fff;

		.rs__option {
			color: #4C4C4C;
			padding: 5px 10px;

			&:hover {
				// background: $color-primary;
				background: #cecece !important;
				color: #fff;
				transition: none !important;
			}
		}

		.rs__option--is-selected {
			background-color: #fff !important;
			color: #4C4C4C !important;

			&:hover {
				// background-color: $color-primary !important;
				background: #cecece !important;
				color: #4C4C4C !important;
				transition: none !important;
			}
		}

		.rs__option--is-focused {
			// background: $color-primary;
			background: #EFF2F5;
			color: #858796 !important;
			transition: none !important;

			&:hover {
				// background: $color-primary;
				background: #EFF2F5 !important;
				transition: none !important;
				color: #858796 !important;
			}
		}
	}

	.rs__menu-list {
		padding: 0;
	}
}

.rs-container-est-white-client {
	.rs__control {
		background-color: transparent;
		border: 1px solid #e8e8ed;
		padding: 5px 10px;
		font-size: 1rem;
		line-height: 1.5;
		min-height: unset;
		border-radius: 10px;

		&:hover {
			// border-color: $color-primary;
			border-color: '#cecece';
			box-shadow: none;
		}
	}

	.rs__indicator-separator {
		display: none;
	}

	.rs__single-value {
		font-size: 1rem;
		line-height: 1.5;
		color: $color-text-1;
	}

	.rs__input {
		font-size: 1rem;
		line-height: 1.5;
		color: $color-text-1 !important;
	}

	.rs__control--is-focused {
		border-color: $color-primary;
		box-shadow: none;
	}

	.rs__value-container {
		font-size: 1rem;
		line-height: 1.5;
		padding: 0;
		color: $color-text-1;
		row-gap: 5px;
	}

	.rs__placeholder {
		margin: 0;
		font-size: 1rem;
		line-height: 1.5;
		color: $color-light-scale-gray-3;
	}

	.rs__input-container {
		margin: 0;
		padding: 0;
		color: $color-text-1;
		font-size: 1rem;
		line-height: 1.5;
	}

	.rs__indicator {
		padding: 0 0px 0 8px;
		box-sizing: border-box;
		font-size: 1rem;
		line-height: 1.5;
	}

	.rs__multi-value {
		margin: 0;
		margin-inline: 2px;
		font-size: 1rem;
		line-height: 1;
	}

	.rs__multi-value {
		background-color: $color-light-scale-gray-2;
		font-size: 1rem;
		line-height: 1;
		border-radius: 7px;
	}

	.rs__multi-value__label {
		color: #000;
		font-size: 11px;
		line-height: 1;
	}

	.rs__multi-value__remove:hover {
		background-color: $color-light-scale-gray-2-2;
		color: #000;
	}

	.rs__menu {
		background: #fff;

		.rs__option {
			color: #4C4C4C;
			padding: 5px 10px;

			&:hover {
				background: $color-primary;
				color: #4C4C4C;
			}
		}

		.rs__option--is-selected {
			background-color: #fff !important;
			color: #4C4C4C !important;

			&:hover {
				background-color: $color-primary !important;
				color: #4C4C4C !important;
			}
		}

		.rs__option--is-focused {
			background: $color-primary;
			color: #4C4C4C !important;

			&:hover {
				background: $color-primary;
				color: #4C4C4C !important;
			}
		}
	}

	.rs__menu-list {
		padding: 0;
	}
}

.rs-container-est-blue-client {
	.rs__control {
		background-color: transparent;
		border: 1px solid #0066ff;
		padding: 5px 10px;
		font-size: 1rem;
		line-height: 1.5;
		min-height: unset;
		border-radius: 10px;
		height: auto;

		&:hover {
			// border-color: $color-primary;
			border-color: '#cecece';
			box-shadow: none;
		}
	}

	.rs__indicator-separator {
		display: none;
	}

	.rs__single-value {
		font-size: 1rem;
		line-height: 1.5;
		color: $color-text-1;
	}

	.rs__input {
		font-size: 1rem;
		line-height: 1.5;
		color: $color-text-1 !important;
	}

	.rs__control--is-focused {
		border-color: $color-primary;
		box-shadow: none;
	}

	.rs__value-container {
		font-size: 1rem;
		line-height: 1.5;
		padding: 0;
		color: $color-text-1;
		row-gap: 5px;
	}

	.rs__placeholder {
		margin: 0;
		font-size: 1rem;
		line-height: 1.5;
		color: $color-light-scale-gray-3;
	}

	.rs__input-container {
		margin: 0;
		padding: 0;
		color: $color-text-1;
		font-size: 1rem;
		line-height: 1.5;
	}

	.rs__indicator {
		padding: 0 0px 0 8px;
		box-sizing: border-box;
		font-size: 1rem;
		line-height: 1.5;
	}

	.rs__multi-value {
		margin: 0;
		margin-inline: 2px;
		font-size: 1rem;
		line-height: 1;
	}

	.rs__multi-value {
		background-color: $color-light-scale-gray-2;
		font-size: 1rem;
		line-height: 1;
		border-radius: 7px;
	}

	.rs__multi-value__label {
		color: #000;
		font-size: 11px;
		line-height: 1;
	}

	.rs__multi-value__remove:hover {
		background-color: $color-light-scale-gray-2-2;
		color: #000;
	}

	.rs__menu {
		background: #fff;

		.rs__option {
			color: #858796;
			padding: 5px 10px;

			&:hover {
				background: $color-primary;
				color: #858796;
			}
		}

		.rs__option--is-selected {
			background-color: #fff !important;
			color: #858796 !important;

			&:hover {
				background-color: $color-primary !important;
				color: #858796 !important;
			}
		}

		.rs__option--is-focused {
			background: $color-primary;
			color: #858796 !important;

			&:hover {
				background: $color-primary;
				color: #858796 !important;
			}
		}
	}

	.rs__menu-list {
		padding: 0;
	}
}

.rs-container-est-blue {
	.rs__control {
		background-color: white;
		border: 1px solid #0066ff;
		padding: 5px 10px;
		font-size: 1rem;
		border-radius: 10px;
		line-height: 1.5;
		min-height: unset;

		&:hover {
			// border-color: $color-primary;
			border-color: '#cecece';
			box-shadow: none;
		}
	}

	.rs__indicator-separator {
		display: none;
	}

	.rs__single-value {
		font-size: 1rem;
		line-height: 1.5;
		color: $color-text-1;
	}

	.rs__input {
		font-size: 1rem;
		line-height: 1.5;
		color: $color-text-1 !important;
	}

	.rs__control--is-focused {
		border-color: $color-primary;
		box-shadow: none;
	}

	.rs__value-container {
		font-size: 1rem;
		line-height: 1.5;
		padding: 0;
		color: $color-text-1;
		row-gap: 5px;
	}

	.rs__placeholder {
		margin: 0;
		font-size: 1rem;
		line-height: 1.5;
		color: $color-light-scale-gray-3;
	}

	.rs__input-container {
		margin: 0;
		padding: 0;
		color: $color-text-1;
		font-size: 1rem;
		line-height: 1.5;
	}

	.rs__indicator {
		padding: 0 0px 0 8px;
		box-sizing: border-box;
		font-size: 1rem;
		line-height: 1.5;
	}

	.rs__multi-value {
		margin: 0;
		margin-inline: 2px;
		font-size: 1rem;
		line-height: 1;
	}

	.rs__multi-value {
		background-color: $color-light-scale-gray-2;
		font-size: 1rem;
		line-height: 1;
		border-radius: 7px;
	}

	.rs__multi-value__label {
		color: #000;
		font-size: 11px;
		line-height: 1;
	}

	.rs__multi-value__remove:hover {
		background-color: $color-light-scale-gray-2-2;
		color: #000;
	}

	.rs__menu {
		background: #fff;

		.rs__option {
			color: #EFF2F5;
			padding: 5px 10px;

			&:hover {
				background: $color-primary;
				color: #fff;
			}
		}

		.rs__option--is-selected {
			background: $color-primary !important;
			color: #fff !important;

			&:hover {
				background: $color-primary !important;
				color: #fff !important;
			}
		}

		.rs__option--is-focused {
			background: $color-primary;
			color: #fff !important;
		}
	}

	.rs__menu-list {
		padding: 0;
	}
}

.rs-container-est {
	width: 250px;
	// .rs__control {
	// }
}

.rs-container-est-white {
	.rs__control {
		background-color: #fff;
	}
}

.mobile-search-container {
	max-height: 0;
	overflow: hidden;
	transition: 0.3s ease-in-out;
	height: 100%;

	&.show {
		max-height: 38px;
	}
}

.sourcing-popup-list {
	display: none;
}

.table thead tr {
	background-color: transparent;
	border-bottom: 1px solid $color-borders-4;
}

ul.pagination .previous,
ul.pagination .next {
	border: 1px solid #44444f;
}

$pagination-color: transparent;

ul.pagination .paginate_button {
	border: solid $pagination-color;
	border-width: 1px 0.5px;
	border-radius: 0;
	padding: 2px 7px;
	margin: 10px 0 10px 5px;
	border: 1px solid #d6d6d6;

	a {
		color: $color-text-2;
		font-size: 13px;
		padding: 5px;
	}

	&.active {
		background-color: transparent;

		a {
			background-color: $pagination-color;
			color: $color-text-1;
			font-weight: 500;
		}
	}

	a.disabled {
		color: #e0e0e3;
	}
}

.paginationcon {
	border-top: 1px solid $color-light-scale-gray-2-2;
}

.estimate-option-card {
	// background-color: $color-light-scale-gray-2;

	.estimate-option-card-head {
		color: $color-text-1;
	}

	.estimate-option-items {
		color: $color-text-1;
	}
}

.custom-file-upload-input {
	background-color: #fff;
	color: $color-light-scale-gray-8;
	border: 1px solid #e8e8ed;
	border-radius: 10px;
	padding: 7px 10px;
}

select.form-control:focus {
	background-image: linear-gradient(45deg, gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, gray 50%),
		linear-gradient(to right, #ccc, #ccc);
	background-position: calc(100% - 15px) 50%, calc(100% - 20px) 50%, calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 60%;
	background-repeat: no-repeat;
	outline: 0;
}

input.numField {
	border: 1px solid #e8e8ed;
	color: $color-text-1;
}

.form-group .custom-select__control {
	background-color: #fff;
	color: $color-text-1;
	border: 1px solid #e8e8ed;
}

.form-group .custom-select__control .custom-select__single-value,
.form-group .custom-select__control .custom-select__input-container,
.form-group .custom-select__control .custom-select__placeholder {
	color: $color-text-1;
}

::placeholder {
	color: $color-text-1;
}

.inspection-details-mobile {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 10px;

	.inspection-detail-item {
		border: 1px solid #ebebeb;
		padding: 10px;
		font-size: 1rem;
		border-radius: 10px;

		.insp-qty {
			border: 1px solid #ebebeb;
			padding: 5px 10px;
			border-radius: 10px;
			font-size: 16px;
		}

		.inspection-detail-item-head {
			h6 {
				font-size: 1rem;
				font-weight: 500;
				margin: 0;
			}

			.cus-name {
				font-size: 11px;
				font-size: $color-text-2;
			}
		}

		.form-group {
			display: flex;
			flex-direction: column;
			gap: 3px;
			align-items: center;

			label {
				margin: 0;
			}

			input {
				width: 120px;
				font-size: 15px;
				text-align: center;
			}
		}
	}
}

.hide-on-non-mobile {
	display: none;
}

.mobile-prod-comment-ui {
	color: $color-text-2;
	font-size: 10px;
}

.form-control:disabled,
.form-control[readonly] {
	// background-color: transparent !important;

	background: #eaeaea !important;
	opacity: 1;
}

.btn-outlined-main.text-primary:hover {
	background: #fff;
	color: #4e73df !important;
	border: 1px solid #d9d9d9 !important;
}

.btn-outlined-approve.text-primary:hover {
	background: #fff;
	color: #28a745 !important;
	border: 1px solid #d9d9d9 !important;
}

.btn-outlined-reject.text-primary:hover {
	background: #fff;
	color: #f54e60 !important;
	border: 1px solid #d9d9d9 !important;
}

@keyframes foundRowAnim {
	0% {
		background-color: $color-borders-2;
	}

	20% {
		background-color: transparent;
	}

	40% {
		background-color: $color-borders-2;
	}

	60% {
		background-color: transparent;
	}

	80% {
		background-color: $color-borders-2;
	}

	100% {
		background-color: transparent;
	}
}

.profile-details-dropdown {
	position: absolute;
	top: calc(100% + 15px);
	z-index: 20;
	right: 0;
	background: #fff;
	padding: 15px;
	// min-width: 200px;
	// width: 100%;
	// max-width: 200px;
	border-radius: 10px;
	width: 220px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

	&.profile-details-dropdown-mobile {
		top: unset;
		bottom: calc(100% + 5px);
	}

	.profile-details-dropdown-header {
		display: flex;
		gap: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ebebeb;

		.profile-details-dropdown-header-name {

			h6,
			p {
				padding: 0;
				margin: 0;
			}

			h6 {
				font-size: 13px;
				font-weight: 500;
			}

			p {
				font-size: 11px;
			}
		}
	}

	.profile-details-dropdown-menu-items {
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding: 10px 0;
		border-bottom: 1px solid #ebebeb;

		.profile-details-dropdown-menu-item {
			display: flex;
			align-items: center;
			gap: 7px;
			border-radius: 5px;
			padding: 5px;

			&:not(.no-hover):hover {
				background: #e8e8ed;
			}

			cursor: pointer;

			.pddmi-icon {
				flex: 13px 1;
				flex-grow: 0;
				font-size: 13px;
			}

			.pddmi-text {
				flex: auto;
			}

			.pddmi-action {
				flex: 30px;
				flex-grow: 0;
			}
		}
	}

	.profile-details-dropdown-footer {
		.profile-details-dropdown-menu-items {
			border-bottom: none;
			padding: 0;
			padding-top: 5px;
		}
	}
}

tr.selected-row {
	background-color: $color-borders-2;
}

.card-body-hoverable {
	cursor: pointer;

	:hover {
		.card-body-hoverable-text {
			color: #0164ff;
		}
	}
}

@media only screen and (max-width: 744px) {
	.hide-on-non-mobile {
		display: initial;
	}

	.inspection-details-mobile {
		display: flex;
	}

	.est-detail-logo {
		height: 25px !important;

		img {
			height: 25px !important;
		}
	}

	.sourcing-popup-list {
		display: flex;
		flex-direction: column;
		gap: 5px;

		.sourcing-popup-item {
			border: 1px solid #ebebeb;
			border-radius: 5px;
			padding: 10px;

			.sourcing-popup-item-header {
				display: flex;
				justify-content: space-between;

				h6 {
					font-size: 1rem;
					font-weight: 500;
				}

				p {
					font-size: 1rem;
					font-weight: 500;
				}
			}
		}
	}

	.hide-on-mobile {
		display: none;
	}

	.TaskDetails .topBtnSign {
		left: 26px !important;
	}

	.form-group-inline {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		label {
			width: 100%;
		}

		.PhoneInput {
			width: 100%;
		}
	}

	.medium-sized-container {
		min-width: unset;
		width: 100%;

		table tr {
			border: none !important;
		}
	}

	.create-contact-container {
		.card {
			padding: 20px;
			margin-top: 20px;
			min-width: unset !important;
			max-width: 768px;
			color: #000;
			font-size: 1rem;
		}

		.form-group {
			padding: 0 !important;

			.form-group-body {
				display: flex !important;
				flex-direction: column !important;
				gap: 3px;
				align-items: flex-start !important;
				justify-content: flex-start;

				label {
					text-wrap: nowrap;
				}

				&>div {
					width: 100% !important;

					.rs-container,
					.vatCheck,
					.PhoneInput {
						width: 100% !important;
					}

					input {
						width: 100% !important;
					}
				}
			}

			.form-group-error {
				padding: 0 !important;
			}
		}
	}

	.sourced-table {
		thead {
			display: none;
		}

		tbody {
			tr {
				display: flex;
				flex-wrap: wrap;

				td {
					border: none;
					border-bottom: none !important;
				}
			}
		}
	}

	.custom-modal-two .modal-dialog {
		max-width: 100% !important;
	}

	.tableData {
		padding: 0;
	}

	.inv-mobile-prods-table {
		tr.bordered-bottom {
			border-bottom: 1px solid #ebebeb;
			z-index: 2;
		}

		tr.bordered-bottom:has(+ tr.expanded-row) {
			border-bottom: none;
		}

		tr.expanded-row {
			border-bottom: 1px solid #b6b6b6;
			border-top: 1px solid #b6b6b6;
			z-index: 2;

			// & + tr {
			// }
		}

		tr:hover {
			background-color: transparent !important;
		}
	}

	.rdrDefinedRangesWrapper {
		display: none;
	}

	.table-card {
		padding: 0;
	}

	// .topListCom li.active::before {
	// 	content: "";
	// 	width: 100%;
	// 	height: 2.5px;
	// 	background-color: #0066FF;
	// 	position: absolute;
	// 	margin: auto;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: -5px;
	// 	border-radius: 50px;
	// 	z-index: 2;
	// }

	.section-head {
		flex-direction: row;
		gap: 10px;
		padding: 0;

		.topListCom {
			gap: 8px;
			width: 100%;
			margin-top: 10px;
			margin-bottom: 5px;
			align-items: center;
			overflow-y: hidden;
			overflow-x: scroll;
			flex-wrap: nowrap;
			height: 32px;

			-ms-overflow-style: none;
			/* Internet Explorer 10+ */
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
				/* Safari and Chrome */
			}

			li {
				margin: 0;
				white-space: nowrap;
				border-radius: 50px;

				&.active::before {
					display: none;
				}

				padding: 10px 12px;
				color: #000000;

				background-color: #e7e8eb;

				&.active {
					background-color: $color-primary;
					color: #fff;
				}
			}

			.topListCounter {
				display: none;
			}

			.topListIcon {
				display: none;
			}
		}

		.actions {
			width: 100%;
			// justify-content: center;
			// flex-direction: row-reverse;
			margin-bottom: 5px;

			button {
				margin: 0;
				min-width: unset;
				width: auto;
			}
		}
	}

	.TableSerachBar {
		.bulk-actions {
			display: none;

			&.show-mobile {
				display: flex;

				.disabled {
					display: none;
				}
			}

			position: fixed;
			bottom: 50px;
			left: 0;
			background: $color-light-max;
			padding: 10px;
			width: 100%;
			z-index: 5;
		}
	}

	.marginTop65 {
		margin-top: 0;
	}

	.est-nv-container,
	.settings-container {
		padding-inline: 0;
	}

	.section-head {
		.actions {
			gap: 5px;
		}
	}

	.new-nav-bar:not(.mobile-menu) {
		display: none;
	}

	.navbar {
		display: none !important;
	}

	.new-nav-bar.mobile-menu {
		display: flex;
		height: 100%;
		width: 0px;
		min-width: 0;
		padding: 10px 0px;
	}

	.new-nav-bar.mobile-menu.show-mobile {
		width: 200px;
		min-width: 200px;
		padding: 10px 20px;
	}

	.navMobileMenu {
		-webkit-backdrop-filter: blur(4px);
		backdrop-filter: blur(4px);
		background-color: rgba(107, 122, 144, 0.2);
		display: block;
		z-index: 5;
		width: 0;
		overflow: hidden;
		transition: 0.1s;

		&.show-mobile {
			width: 100vw;
		}
	}

	header.mobileHead {
		display: flex;
		justify-content: space-between;
		padding: 10px 20px;
		background-color: $color-light-max;

		position: relative;

		.logo-icon {
			width: 40px;
		}

		.search-mobile {
			input {
				padding: 0;
				margin: 0;
				border: 0;
				outline: 0;
				box-shadow: none;
				width: 0;
			}

			height: 25px;
			width: 25px;
			min-width: 25px;
			min-height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			color: $nav-head-color-2;
			background-color: $color-light-max;
			border: 1px solid $currency-border;
			border-radius: 100%;

			.search-mobile-icon {
				margin-top: -4px;
			}

			transition: all 0s ease-in-out;

			&.expanded {
				padding: 10px;
				position: absolute;
				border-radius: 50px;
				width: calc(100% - 20px);
				height: 25px;

				input {
					width: 100%;
					border-radius: 50px;
					font-size: 1rem;
					padding: 0 20px 0 10px;
				}

				right: 50%;
				transform: translateX(50%);
				transition: width 0.3s ease-in-out;
			}
		}
	}

	#content {
		// margin-bottom: 50px;
	}

	.navMobile {
		display: flex;
		position: fixed;
		bottom: 0;
		left: 0;
		justify-content: space-between;
		width: 100%;
		background-color: #fff;
		height: 50px;
		z-index: 50;
		padding-inline: 20px;
		box-shadow: 0px 2px 48px #00000014;

		.action-item {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 2;

			&.quick-action {
				flex: 3;

				.icon-circle {
					border-radius: 100%;
					background-color: $color-primary;
					color: #fff;
					font-size: 18px;
					width: 50px;
					height: 50px;
					// margin-top: -50%;
					transform: translateY(-50%);
					z-index: 2;
				}
			}
		}

		.icon-noti {
			.noti-badge {
				position: absolute;
				width: 10px;
				height: 10px;
				margin-top: -1px;
				margin-left: -7px;
				background-color: $color-primary;
			}
		}

		.mobile-noti-view {
			-webkit-backdrop-filter: blur(4px);
			backdrop-filter: blur(4px);
			background-color: rgba(107, 122, 144, 0.2);
			position: fixed;
			bottom: 50px;
			height: 100%;
			width: 100%;
			left: 0;

			.notification-dropdown {
				bottom: 0;
				top: auto;
				padding-bottom: 30px;
				border-radius: 20px;
				left: 50%;
				right: auto;
				transform: translateX(-50%);
			}
		}

		.mobile-quick-action-popover-container {
			-webkit-backdrop-filter: blur(4px);
			backdrop-filter: blur(4px);
			background-color: rgba(107, 122, 144, 0.2);
			position: fixed;
			bottom: 50px;
			width: 100%;
			height: 0;
			overflow: hidden;

			transition: all 0.1s ease-in-out;

			&.show {
				height: calc(100dvh - 50px);
			}

			.quick-action-popover {
				position: absolute;
				bottom: 0;
				width: 100%;
				display: flex;
				gap: 10px;
				left: 50%;
				transform: translateX(-50%);

				max-width: 350px;

				background-color: $color-light-max;
				border-radius: 20px;
				padding: 10px 10px 30px 10px;

				.quick-action-item-section {
					flex: 1;

					.link-list {
						display: flex;
						flex-direction: column;
						gap: 10px;

						.link-list-item {
							text-decoration: none;
							color: $quick-access-text-color;
							background-color: #ffffff;
							display: flex;
							gap: 10px;
							align-items: center;
							font-size: 1rem;
							border-radius: 10px;
							padding: 10px 10px;
							box-shadow: 0px 10px 30px #d1d5df80;

							& * {
								margin: 0;
								padding: 0;
								font-size: 13px;
							}

							span {
								border-radius: 100px;
								width: 25px;
								height: 25px;
								background-color: #e4eeff;
								color: #809fb8;
								display: flex;
								justify-content: center;
								align-items: center;
							}

							transition: 0.3s ease-in-out;
							// min-width: 150px;
						}
					}
				}
			}
		}
	}
}

@keyframes slideIn {
	0% {
		scale: 1;
	}

	50% {
		scale: 1.01;
	}

	100% {
		scale: 1;
	}
}

.text-pink {
	color: #d67777bf;
}

.form-inp {
	width: 100%;
}

.rs__single-value,
.rs__placeholder,
.rs__option {
	text-align: left;
}

.modal-card-padding {
	padding: 20px 0px;
}

@media screen and (min-height: 670px) {
	.nav-item-settings {
		position: absolute;
		bottom: 65px;
	}

	.modal-card-padding {
		padding: 20px 30px !important;
	}
}

.order-form-section-effect {
	// border-left: 2px solid #0066FF;
}

.returns-section-header {
	font-weight: 500;
	font-size: 18px;
}

.returns-section-header.gray {
	color: #c7c7c7;
}

html {
	font-size: 13px;
}

// Large Screens
.btn-with-disabled[disabled],
.btn-with-disabled[disabled]:hover {
	margin: 10px;
	background: #cecece !important;
	color: #fff !important;
	cursor: not-allowed;
	border: 0;
}

@media screen and (min-width: 1600px) {
	html {
		font-size: 13px;
	}
}

.rs__multi-value__label {
	font-size: 13px !important;
}

.modal-card {
	box-shadow: none !important;
	border: 1px solid #eaeaea;
}

.font500 {
	font-weight: 500;
}

.cardbody-chevron-container {
	justify-content: right;
	align-items: center;
	display: flex;
}

.card-body.disabled {
	background: #eaeaea;
}

.card-body.disabled:hover {
	cursor: not-allowed;
}

.btn-main-success {
	background: #19b67c;
	border: 1px solid #19b67c;
	color: #fff;
}

.btn-main-success:hover {
	background: #17a772;
	border: 1px solid #17a772;
	color: #fff;
}

.new-nav-bar.expanded {
	.navbar-nav-items {
		li {
			.sidebar-collapsable-menu {
				margin-left: 19px;
				// margin-left: 11px;
				border-left: 2px solid #dfdfe0;
				max-height: 135px;
				overflow: hidden;
				transition: max-height 1s ease-out;
			}
		}
	}
}

.text-active {
	color: #0065ff;
}

.text-inactive {
	color: #1a2138;
}

.sidebar-collapsable-menu.show {
	max-height: 500px;
	transition: max-height 1s ease-in-out;
	/* Increased duration to 1s */
	/* Adjust this value based on your needs */
}

.input-placeholder-new {
	border: none;
	border-bottom: 1px solid #fcfcfc;
	border-radius: 0;
	box-shadow: none;
}

.input-placeholder-new::placeholder {
	color: #bbbbbb;
}

.note-enter {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
}

.note-enter-active {
	opacity: 1;
	max-height: 100px;
	/* adjust this value based on your needs */
	transition: opacity 200ms, max-height 200ms;
}

.note-exit {
	opacity: 1;
	max-height: 100px;
	/* adjust this value based on your needs */
}

.note-exit-active {
	opacity: 0;
	max-height: 0;
	transition: opacity 200ms, max-height 200ms;
}

.note-row:hover {
	box-shadow: 0 0 5px #e1d9d9 !important;
	color: black;
	border-radius: 10px;
	margin-left: 5px;
	margin-right: 5px;
}

.note-row {
	margin-left: 5px;
	margin-right: 5px;
	border-radius: 10px;
	transition: all 0.3s ease; // Add this line
}

@keyframes slideDown {
	0% {
		max-height: 0;
		opacity: 0;
	}

	100% {
		max-height: 500px; // adjust this value based on your needs
		opacity: 1;
	}
}

@keyframes slideUp {
	0% {
		max-height: 500px; // adjust this value based on your needs
		opacity: 1;
	}

	100% {
		max-height: 0;
		opacity: 0;
	}
}

.notes-body.slide-down {
	animation: slideDown 1s forwards;
}

.notes-body.slide-up {
	animation: slideUp 0.2s forwards;
}

.spinner {
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.new-nav-bar-client .nav-link {
	transition: color 0.2s ease-in-out;
}

.new-nav-bar-client .nav-link:hover {
	background-color: #f2f2f2;
}

.updateLogo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100px;
	border: 2px dashed #888;
	border-radius: 5px;
	color: #888;
	cursor: pointer;
	transition: border 0.3s ease-in-out;
}

.updateLogo:hover {
	border-color: #09f;
}

.form-control,
.rs__control {
	border-radius: 10px;
	padding: 7px 10px !important;
	// height: calc(1.5em + 0.75rem + 5px);
}

.rs-container {
	width: 100%;
}

.font600 {
	font-weight: 600;
}

.attachments-file-client {
	background-color: #eaeaea;
	padding: 7px 10px;
	border-radius: 10px;
	transition: background-color 0.3s ease, color 0.3s ease;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}

.attachments-file-client:hover {
	background-color: #0065ff;
	color: white;
}

.dropdown-menu.show {
	inset: 0px -69px auto auto !important;
}

.est-nv-container {
	padding-inline: 50px;
}
 
.settings-container {
	padding-inline: 0px;
}
.settings-icon-set {
	display: none;
}
@media (max-width: 575px) {
	.est-nv-container, 
	.settings-container {
		padding-inline: 0 !important;
	}
}
@media (max-width: 519px) {
	.settings-icon-bg-container {
		padding: 5px 8px !important;
	}
	.color-theme {
		color: #000;
	}
}
@media (min-width: 520px) {
	.settings-icon-set {
		display: block !important;
	}
	.card-body-settings-container {
		min-height: 94px !important;
	}
}

@media (max-width: 767px) and (min-width: 576px) {}

@media (max-width: 991px) and (min-width: 768px) {
	.product-warehouses {
		float: right;
	}
}

@media (max-width: 1199px) and (min-width: 992px) {
	// .inv-details {
	// 	display: none;
	// }

	// .inv-preview {
	// 	width: 100% !important;
	// }
	.product-warehouses {
		float: right;
	}
}

@media (max-width: 1399px) and (min-width: 1200px) {}

@media (max-width: 955px) {
	.inv-details {
		display: none;
	}

	.est-nv-container,
	.settings-container {
		// width: 1154px !important;
		width: 100% !important;
		flex: 100%;
	}
}

@media (min-width: 768px) and (max-width: 955px) {
	.inv-details {
		display: none;
	}

	.est-nv-container,
	.settings-container {
		// width: 1154px !important;
		width: 100% !important;
		flex: 100% !important;

		.inv-preview {
			flex: 100% !important;
			max-width: 100% !important;
		}
	}
}

.active-svg path {
	fill: black;
}

.inactive-svg path {
	fill: #b7b7b7;
}

.card-awb:hover {
	background-color: #0065ff !important;
	color: white;
	transition: background-color 0.3s ease;
}

.card-awb {
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.estimates-payment-shipment:hover {
	background-color: #e5e5e5 !important;
	transition: background-color 0.3s ease;
	border-radius: 10px;
	padding: 5px 10px;
}

.estimates-payment-shipment {
	padding: 5px 10px;
}

.estimates-payment-shipment.active {
	color: #0065ff;
}

.product-view-change-type {
	width: 100%;

	.dropdown-menu {
		transform: translate3d(32px, 35px, 0px) !important;
	}
}

.product-stock-summary {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 19px;
	margin-top: 8px;
}

.btn-outlined-main-primary {
	min-width: 100px;
	background-color: transparent;
	// border: 1px solid $color-borders-4;
	// background-color: $color-light-max;
	color: #fff;
	text-align: center;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 1rem;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
	background-color: #06f;

	&.white-var {
		background-color: #06f;
		// border-color: $color-borders-4;
	}

	&.white-var {
		background-color: #06f;
		// border-color: $color-borders-4;
		position: relative;

		// &:hover {
		// 	background-color: transparent;
		// 	border-color: $color-borders-4;
		// 	color: $color-primary-2;
		// }

		&.white-var-success {
			border: $color-success 1px solid;
			color: $color-success;
		}
	}

	&:hover {
		background-color: #0060f0;
		// border: 1px solid #0060f0;
		color: #fff;
	}

	&:focus,
	&:focus-visible,
	&:active {
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		color: $color-light-max;
	}

	&.plain-start {
		background-color: $color-light-scale-gray-3;
		color: lighten($color: $color-light-scale-gray-3, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-3;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-3, $amount: 3) !important;
		}
	}

	&.plain-continue {
		background-color: $color-light-scale-gray-4;
		color: lighten($color: $color-light-scale-gray-4, $amount: 50) !important;
		border: 1px solid $color-light-scale-gray-4;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-light-scale-gray-4, $amount: 3) !important;
		}
	}

	&.plain-stop {
		background-color: $color-warning;
		color: lighten($color: $color-warning, $amount: 50) !important;
		border: 1px solid $color-warning;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-warning, $amount: 3) !important;
		}
	}

	&.plain-finish {
		background-color: $color-success;
		color: lighten($color: $color-success, $amount: 50) !important;
		border: 1px solid $color-success;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			background-color: darken($color: $color-success, $amount: 3) !important;
		}
	}

	&.disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}

	&:disabled {
		background-color: $color-light-scale-gray-2 !important;
		color: $color-light-scale-gray-3 !important;
		border: 1px solid $color-light-scale-gray-3 !important;
		cursor: not-allowed;
	}
}

.product-price-margin-label {
	margin-left: 10px;
	min-width: 85px;
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
}

.est-btn {
	.dropdown-menu {
		transform: translate3d(-145px, 46.5px, 0px) !important;
	}
}

.currency-component.collapsed {
	background: #e8e8e8;
}

// .new-navbar
.new-nav-bar.collapsed {
	z-index: 25 !important;
	background: #e8e8e8;

	.navbar-nav-items {
		.nav-item.main-menu {
			min-height: 45px;
		}

		.nav-item.main-menu {
			&:hover {
				.nav-link {
					background: #efefef;
					// background: firebrick;
					position: flex;
					position: absolute;
					// padding: 5px 8px 5px 10px;
					border-radius: 0;
					// margin-left: -30px;
					margin-left: -33px;

					.menuIcon {
						margin-left: 8px;
						margin-right: -2px;
					}

					.menuLabel {
						display: flex !important;
						position: absolute !important;
						/* background: #fff; */
						padding-left: 52px !important;
						margin-right: -68px !important;

						div {
							background: #fff;
							padding: 10px;
							border-radius: 12px;
							color: #000;
							// box-shadow: 0px 0px 7px 2px #cecece;
							border: 1px solid #cecece;
							min-width: 92px;
							z-index: 5;
						}
					}
				}
			}

			.menu-quick-create {
				display: none;
				// position: absolute;
				// right: 20px;
				// cursor: pointer;
			}
		}

		.nav-item.main-menu.active {
			.nav-link {
				background: #efefef;
				// background: firebrick;
				position: flex;
				position: absolute;
				// padding: 5px 8px 5px 10px;
				border-radius: 0;
				// margin-left: -30px;
				margin-left: -33px;

				.menuIcon {
					margin-left: 8px;
					margin-right: -2px;
				}
			}
		}

		.nav-item.sub-menu {
			min-height: 45px;
			// margin-left: 47px;


			.menu-quick-create {
				display: none;
				// position: absolute;
				// right: 20px;
				// cursor: pointer;
			}
			
			.nav-link {
				position: flex;
				margin-left: -25px;
				position: absolute;
				padding: 5px 8px 5px 10px;
				border-radius: 0;

				&:hover {
					background: #efefef;

					.menuLabel {
						display: flex !important;
						position: absolute !important;
						/* background: #fff; */
						padding-left: 50px !important;
						// margin-right: -72px !important;

						div {
							background: #fff;
							padding: 10px;
							border-radius: 12px;
							color: #000;
							// box-shadow: 0px 0px 7px 2px #cecece;
							border: 1px solid #cecece;
							min-width: 100px;
							z-index: 5;
						}
					}
				}
			}

			.sidebar-collapsable-menu {
				margin-top: 45px;

				.nav-item {
					min-height: 45px;

					&:hover {

						// .nav-item {
						a {
							display: flex !important;
							position: absolute !important;
							/* background: #fff; */
							// padding-left: 50px !important;
							margin-right: -72px !important;

							.submenuLabel {
								display: flex !important;
								position: absolute !important;
								/* background: #fff; */
								padding-left: 45px !important;
								margin-right: -74px !important;

								div {
									background: #fff;
									padding: 10px;
									border-radius: 12px;
									color: #000;
									// box-shadow: 0px 0px 7px 2px #cecece;
									border: 1px solid #cecece;
									min-width: 120px;
								}
							}
						}
					}
				}
			}

			.sidebar-collapsable-menu.show {
				margin-left: -5px;
			}
		}
	}
}

.new-nav-bar.expanded {
	.navbar-nav-items {
		.nav-item-settings {
			min-width: 155px;
		}
	}
}

@keyframes diagonal-expand {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

.chat-supp-container {
	position: absolute;
	bottom: 13px;
	right: 13px;
	z-index: 15;
	pointer-events: none;

	@media screen and (max-width: 768px) {
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		overflow-y: hidden;
		overflow-x: hidden;
	}
}

.chat-container {
	height: 550px;
	width: 350px;
	position: relative;
	background-color: rgb(221, 221, 221);
	border-radius: 16px;
	margin-bottom: 60px;
	transition: transform 0.3s, opacity 0.3s;
	transform-origin: bottom right;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

	@media screen and (max-width: 768px) {
		border-radius: 0px;
		height: 100%;
		width: 100%;
		margin-bottom: 0px;
		z-index: 3;
	}
}

.chat-container.closed {
	transform: scale(0);
	opacity: 0;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.chat-body-menu {
	height: 480px;
	width: 350px;
	overflow-y: auto;
	padding: 10px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	background: linear-gradient(to bottom, black 40%, rgb(237, 237, 237));
	animation: fadeIn 0.5s ease-out forwards;

	@media screen and (max-width: 768px) {
		border-radius: 0px;
		width: 100%;
		height: 100%;
	}
}

.chat-body-message {
	height: 480px;
	width: 350px;
	overflow-y: auto;

	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	background-color: #000000;
	transition: opacity 0.3s ease-in-out;

	@media screen and (max-width: 768px) {
		border-radius: 0px;
		width: 100%;
		height: 100%;
	}
}

@keyframes slideUp {
	from {
		transform: translateY(100%);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.chat-popup-body {
	animation: slideUp 0.3s ease-out forwards;
	background-color: #f0f0f0;
}

.help-body {
	animation: slideUp 0.3s ease-out forwards;
	background-color: #f0f0f0;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.message-banner {
	animation: fadeIn 0.3s ease-out forwards;
}

.chat-body-help {
	height: 480px;
	width: 350px;
	overflow-y: auto;

	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	background-color: #000000;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;

	@media screen and (max-width: 768px) {
		border-radius: 0px;
		width: 100%;
		height: 100%;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.chat-body-news {
	height: 480px;
	width: 350px;
	overflow-y: auto;
	padding: 10px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	background: linear-gradient(to bottom, black 40%, rgb(237, 237, 237));
	animation: fadeIn 0.5s ease-out forwards;

	@media screen and (max-width: 768px) {
		border-radius: 0px;
		width: 100%;
		height: 100%;
	}
}

.message-banner {
	height: 70px;
	width: 350px;

	background-color: #000000;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 20px;

	@media screen and (max-width: 768px) {
		width: 100%;
		position: sticky;
		top: 0;
		left: 0;
		z-index: 10;
		border-radius: 0px;
	}
}

.active {
	opacity: 1;
}

.chat-popup-reply {
	padding: 5px;
	background-color: white;
	border-radius: 10px;
	margin-left: 5px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.chat-popup-reply-name {
	font-weight: 600;
	margin-left: 7px;
}

.chat-timestamp {
	margin-left: 5px;
	font-size: 12px;
	color: #848484;
}

.chat-footer {
	height: 70px;
	width: 350px;
	bottom: 0;
	position: absolute;
	background-color: rgb(255, 255, 255);
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;

	@media screen and (max-width: 768px) {
		border-radius: 0px;
		width: 100%;
	}
}

.chat-popup-body,
.help-body {
	position: relative; // Add this line
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 410px;

	@media screen and (max-width: 768px) {
		height: calc(100% - 140px); // Adjust 60px to your footer's height
	}
}

.send-icon:hover {
	color: #0066ff;
}

.chat-container,
.chat-toggle {
	/* ...existing styles... */
	pointer-events: auto;
}

.chat-toggle {
	height: 50px;
	width: 50px;
	background-color: #0066ff;
	border-radius: 50%;
	cursor: pointer;
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.3s;

	@media screen and (max-width: 768px) {
		bottom: 65px;
		right: 8px;
	}
}

.chat-toggle span {
	position: absolute;
	/* add this */
}

.chat-toggle span:nth-child(1) {
	transition: transform 0.5s, opacity 0.5s;
}

.chat-toggle span:nth-child(2) {
	transition: transform 0.5s, opacity 0.5s;
	opacity: 0;
	transform: rotate(-45deg);
}

.chat-toggle.open span:nth-child(1) {
	transform: rotate(45deg);
	opacity: 0;
}

.chat-toggle.open span:nth-child(2) {
	transform: rotate(0);
	opacity: 1;
}

.chat-menu:hover {
	color: #0066ff;
	transition: color 0.3s;
	cursor: pointer;
}

.chat-menu.active {
	color: #0066ff;
}

.chat-header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-top: 5px;
}

.chat-input-field {
	bottom: 0;
	position: absolute;
	width: 100%;
}

.card-help {
	height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #c6c6c6;
	border-radius: 16px;
}

.svg-background {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: #0066ff;
	/* replace with your desired color */
	border-radius: 50%;
	/* makes the div circular */
	padding: 15px;
	/* adjust as needed */
	color: white;
}

.filter-container {
	z-index: 15 !important;
}

.rs__control {
	border-radius: 12px !important;
}

.payment-delete-icon {
	position: absolute;
	top: 0;
	margin-top: -10px;
	right: 0;
	margin-right: -9px;
	background: #efefef;
	// border: 1px solid #bdbdbd;
	padding: 1px 5px 3px;
	border-radius: 12px;
	font-size: 10px;

	&:hover {
		background: #e74c3c;
		border: border 1px solid #e74c3c !important;

		svg {
			fill: #fff;
		}
	}
}

.task-title-input-container {
	position: relative;
	display: flex;
	justify-content: start;
	// align-items: center;
	width: 100%;
	// align-self: stretch;
	align-items: center;

	.placeholder {
		position: absolute;
		left: 1px;
		color: #696969;
		height: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

	.task-title-input {
		border: none;
		width: 100%;
		resize: none;
		height: auto;
		field-sizing: content;
		background: transparent;
		outline: none;
		position: relative;
		z-index: 2;
		width: 100%;

		&.description-input {
			font-size: 0.9em;
			color: #707070;
		}
	}

	.description-input-placeholder {
		font-size: 0.9em;
	}
}

.tasks-panel {
	max-width: 300px;
	width: 300px;
	padding: 10px 0px;
	display: flex;
	flex-direction: column;
	height: 100%;

	.tasks-panel-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e3e6f0;
		flex-grow: 0;
		padding: 0px 15px;
		padding-bottom: 10px;
	}

	.task-panel-body {
		flex: 1;
		flex-grow: 1;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	.task-panel-footer {
		border-top: 1px solid #e3e6f0;
		padding-top: 10px;
		padding-bottom: 5px;
		flex-grow: 0;
	}

	.task-item {
		& > * {
			padding: 0;
			margin: 0;
			font-size: 14px;
		}
		&.add-task {
			color: #0066ff;
		}
		padding: 5px 15px;

		font-size: 14px;
		display: flex;
		gap: 15px;
		align-items: center;

		.task-action {
			display: flex;
			gap: 10px;
			align-items: center;
			opacity: 0;
		}

		&:hover .task-action {
			opacity: 1;
		}

		&:hover {
			background: #f5f5f5;
		}

		.check-section {
			.check-item {
				font-size: 1px;
				width: 20px;
				height: 20px;
				border: 2px solid #707070;
				border-radius: 9999px;
				cursor: pointer;
			}
			.check-check-item {
				width: 20px;
				height: 20px;
				border: 2px solid #eff5ff;
				background: #eff5ff;
				color: #0066ff;
				border-radius: 9999px;
				cursor: pointer;
				display: none;
				justify-items: center;
				align-items: center;
				scale: 1.2;
			}
			&:hover .check-item {
				display: none;
			}
			&:hover .check-check-item {
				display: flex;
			}
		}

		&.task-item-complete {
			.check-section .check-check-item {
				display: flex;
			}

			.content {
				opacity: 0.6;
				text-decoration: line-through;
			}
		}
	}
}

.color-secondary {
	color: #707070;
}
.payment-delete-icon-2 {
	top: 0;
	margin-top: -10px;
	right: 0;
	margin-right: -9px;
	background: #efefef;
	// border: 1px solid #bdbdbd;
	padding: 2px 5px 3px;
	border-radius: 12px;
	font-size: 13px;

	&:hover {
		background: #E74C3C;
		border: border 1px solid #E74C3C !important;

		svg {
			fill: #fff;
		}
	}
}

.purchase-order-add-product-div {
	border: 1px solid #d0d0d0;
	background: #e8e8e8;
	border-radius: 12px;
	padding: 15px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}




@media (max-width: 1500) and (min-width: 1400px) {
	.est-nv-container,
	.settings-container {
		padding-inline: 50px;
		margin-left: 50px !important;
	}
}

@media (min-width: 1501px) and (max-width: 1550px) {
	.est-nv-container,
	.settings-container {
		width: 93% !important;
		margin-left: 50px !important;
	}
}

@media (min-width: 1551px) and (max-width: 1600px) {
	.est-nv-container, 
	.settings-container {
		width: 90% !important;
		margin-left: 70px !important;
	}
}

@media (min-width: 1601px) and (max-width: 1650px) {
	.est-nv-container, 
	.settings-container {
		width: 86% !important;
		margin-left: 90px !important;
	}
}

@media (min-width: 1651px) and (max-width: 1700px) {
	.est-nv-container, 
	.settings-container {
		width: 86% !important;
		margin-left: 110px !important;
	}
}

@media (min-width: 1701px) and (max-width: 1750px) {
	.est-nv-container, 
	.settings-container {
		width: 83% !important;
		margin-left: 130px !important;
	}
}

@media (min-width: 1751px) and (max-width: 1800px) {
	.est-nv-container, 
	.settings-container {
		width: 80% !important;
		margin-left: 150px !important;
	}
}

@media (min-width: 1801px) and (max-width: 1850px) {
	.est-nv-container, 
	.settings-container {
		width: 77% !important;
		margin-left: 170px !important;
	}
}

@media (min-width: 1851px) and (max-width: 1900px) {
	.est-nv-container, 
	.settings-container {
		width: 74% !important;
		margin-left: 190px !important;
	}
}

@media (min-width: 1901px) and (max-width: 1950px) {
	.est-nv-container, 
	.settings-container {
		width: 68% !important;
		margin-left: 230px !important;
	}
}

@media (min-width: 1951px) and (max-width: 2000px) {
	.est-nv-container, 
	.settings-container {
		width: 65% !important;
		margin-left: 250px !important;
	}
}

@media (min-width: 2001px) and (max-width: 2050px) {
	.est-nv-container, 
	.settings-container {
		width: 62% !important;
		margin-left: 270px !important;
	}
}

@media (min-width: 2051px) and (max-width: 2100px) {
	.est-nv-container, 
	.settings-container {
		width: 59% !important;
		margin-left: 290px !important;
	}
}

@media (min-width: 2101px) and (max-width: 2150px) {
	.est-nv-container, 
	.settings-container {
		width: 56% !important;
		margin-left: 310px !important;
	}
}

@media (min-width: 2151px) and (max-width: 2200px) {
	.est-nv-container, 
	.settings-container {
		width: 53% !important;
		margin-left: 330px !important;
	}
}

@media screen and (min-width: 2201px) {
	.est-nv-container, 
	.settings-container {
		width: 50% !important;
		margin-left: 350px !important;
	}
}

@media (max-width: 955px) {
	.inv-details {
		display: none;
	}

	.est-nv-container,
	.settings-container {
		// width: 1154px !important;
		width: 100% !important;
		flex: 100%;
	}
}

@media (min-width: 768px) and (max-width: 955px) {
	.inv-details {
		display: none;
	}

	.est-nv-container, 
	.settings-container {
		// width: 1154px !important;
		width: 100% !important;
		flex: 100% !important;

		.inv-preview {
			flex: 100% !important;
			max-width: 100% !important;
		}
	}
}


.send-to-products-purchase {
	background: #eaeaea;
	min-height: 40px;
	border-radius: 12px;
	margin-top: 8px;
	margin-bottom: 15px;
	padding: 12px 10px 0 10px;
	// display: flex;
}

.btn-export-po {
	.dropdown-menu {
		transform: translate3d(-268.5px, 33.5px, 0px) !important;
	}
}
.btn-export-po-2 {
	.dropdown-menu {
		transform: translate3d(-158.5px, 33.5px, 0px) !important;
	}
}

.add-item-empty-div {
	background: #eeeef2;
	display: flex;
	justify-content: center;
	padding: 20px;
	border: 1px solid #c3c3c3;
	border-radius: 8px;
	cursor: pointer;

	// &:hover {
	// 	border: 1px dashed #c3c3c3;
	// 	filter: blur(2px);
	// }
}

.card-header-radius-0 {
	margin-bottom: 0;
    background-color: #f8f9fc;
	padding: 15px 10px;
	border-radius: 0;
	border-top-right-radius: 22px;
    border-top-left-radius: 22px;
	border-bottom: 1px solid #dedede;
}
.card-border-gray {
	border: 1px solid #dedede;
}
.payment-modal-attachment-icon {
	border: 1px solid #c1c1c1;
    padding: 5px 10px;
    border-radius: 6px;
}
.payment-modal-attachment-name {
	margin-left: 10px;
}
.payment-modal-attachment-delete {
	// right: 20px;
    // position: absolute;
    // cursor: pointer;
	right: 0px;
    position: absolute;
    cursor: pointer;
    padding: 8px 11px;
    border-radius: 38px;
	&:hover {
		background: #e0e0e0;
	}
}
.payment-modal-attachment-container {
    border: 1px solid #e0e0e0;
    padding: 10px 20px 10px 10px;
    border-radius: 12px;
	margin-bottom: 10px;
}
@keyframes progress-animation {
	0% { width: 0%; }
	100% { width: 100%; }
}
@keyframes gradientTransition {
	0% {
	  background-position: 100% 0;
	}
	100% {
	  background-position: 0 0;
	}
}
.animated-progress-bar {
	height: 5px;
	width: 100%;
	background-color: #e0e0e0;
	border-radius: 5px;
	overflow: hidden;
	position: relative;
}
.animated-progress-bar::after {
	content: '';
	display: block;
	height: 100%;
	width: 100%;
	background-size: 200% 100%;
	background-image: linear-gradient(to right, #4caf50 50%, #e0e0e0 50%);
	animation: gradientTransition 2s linear forwards;
  }
//   .animated-progress-bar::after {
// 	content: '';
// 	display: block;
// 	height: 100%;
// 	width: 100%;
// 	background-color: #4caf50;
// 	animation: progress-animation 2s linear forwards;
//   }

// .menu-quick-create {
// 	position: absolute;
// 	right: 20px;
// 	cursor: pointer;
// 	margin-top: 6px;

// 	.active {
// 		display: none;
// 	}

// 	&:hover {
// 		// color: #0066ff;
// 		.passive {
// 			display: none;
// 		}
// 		.active {
// 			display: block
// 		}
// 		// color: #fff;
// 		// background: #39C880;
// 	}
// }
.menu-quick-create {
    position: absolute;
    right: 12px;
    cursor: pointer;
    margin-top: 6px;

    .active {
        display: none;
        opacity: 0; // Start hidden
        animation: fadeOut 0.3s forwards; // Animation to fade in
    }

    &:hover {
        // .passive {
        //     display: none;
        // }
        .active {
            display: block;
			margin-top: -20px;
            animation: fadeIn 0.3s forwards; // Change to fadeOut when necessary
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.new-nav-bar.expanded {
	.navbar-nav-items {
		.sub-menu {
			.nav-item  {
				.menu-quick-create {
					margin-top: -30px;
				}
			}
		}
	}
}
.inbound-item-button {
	display: flex;
    margin-top: 20px;
    justify-content: right;
}
.nav-item:hover {
	border-radius: 12px;
}
.preview-transaction-transfer {
    position: absolute;
    width: 100%;
    /* text-align: center; */
    // margin-left: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    z-index: 1;
}
.popup-transaction-header {
    background: linear-gradient(to bottom, #0066ff, #fff);
    position: absolute;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    width: 100%;
    height: 150px;
}
.rs__menu-list {
	.rs__option {
			&:hover {
				background: #f5f5f5 !important;

				transition: none !important;
			}
	}
}
.product-popup-option {
	.rs-container {
		.rs__menu-portal {
			.rs__menu {
				.rs__menu-list {
					max-height: 370px !important;
				}
			}
		}
	}
}
.custom-input-group {
	.input-group {
		.btn {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;	
		}
		.dropdown-menu.show {
			min-width: auto !important;
			// transform: translate(-417px, 32px) !important;
			inset: auto !important;
		}
	}
}
.hover-row {
	position: relative;
	.hover-button-container, .hover-button-container-lg {
	  position: relative;
	  display: none;
	}
	&:hover {
		.hover-button-container {
			position: absolute;
			display: flex;
			//   margin-top: 29px;
			transform: translateX(1%);
			transition: 1.2s;
			height: -webkit-fill-available;
		}
		.hover-button-container-lg {
			position: absolute;
			display: flex;
			right: 0;
			margin-top: -10px;
			transform: translateX(1%);
			transition: 1.2s;
			height: -webkit-fill-available;
		}
	}
}

.hover-button-container,
.hover-button-container-lg {
	position: relative;
	display: none;
}
  
.hover-button {
	position: absolute;
	top: 0;
	// left: 0;
	right: 0;
	bottom: 0;
	display: none;
	justify-content: center;
	align-items: center;
	// margin-top: -31px;
	margin-right: 29px;
	.btn {
		// padding: 8px 12px !important;
		padding: 5px 5px !important;
		min-width: auto !important;
	}
	.btn-primary {
		border: 1px solid #8b8b8b;
		&:hover {
			background: #0264ce !important;
		}
	}
}

.hover-row:hover .hover-button {
	display: flex;
}

.hover-row:hover td:not(.hover-button-container) {
	filter: blur(0.1px);
}
.justify-content-right {
	justify-content: right;
}
.justify-content-left {
	justify-content: left;
}
.justify-content-center {
	justify-content: center;
}
.cursor-pointer {
	cursor: pointer;
}
.btn-icon-only {
	min-width: auto !important;
}
.btn-icon-square {
	padding: 7px 8px !important;
}

// @keyframes swipeRightToLeft {
// 	from {
// 	  transform: translateX(100%);
// 	  opacity: 0;
// 	}
// 	to {
// 	  transform: translateX(0);
// 	  opacity: 1;
// 	}
//   }
  
//   .swipe-rtl-animation {
// 	position: relative;
// 	animation: swipeRightToLeft 3s ease-out;
//   }

.padLeft0 {
	padding-left: 0 !important;
}
.checkbox-action-container {
	height: 100vh !important;
    position: absolute !important;
    top: -62px !important;
}
.checkbox-action {
    position: fixed;
    background: #fff;
    width: 100%;
	display: flex;
	justify-content: left;
	align-items: center;
	bottom: 0px;
    padding: 10px;
    border: 1px solid #a4a4a4;
    border-radius: 6px;
    z-index: 6;
	margin-left: -9px;
    animation: fadeIn 0.8s forwards; /* Apply fade-in animation */
	gap: 10px;
}
.card-breadcrumbs {
	nav {
		.cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
			margin-top: -9px;
		}
	}
}

.align-item-center {
	align-items: center;
}
.custom-input-group {
	.input-group {
		.dropdown-toggle {

			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border: 1px solid #e0e0e0;
		}
	}
}
.payment-input-group {
	.input-group {
		.dropdown-menu.show {

			left: 0px !important;
    		width: 108px !important;
		}
	}
}
.btnPicklistMore {
	.dropdown-menu.show {
		transform: translate3d(-31.5px, 33.5px, 0px) !important;
	}
}
.custom-footer-popup {
	position: absolute;
    background: rgba(107, 122, 144, 0.2);
    width: 100%;
    top: 0;
    height: 96vh;
    margin-left: -1%;
    backdrop-filter: blur(2px);
}
.model-col {
	div {
		.rs-container {
			.rs__menu-portal {
				.rs__menu {
					margin-left: -83px !important;
				}
			}
		}
	}
}
.sidebar-title-backside {
	position: absolute;
    height: 42px;
}
.nav-item-settings {
	width: 190px;
}
.settings-card-title {
	font-weight: 600 !important;
    font-size: 14px !important;
}
.card-body-settings-container {
	padding: 17px 25px;
	min-height: 0;
}
.color-theme {
	color: #0066ff;
}
.settings-icon-bg-container 
{
	background: #e5f0ff;
	border-radius: 32px;
	padding: 11px;
}
.settings-feature-headers {
	font-size: 18px;
    font-weight: 600;
}
.settings-logo-delete-container {
	position: absolute;
    margin-top: -9px;
    right: -13px;
    // border: 1px solid #e9e9e9;
    padding: 4px 7px;
    border-radius: 32px;
    background: #fff;
    cursor: pointer;

	&:hover {
		background: #e9e9e9 !important;
	}
	svg {
		margin-top: -2px;
	}
}

// @media (max-width: 1469px) {
// 	.picklist-sku-col {
// 		display: none !important;
// 	}
// }
.input-no-radius-top-left {
	border-top-left-radius: 0 !important;
}
.input-no-radius-top-right {
	border-top-right-radius: 0 !important;
}
.input-no-radius-bottom-left {
	border-bottom-left-radius: 0 !important;
}
.input-no-radius-bottom-right {
	border-bottom-right-radius: 0 !important;
}
.input-group-2-form {
	.left-input {
		max-width: 100px !important;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.right-input {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
}

.settings-currency-rate-field {

	min-width: 36px !important;
	padding: 9px 0px !important;
}
.h36 {
	height: 36px !important;
}
.input-group-2-form-currency {
	.rs-container {
		.rs__control {
			border-top-right-radius: 0px !important;
			border-bottom-right-radius: 0px !important;
			min-width: 107px !important;
		}
	}
}
.card-effect {
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	&:hover {
		transform: translateY(-5px);
		box-shadow: 0px 0px 11px 2px #a6a6a6 !important;
	}
}
.MuiTooltip-tooltip {
	font-size: 11px !important;
}
.text-10 {
	font-size: 10px;
}
.more_label_container {
	color: #787878 !important;
	border: 1px solid #787878;
	padding: 2px 7px;
	border-radius: 9px;
	font-size: 11px;
	margin-left: 5px;
	cursor: pointer;
}
.h-35 {
	height: 35px;
}
.justify-left{
	justify-content: left;
}
.justify-right{
	justify-content: right;
}
.justify-center{
	justify-content: center;
} 
.items-center {
	align-items: center;
}
.gap-4 {
	gap: 4px;
}
.text-12 {
	font-size: 12px;
}

.dynamic-listing {
	.dynamic-listing-header {
		display: block;
	}
	.dynamic-listing-label-container {
		display: none;
	}
	.dynamic-listing-two-rows-horizontal-view {
		display: block;
	}
	.dynamic-listing-two-rows-vertical-view {
		display: none;
	}
}

@media (max-width: 768px) {
	.dynamic-listing {
		.dynamic-listing-header {
			display: none !important;
		}
		.dynamic-listing-label-container {
			display: grid;
		}
		.dynamic-listing-two-rows-horizontal-view {
			display: none;
		}
		.dynamic-listing-two-rows-vertical-view {
			display: block;
		}
	}
    .serial-details-basic-info-container {
        text-align: left !important;
        width: auto !important;
    }
}
.btn-export-backorder {
	.dropdown-menu.show {
		transform: translate(-70px, 32px) !important;
	}
}