$color-purple-1: #6b4a9f;
$color-pink-1: #d8578a;
$color-pink-2: #c8467a;
$color-green-1: #45ad51;
$color-green-2: #6bd076;
$color-green-3: #449e4f;
$color-success: #70DD36;
$color-success-light: #D9F2EC;
$color-danger: #F64E60;
$color-danger-light: #F7D3CF;
$color-warning: #FFB946;



$color-primary: #0066FF;
$color-light-max: #fff; 
$color-dark-max: #000; 
$color-link: #42739B;
$color-pagination-solid: #BCD3E5;
$color-text-1: #17181A;
$color-text-2: #707070;
$color-input-color: $color-dark-max;
$color-input-borders: #D9E1E7;
$color-input-place-holder: #939393;
$color-borders-1: #939393;
$color-borders-2: #EFF2F5;
$color-borders-3: #acacac;
$color-borders-4: #d9d9d9;

$color-grey-1: #D9D9D9;
$color-main-bg: #F9F9F9;
$color-sidebar-bg: $color-light-max;
$color-border-thead: #DDDFE1;
$currency-border: #D9E1E7CC;
$nav-head-color-2: #99B2C6;
$quick-access-text-color: #192A3E;
$color-bg-2: #fafafa;
