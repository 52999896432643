@import '../../assets/scss/colors';

.notification-dropdown {
	color: #000;
	max-width: 350px;
	background: #fff;
	padding: 10px;
	position: absolute;
	top: calc(100% - 5px);
	right: 10px;
	z-index: 10;
	/* width: 300px; */
	min-width: 350px;
	border: 0.5px solid #d7dfe8;
	border-radius: 10px;

	.notifications {
		display: flex;
		flex-direction: column;
		gap: 15px;

		.no-notifications {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 3px;
			min-height: 10vh;
		}

		max-height: 50vh;
		overflow-y: scroll;

		.notification {
			background-color: #fff;
			// border: 1px solid $color-light-scale-gray-2;
			border-left: 2px solid $color-primary-2;
			display: flex;
			box-shadow: 0px 0px 5px 0px #f1f1f1;
			border-radius: 10px;

			.notification-body {
				flex: 100%;
				padding: 10px;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				font-size: 1rem;
				flex-direction: column;
				gap: 10px;
			}

			&.urgent {
				border-left-color: $color-danger;
			}
		}

		p {
			margin: 0;
		}

		.notification-content {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 10px;
		}

		.notification p {
			flex: 1;
			margin-top: 0;
			font-size: 1rem;
			margin: 0;
		}

		.notification-actions {
			display: flex;
			gap: 5px;
			button {
				font-size: 1rem;
				// background-color: #4caf50;
				// color: white;
				// padding: 5px 5px;
				// border: none;
				// border-radius: 4px;
				// cursor: pointer;
				min-width: unset;
				width: auto;
				width: 50px;
				padding: 1px;

				&.accept-btn {
					background-color: $color-primary-2;
					border-color: $color-primary-2;
					color: #fff;
				}
			}
		}
	}
}
