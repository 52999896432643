.drawer-container-header-row {
    margin-left: -1rem;
    margin-right: -1rem;
}
.drawer-container {
    position: fixed; /* fixed */
    top: 0; /* top-0 */
    right: 0; /* right-0 */
    z-index: 41; /* z-40 */
    height: 100vh; /* h-screen */
    padding: 1rem; /* p-4 */
    overflow-y: auto; /* overflow-y-auto */
    // transition: transform 0.5s ease-in-out; /* transition-transform */
    transition: transform 5s ease-in-out; /* transition-transform */
    background-color: white; /* bg-white */
    width: 20rem; /* w-80 */
    color: #000;
    
    &.open{
        transform: translateX(0%); /* translate-x-no */
    }
    &.close {
        transform: translateX(100%); /* translate-x-full */
    }
  }
  
  /* Dark mode styles */
.drawer-container.dark {
    background-color: #2d3748; /* dark:bg-gray-800 */
}

.drawer-header {
    display: inline-flex; /* inline-flex */
    align-items: center; /* items-center */
    margin-bottom: 1rem; /* mb-4 */
    font-size: 1rem; /* text-base */
    font-weight: 600; /* font-semibold */
    color: #6b7280; /* text-gray-500 */
}
  
  /* Dark mode styles */
.drawer-header.dark {
    color: #9ca3af; /* dark:text-gray-400 */
}

.drawer-close-button {
    color: #9ca3af; /* text-gray-400 */
    background-color: transparent; /* bg-transparent */
    border-radius: 24px; /* rounded-lg */
    font-size: 0.875rem; /* text-sm */
    width: 2rem; /* w-8 */
    height: 2rem; /* h-8 */
    position: absolute; /* absolute */
    top: 0.625rem; /* top-2.5 */
    right: 0.625rem; /* end-2.5 */
    display: inline-flex; /* inline-flex */
    align-items: center; /* items-center */
    justify-content: center; /* justify-center */
    transition: background-color 0.3s, color 0.3s; /* Add transition for hover effects */
    border: 0;

    &:hover {
        background-color: #e5e7eb; /* hover:bg-gray-200 */
        color: #1f2937; /* hover:text-gray-900 */
    }

    &.dark:hover {

        background-color: #4b5563; /* dark:hover:bg-gray-600 */
        color: #ffffff; /* dark:hover:text-white */
    }
}
  
.drawer-overlay {
    position: fixed; /* fixed */
    height: 100vh; /* h-screen */
    top: 0; /* inset-0 */
    right: 0; /* inset-0 */
    bottom: 0; /* inset-0 */
    left: 0; /* inset-0 */
    background-color: rgba(0, 0, 0, 0.5); /* bg-black bg-opacity-50 */
    backdrop-filter: blur(4px); /* backdrop-blur-sm */
    z-index: 20; /* z-[51] */
}
.drawer-content-header-container {
    padding: 10px 0;
    background: #d7e7ff;
}




.rb-container {
    // font-family: "PT Sans", sans-serif;
    width: 100%;
    margin: auto;
    display: block;
    position: relative;
  }
  
  .rb-container ul.rb {
    margin: 1em 0;
    padding: 0;
    display: inline-block;
  }
  
  .rb-container ul.rb li {
    list-style: none;
    margin: auto;
    margin-left: 1em;
    min-height: 50px;
    border-left: 1px dashed #a8b6d4;
    padding: 0 0 10px 15px;
    position: relative;
  }
  
  .rb-container ul.rb li:last-child {
    border-left: 0;
  }
  
.rb-container ul.rb li {

    &::before {
        position: absolute;
        left: -8px;
        top: 0px;
        content: " ";
        border-radius: 500%;
        background: #fff;
        height: 15px;
        width: 15px;
        transition: all 500ms ease-in-out;
        border: 5px solid #c0c0c0;
    }
}
.rb-container ul.rb li.active {
    &::before {
        position: absolute;
        left: -8px;
        top: 0px;
        content: " ";
        border-radius: 500%;
        background: #fff;
        height: 15px;
        width: 15px;
        transition: all 500ms ease-in-out;
        border: 5px solid #3ac57f !important;
    }
}
  
//   .rb-container ul.rb li:hover::before {
//     border-color: #003990;
//     transition: all 500ms ease-in-out;
//   }
  
  ul.rb li .timestamp {
    color: #323232;
    position: relative;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
  }
  
  .item-title {
    color: #323232;
  }
  .item-content {
    color: #a1a1a1;
  }
  
  .container-3 {
    width: 5em;
    vertical-align: right;
    white-space: nowrap;
    position: absolute;
  }
  
  .container-3 input#search {
    width: 150px;
    height: 30px;
    background: #fbfbfb;
    border: none;
    font-size: 10pt;
    color: #262626;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 0.9em 0 0 28.5em;
    box-shadow: 3px 3px 15px rgba(119, 119, 119, 0.5);
  }
  
  .container-3 .icon {
    margin: 1.3em 3em 0 31.5em;
    position: absolute;
    width: 150px;
    height: 30px;
    z-index: 1;
    color: #4f5b66;
  }
  
//   .input-dot::placeholder {
//     padding: 5em 5em 1em 1em;
//     color: #0066ff;
//   }
  

.drawer-fixed-top {
    position: fixed; /* Make the div fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Prevent scrolling */
    z-index: 1000; /* Ensure it is above other elements */
}

#preloader {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
     background: #0066ff ; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    z-index:99; /* makes sure it stays on top */
    transition: transform 0.3s ease-in-out; /* transition-transform */
}

#status {
    width:50px;
    height:30px;
    position:fixed;
    left:50%; /* centers the loading animation horizontally one the screen */
    top:50%; /* centers the loading animation vertically one the screen */
   margin:-25px 0 0 -15px; /* is width and height divided by two */
}

.spinner {
  margin: 0px auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.drawer-title-container {
    font-weight: 600;
    color: #323232;
}

.drawer-date-container {
	color: #b6b6b6;
	font-weight: 600;
    font-size: 10px;
}
.timeline-trigger-action {
    padding: 8px 10px;
    border-radius: 32px;
    color: #0066ff;
    &:hover {
        background: #0066ff;
        color: #fff;
    }
}