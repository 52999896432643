@import '../../assets/scss/colors';

.notifications-page {
	color: #000;

	.notifications {

        display: flex;
        flex-direction: column;
        gap: 15px;


		.notification {
			background-color: #fff;
			// border: 1px solid $color-light-scale-gray-2;
            border-left: 2px solid $color-primary-2;
            display: flex;
			box-shadow: 0px 0px 5px 0px #f1f1f1;

            .notification-icon {
                flex: 100px;
                width: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                color: $color-light-scale-gray-3;
            }

            .notification-body {
                flex: 100%;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                font-size: 1rem;
                flex-direction: column;
                gap: 10px;
            }

            &.urgent {
                border-left-color: $color-danger;
            }

		}

		p {
			margin: 0;
		}

		.notification-content {
			width: 100%;	
			display: flex;
			align-items: center;
		}

		.notification p {
			flex: 1;
			margin-top: 0;
			font-size: 1rem;
			margin: 0;
		}

		.notification-actions {
			display: flex;
			gap: 5px;
			button {
				font-size: 1rem;
				// background-color: #4caf50;
				// color: white;
				// padding: 5px 5px;
				// border: none;
				// border-radius: 4px;
				// cursor: pointer;
				min-width: unset;
				width: auto;
				width: 50px;
				padding: 1px;

                &.accept-btn {
                    background-color: $color-primary-2;
                    border-color: $color-primary-2;
                    color: #fff;
                }
			}
		}
	}
}
