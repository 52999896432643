.wrapper-new {
	display: flex !important;
	height: 100dvh !important;
}

.currency-div-new {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	flex: 50px 1;
	flex-grow: 0;
	position: relative;
}

#parent-client-wrapper {
	// display: flex;
	// flex-direction: column;
	// height: 100dvh;
}

#wrapper-client {
	display: flex;
	height: 100dvh;
	width: 100%;
	overflow: hidden;
	flex-direction: column;
}

#wrapper-client #mainNav {
	background: #fff;
	box-shadow: none;
	height: 60px;
	border-bottom: none;
	box-shadow: none;
	border-bottom: 1px solid rgba(217, 225, 231, 0.8);
	color: #fff;
}

#wrapper-client #mainNav .nav-search {
	width: 100%;
	flex: 100% 1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 15px;
}

#wrapper-client #mainNav .nav-actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: -moz-fit-content;
	width: fit-content;
	gap: 15px;
	color: #17181a;
}

#wrapper-client #mainNav #nav-page-title {
	min-width: -moz-fit-content;
	min-width: fit-content;
}

#wrapper-client #mainNav .nav-search .nav-search-input {
	color: rgba(217, 225, 231, 0.8);
	font-size: 17px;
	display: flex;
	align-items: center;
	padding: 0;
	position: relative;
}

#wrapper-client #mainNav .nav-search .nav-search-input input {
	padding: 5px;
	padding: 5px 30px 5px 15px;
	border: 0;
	outline: 0;
	box-shadow: none;
	border: 1px solid rgba(217, 225, 231, 0.8);
	font-size: 12px;
	transition: all 0.3s ease-in-out;
	width: 250px;
	border-radius: 50px;
}

#wrapper-client #mainNav .nav-search .nav-search-input .nav-search-input-icon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-55%);
}

#new-wrapper {
	display: flex;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	background-image: url('../../../assets/images/designs/bg-client.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center 10%; // adjust the second value as needed
}

.new-nav-bar-client {
	display: flex;
	flex-direction: column;
	margin: 0;
	gap: 18px;
	padding: 20px 20px;
	background-color: #fff;
	width: 200px;
	min-width: 200px;
	position: relative;
	border-right: 1px solid #f1f5f7;
	transition: all 0.2s ease-in-out;
	z-index: 11;

	@media screen and (max-width: 768px) {
		width: 100%;
		justify-content: start; // Aligns items to the left
		align-items: center; // Centers items vertically
	}
}

.cmpny-dtls {
	display: flex;
	gap: 10px;
	align-items: center;
	flex-direction: column;
	line-height: 6px;
	margin-bottom: -12px;
}

.cmpny-dtls-vrfied {
	margin-bottom: 0 !important;
}

.company-details-link {
	margin-bottom: 1rem;
}

.action-required {
	color: #f67812;
	letter-spacing: normal;
	font-size: 12px;
	width: 100%;
}

.new-nav-bar-client.collapsed {
	width: 50px;
	min-width: 50px;
	padding: 20px 5px;
}

.new-nav-bar-client.collapsed .navbar-nav-items {
	padding-inline: 5px;
	align-items: center;
	gap: 0;
}

.new-nav-bar-client .collapsed li.nav-item a.nav-link .menuIcon {
	width: 30px;
	height: 30px;
}

.new-nav-bar-client.collapsed .nav-item .nav-link span:last-child {
	display: none;
}

.new-nav-bar-client .navbar-nav-items {
	display: flex;
	flex-direction: column;
	gap: 0px;
	overflow: auto;
	flex: auto;
	font-size: 13px;

	@media screen and (max-width: 768px) {
		justify-content: center;
		align-items: left;
	}
}

.new-nav-bar-client ul {
	padding: 0;
	margin: 0;
}

.new-nav-bar-client li.nav-item {
	padding: 0;
	margin: 0;
	font-size: 18px;
	margin-bottom: 0px;
}

.new-nav-bar-client li {
	list-style: none;
	padding: 0;
	margin: 0;
}

.new-nav-bar-client .currency-div {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	height: 50px;
	flex: 50px 1;
	flex-grow: 0;
	position: relative;
	padding-bottom: 2rem;
}

.new-nav-bar-client li.nav-item.active a.nav-link {
	color: #0066ff;
}

.new-nav-bar-client li.nav-item a.nav-link {
	padding: 7px 10px;
	margin: 0;
	display: flex;
	gap: 10px;
	align-items: center;
	color: #17181a;
	border-radius: 10px;
	transition: all 0.3s ease-in-out; // Added transition
}

.new-nav-bar-client li.nav-item a {
	text-decoration: none;
	color: #000;
}

.new-nav-bar-client li.nav-item.active a.nav-link .menuIcon {
	color: #0066ff;
	fill: #0066ff;
	background-color: transparent;
}

.main-page-client {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;

	width: 100%;
	height: 100%;
	overflow: auto;

	@media screen and (max-width: 768px) {
		display: block;
		padding: 0;
		height: auto;
	}
}

.action-signup {
	width: 100%;
	margin: auto;
	text-align: center;
}

.form-signup {
	width: 75%;
	margin: auto;
	font-size: 12px !important;
	padding: 15px;
}

.sign-link {
	cursor: pointer;
	color: #0066ff !important;
}

.signup-head {
	text-align: center;

	h4 {
		font-size: 16px;
	}

	p {
		font-size: 12px;
		font-weight: 300;
	}
}

.main-page-auth {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 85px 100px;
	width: 727px;

	margin: auto;
	position: absolute;
	top: 45%;
	left: 50%;
	background-color: transparent;
	transform: translate(-50%, -50%);

	@media (max-width: 650px) {
		padding-left: 10px;
		padding-right: 10px;
		overflow: hidden;
	}
}

.main-page-auth-2 {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
	width: 595px;

	margin: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@media (max-width: 650px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.main-page-auth-3 {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 85px 100px;
	width: 640px;

	margin: auto;
	position: absolute;
	top: 45%;
	left: 50%;
	background-color: transparent;
	transform: translate(-50%, -50%);

	@media (max-width: 650px) {
		padding-left: 10px;
		padding-right: 10px;
		overflow: hidden;
	}
}

@media (max-width: 650px) {
	.main-page-auth {
		width: 100% !important;
		margin-top: 0 !important;
		height: 89vh;
		display: flex;
		justify-content: center;
	}

	.main-page-auth-2 {
		width: 100% !important;
		margin-top: 0 !important;
		height: 89vh;
		display: flex;
		justify-content: center;
	}

	.main-page-auth-3 {
		width: 100% !important;
		margin-top: 0 !important;
		height: 89vh;
		display: flex;
		justify-content: center;
	}

	.form-signup {
		margin: auto !important;
	}
}

.backdrop-image-purple,
.backdrop-image-pink,
.backdrop-image-purple-sign,
.backdrop-image-pink-sign {
	position: absolute;
	z-index: -1;
	transition: top 0.5s ease, left 0.5s ease, right 0.5s ease; // adjust as needed
}

.backdrop-image-purple {
	top: -20%;
	left: -15%;
}

.backdrop-image-pink {
	top: -30%;
	right: -30%;
}

.backdrop-image-purple-sign {
	top: -20%;
	left: -30%;
}

.backdrop-image-pink-sign {
	top: -10%;
	right: -45%;
}

@media (max-width: 768px) {
	.backdrop-image-purple {
		top: 10%;
		left: -40%;
	}

	.backdrop-image-pink {
		top: 10%;
		right: -52%;
	}

	.backdrop-image-purple-sign {
		top: -10%; // adjust as needed
		left: -20%; // adjust as needed
	}

	.backdrop-image-pink-sign {
		top: -5%; // adjust as needed
		right: -35%; // adjust as needed
	}
}

.purple-image {
	position: absolute;
	top: -10%;
	left: -10%;
}

.pink-image {
	position: absolute;
	top: -10%;
	right: -10%;
}

.actions-order-client {
	width: 100%;
	padding: 20px;
	flex-direction: column;
	height: fit-content;
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	margin-bottom: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	text-decoration: none;
	color: #17181a;
}

.actions-company-details-client {
	width: 100%;
	padding: 20px;
	padding-top: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	text-decoration: none;
	color: #17181a;

	@media screen and (max-width: 768px) {
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;
		margin-top: 10px !important;
	}
}

.dragRegistration {
	color: #0066ff !important;
	border: 1px solid #0066ff !important;
}

.bg-transparent {
	.modal-content {
		background-color: transparent !important;
		border: none !important;
	}
}

.mobile-search:hover {
	background-color: #f2f2f2;
	color: #0066ff;
	border-radius: 10px;
}

.heading-order-client-tbl {
	h3 {
		font-size: 16px;
		font-weight: 600;
		letter-spacing: normal;
	}
}

.order-client-content {
	padding: 20px;
	background-color: #ffffff;
	border-radius: 16px;
	max-height: 100%;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

	@media screen and (max-width: 768px) {
		border-radius: 0;
		box-shadow: none;
	}
}

.dragIconVAT {
	border: 1px solid #e8e8ed;
	border-radius: 5px;
	height: 80px;
	display: flex;
	justify-content: end;
	align-items: center;
	flex-direction: column;

	.UploadIcon {
		font-size: 28px;
	}
}

.company-details-client-content {
	padding: 20px 60px;
	margin-bottom: 0px !important;
	margin-top: 0% !important;
	height: fit-content;
	width: 520px;
	margin: auto;
	border-radius: 16px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

	@media screen and (max-width: 768px) {
		width: 90%;
		padding: 10px;
		margin: auto !important;
	}
}

.company-details-client-content-2 {
	padding: 80px 60px;
	height: fit-content;
	width: 520px;
	margin: auto;
	border-radius: 16px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

	@media screen and (max-width: 650px) {
		width: 100%;
		padding: 80px 30px;
	}
}

.company-details-client-content-3 {
	padding: 40px 40px;
	height: fit-content;
	width: 520px;
	margin: auto;
	border-radius: 16px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.action-company-dtls-btn {
	height: fit-content;
	width: 450px;
	margin: auto;
	display: flex;
	justify-content: end;

	@media screen and (max-width: 768px) {
		width: 90%;
		margin-bottom: 20px;
	}
}

.onboard-label {
	font-weight: 300;
	font-size: 14px;
	margin-left: 3px;
}

.action-company-dtls-adrs-btn {
	height: fit-content;
	width: 450px;
	margin: auto;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		width: 90%;
		margin-bottom: 20px;
	}
}

.btn-cmpny-dtls-action {
	background-color: #0066ff;
	color: #fff;
	padding: 4px 24px;
	border-radius: 20px;
	font-size: 12px;
}

.btn-cmpny-dtls-action:hover {
	color: #0066ff !important;
	background-color: #fff;
	border: 1px solid #0066ff;
	transition: all 0.3s ease-in-out;
}

.btn-cmpny-dtls-action-back {
	border: 1px solid #707070;
	color: #707070;
	padding: 4px 24px;
	border-radius: 20px;
	font-size: 12px;
}

.fw-600 {
	font-weight: 600;
}

.tooltipclient {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.tooltipclient .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 5px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	/* Position the tooltip above the text */
	left: 150%;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltipclient:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.block_a {
	pointer-events: none;
}

.MTVH {
	margin-top: 15dvh;
}

.verify-div {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	gap: 8px;

	svg {
		width: 60px;
		margin: auto;
	}

	h3 {
		font-size: 16px;
		font-weight: 600;
	}

	p {
		margin-bottom: -0.3rem;
	}

	span {
		font-size: 11px;
	}
}

.search-imei-div {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	gap: 3px;

	svg {
		width: 40px;
		margin: auto;
	}

	h3 {
		font-size: 16px;
		font-weight: 600;
	}

	p {
		margin-bottom: -0.3rem;
	}
}

.search-imei {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 20px;

	.input-search {
		position: relative;
		width: 220px;

		svg {
			position: absolute;
			top: 7px;
			right: 0;
			font-size: 18px;
			z-index: 999;
		}
	}

	button {
		background-color: #0066ff;
		color: #fff;
		padding: 4px 28px;
		border: none;
		border-radius: 10px;
	}

	.device-coverage {
		display: flex;
		gap: 5px;

		.icon-coverage {
			img {
				width: 50px;
			}
		}
	}
}

.clr-expire {
	color: #cb1401;
}

.clr-avlbl {
	color: #348f46;
}

.pad-check-warrant {
	padding: 0 5px !important;
}

.group-chk-cmpny-dtls {
	display: flex;
	gap: 10px;
	align-items: baseline;
	color: #000000;
}

.group-chk-trms-dtls {
	display: flex;
	gap: 10px;
	align-items: stretch;
	color: #000000;
	margin-bottom: 6px;

	label {
		margin-bottom: 0 !important;
	}

	span {
		color: #0066ff;
		cursor: pointer;
	}
}

.trn-over-cmpny-dtls-tbl-heading {
	display: flex;
}

.btn-cmpny-dtls-action svg {
	font-size: 19px;
}

.btn-cmpny-dtls-action-back svg {
	font-size: 19px;
}

.status-Invoice-client-inprogress {
	color: #0066ff !important;
}

.status-Invoice-client-onhold {
	color: #cb1401 !important;
}

.btn-cancel {
	background-color: #cb1401;
	color: #fff !important;
	min-width: 100px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 1rem;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
}

.head-cmpny-dtls-aprvd {
	h4 {
		font-size: 16px !important;
		font-weight: 500 !important;
		margin-bottom: 0 !important;
		padding: 0px 20px !important;
		margin-top: 1rem;
	}
}

.action-sv-cmpny {
	padding: 0px 20px !important;
}

.gnrl-dtls-aprvd {
	display: flex;
	width: 100%;
	padding: 10px 20px;
	gap: 20px;

	.inpt-dtls {
		flex: 0 0 30%;

		label {
			font-size: 12px;
			font-weight: 200;
		}
	}
}

.btn-confirm-agreement {
	background-color: #0066ff;
	fill: #fff !important;
	color: #fff !important;
	min-width: 100px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #0066ff;
	gap: 5px;
	font-size: 1rem;
	padding: 5px 10px;
	z-index: 0;
	border-radius: 10px !important;
}

.btn-confirm-agreement:hover {
	background-color: #fff !important;
	color: #0066ff !important;
	border: 1px solid #0066ff;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	fill: #0066ff !important;
}

.btn-cancel:hover {
	background-color: #fff !important;
	color: #cb1401 !important;
	border: 1px solid #cb1401;
	transition: all 0.3s ease-in-out;
}

.status-Invoice-client-success {
	color: #348f46 !important;
}

.pm-table tbody tr td {
	border-bottom: #eff2f5 1px solid;
	padding: 7px 10px 7px 10px;
	vertical-align: middle;
	color: #707070;
	font-size: 13px;
}

.invoice-client-content {
	padding: 20px;
	border-radius: 20px;
	background-color: #ffffff82;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.order-client-tb thead tr {
	background-color: transparent;
}

.inovice-client-tb thead tr {
	background-color: transparent;
}

.inovice-client-tb {
	text-align: center;
}

.icon-prdct-list-client {
	width: 50px;
	height: 50px;
	font-size: 18px;
	color: #606006;
}

.cart-product-list-client {
	background-color: #0066ff;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 7px 10px;
	width: fit-content;
}

.cart-product-list-client-mobile {
	background-color: #0066ff;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 7px 11px;
	width: fit-content;
}

.cart-product-list-client svg {
	fill: #fff;
}

.cart-product-list-client-mobile svg {
	fill: #fff;
}

.phn-cmpny-dtls {
	display: flex;
	gap: 5px;
}

.disabled-link {
	pointer-events: none;
	cursor: not-allowed;
	color: rgb(151, 151, 151) !important;
}

.add-to-cart-prdct-list {
	display: flex;
	width: 100%;
	justify-content: end;
	padding-right: 30px !important;
}

.href-link-head:hover {
	color: #0066ff;
	text-decoration: none;
	cursor: pointer;
}

.slct-phn-cmpny-dtls {
	width: 220px;
}

.client-prdct-details {
	width: 85%;
	display: flex;
	gap: 40px;
	align-items: center;
	justify-content: center;
	padding: 10px;
	margin: auto;
	margin-bottom: 1.2rem;

	.heading-client-modal {
		flex: 0 0 30%;

		img {
			width: 100px;
		}
	}

	.prdct-dtls-modal {
		flex: 0 0 70%;

		h3 {
			font-weight: 600;
			font-size: 13px;
			margin-top: 1rem;
		}

		.qty-inpt-prdct {
			width: 50px;
			border: none;
			border-radius: 20px;
			height: 28px;
			color: #707070;
			background-color: #ededed;
			padding: 10px;
			text-align: center;
		}

		.prdc-dtls-add-cart-btn {
			background-color: #0066ff;
			color: #fff;
			padding: 4px 28px;
			border-radius: 20px;
			font-size: 12px;
			border: none;
		}

		.prdc-dtls-action {
			display: flex;
			gap: 10px;
			margin-top: 1rem;
		}
	}
}
.qty-inpt-prdct-mobile {
	width: 60px;
	border: none;
	border-radius: 20px;
	height: 38px;
	color: #707070;
	background-color: #ededed;
	padding: 10px;
	text-align: center;
}

.qty-inpt-cart-mobile {
	width: 60px;
	border: none;
	border-radius: 5px;
	/* height: 38px; */
	color: #707070;
	background-color: #ededed;
	padding: 2px 3px;
	text-align: center;
}

.prdc-dtls-add-cart-btn-mobile {
	background-color: #0066ff;
	color: #fff;
	padding: 10px 28px;
	border-radius: 20px;
	font-size: 12px;
	border: none;
}

.medium-modal-prdct-dtls {
	width: 600px !important;
	max-width: 600px !important;
}

.product-client-tb thead tr {
	background-color: transparent;
	border: 1px solid #dddfe1 !important;
}

.product-client-tb {
	text-align: left;
}

.product-client-tb tbody tr:hover {
	background-color: #f9f9f9;
}

.inovice-client-tb tbody tr:hover {
	background-color: #f9f9f9;
	cursor: pointer;
}

.order-client-tb tbody tr:hover {
	background-color: #f9f9f9;
	cursor: pointer;
}

.order-client-active {
	background-color: #f9f9f9 !important;
}

.action-confirm {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.mobile-model-client-width {
	width: 100% !important;
}

.approved-mobile {
	display: flex;
	justify-content: center;
	align-items: center;

	.verified-mobile {
		background-color: #0066ff;
		width: 100%;
		color: #fff;
		fill: #fff;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
}

.padding-mobile-client {
	padding: 0px !important;

	.gnrl-dtls-aprvd {
		flex-direction: column !important;

		div {
			flex: 0 0 43% !important;
		}
	}
}

.active-navbar-mobile {
	color: #0066ff;
	fill: #0066ff;
}

.navbar-client-mobile {
	position: absolute;
	height: 90%;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out, visibility 0s 0.3s; // Modify this line
	visibility: hidden;
}

.navbar-client-mobile.expanded {
	transform: translateX(0);
	visibility: visible;
	transition-delay: 0s; // Add this line
}

.mobile-collapsed {
	visibility: hidden;
}

.footer-icon {
	font-size: 24px;
}

.link-prdct-dtls {
	color: #42739b;
	text-decoration: none;
	cursor: pointer;
}

#footer {
	padding: 10px;
}

.mobile-account-pop {
	padding: 10px;
	color: 'black';
	text-decoration: none;
}

.mobile-account-pop:hover {
	background-color: #f2f2f2;
	border-radius: 10px;
	color: #0066ff;
}

.pm-table thead tr th {
	border-bottom: #dddfe1 1px solid;
	padding: 15px 10px 15px 10px;
	color: #17181a;
	font-size: 13px;
	font-weight: 500;
}

.pm-table tfoot tr td {
	border-bottom: #dddfe1 1px solid;
	padding: 15px 10px 15px 10px;
	color: #17181a;
	font-size: 13px;
	font-weight: 500;
}

.client-dashboard-titile {
	display: flex;
	gap: 12px;
	align-items: center;
}

.settings-detail-mobile {
	height: 96%;
	overflow-y: auto;
	overflow-x: hidden;
}

.btn-client {
	background-color: #d3d3d3;
	color: #fff;
	padding: 4px 18px;
	border-radius: 20px;
	font-size: 12px;
}

.head-order-action {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #192a3e;

	h3 {
		font-size: 16px;
		font-weight: 600;
	}

	p {
		font-size: 12px;
		letter-spacing: normal;
	}
}

.btn-client svg {
	margin-right: 5px;
	font-size: 11px;
}

.btn-not-verified {
	font-size: 12px;
	letter-spacing: normal;
}

.btn-not-verified-incomplete {
	font-size: 12px;
	letter-spacing: normal;
}

.btn-verified {
	background-color: #ffffff;

	color: #000000;
	font-size: 12px;
}

.btn-portal-verified {
	background-color: #0066ff;
	padding: 0 6px !important;
	color: #fff;
	height: 20px;
	font-size: 12px;
	letter-spacing: normal;
}

.cart-section-side {
	width: 100%;
	height: fit-content;
	padding: 20px;
	border-radius: 11px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 1.5rem;
	background-color: #f9f9f9;
}

.side-cart-content p {
	font-size: 13px;
	color: #707070;
}

.side-cart-content p span {
	color: #0066ff;
}

.side-cart-head {
	display: flex;
	justify-content: left;
	gap: 5px;
	align-items: center;
}

.btn-view-cart {
	padding: 2px 8px;
	font-size: 12px;
	background-color: #d3d3d3;
	color: #fff;
	width: 100%;
	pointer-events: none;
}

.side-cart-head span {
	line-height: 2px;
}

.new-nav-bar-client li.nav-item a.nav-link .menuIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border-radius: 100%;
	width: 15px;
}

.new-nav-bar-client.collapsed .collapse-tag {
	left: calc(100% + 5px) !important;
}

.new-nav-bar-client .currency-div .collapse-tag {
	position: absolute;
	left: calc(100% + 20px);
	top: 20%;
	transform: translateY(-50%);
	background-color: #d9d9d9;
	height: 30px;
	display: flex;
	align-items: center;
	padding: 3px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	cursor: pointer;
	z-index: 5;
}

.hamburger-menu {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 2rem;
	height: 2rem;
	cursor: pointer;
	transition: all 0.3s linear;

	.line-burg {
		width: 2rem;
		height: 0.15rem;
		border-radius: 10x;
		background: #333;
		transition: all 0.3s linear;
	}

	&.active {
		.line-burg {
			background: #0066ff; // Changes the color to #0066FF when active
		}
		.line-burg:nth-child(1) {
			transform: rotate(45deg) translate(0.5rem, 0.5rem);
		}

		.line-burg:nth-child(2) {
			opacity: 0;
		}

		.line-burg:nth-child(3) {
			transform: rotate(-45deg) translate(0.5rem, -0.5rem);
		}
	}
}
.white-tooltip .tooltip-inner {
	background-color: #ffffffcf;
	color: black; /* Change the text color if needed */
	padding: 10px;

	@media screen and (max-width: 800px) {
		background-color: #ffffff !important;
		padding: 10px;
	}
}

.currency-container {
	background-color: #f2f2f2;
	border-radius: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 80%;
	padding: 3px;
}

.currency-select {
	padding: 3px 25px;
	color: black;
	text-decoration: none;
}

.currency-select.active {
	background-color: #0066ff;
	border-radius: 20px;
	color: white;
	text-decoration: none;
}

.product-filter-mobile {
	display: flex;
	align-items: center;
	opacity: 1;
	transition: opacity 0.3s ease;
}

.product-filter-fade {
	opacity: 0;
	transition: opacity 0.3s ease;
}

.product-filter-mobile-option {
	padding: 7px 11px;
	background-color: #eeeeee;
	border-radius: 10px;
	margin-right: 5px;

	align-items: center;
	width: auto; // expand to fit content
	transition: width 0.3s ease, opacity 1.3s ease, background-color 0.3s ease;
	cursor: pointer;
}

.product-filter-mobile-option:not(.active) {
	width: 35px; // specific width when not active
	height: 34px;
}

.product-filter-mobile-option.active {
	display: flex;
	width: 115px;
	color: #ffffff;
	fill: #ffffff;
	background-color: #0066ff;
}

.product-filter-mobile-currency {
	padding: 7px 8px;
	background-color: #eeeeee;
	border-radius: 10px;

	align-items: center;
	width: auto; // expand to fit content

	cursor: pointer;
}

.product-filter-mobile-currency:not(.active) {
	width: 35px; // specific width when not active
	height: 34px;
}

.product-filter-mobile-currency.active {
	color: #ffffff;
	fill: #ffffff;
	background-color: #0066ff;
	width: 35px; // specific width when not active
	height: 34px;
}

.product-filter-mobile-option a,
.product-filter-mobile-option .hidden-option {
	margin-left: 5px;
	white-space: nowrap;
	opacity: 0;
	transition: opacity 0s ease;
	transition-delay: 0s; // hides fast
}

.product-filter-mobile-option.active a,
.product-filter-mobile-option.active .hidden-option {
	opacity: 1;
	transition: opacity 1.4s ease; // shows slow
	transition-delay: 0s;
}

.product-filter-mobile.option a {
	pointer-events: none;
}

.tag {
	padding: 1px 6px;
	border-radius: 20px;
	border: 1px solid black;
}

.tag.active {
	border: 1px solid #0066ff;
	background-color: #0066ff;
	color: white !important;
}

.clear-filter:hover {
	background-color: #0066ff;
	color: white !important;
	border: 1px solid #0066ff;
	& a {
		color: white !important;
	}
}

.borderRadiusStyle {
	border-radius: 10px !important;
}

div.borderRadiusStyle {
	border-radius: 10px !important;
}

.PhoneInputCountry {
	border-radius: 10px !important;
}

.PhoneInputInput {
	border-radius: 10px !important;
}

.sign-up-phone-input .PhoneInputCountry,
.sign-up-phone-input .PhoneInputInput {
	border-radius: 10px !important;
	border-color: #0066ff !important;
}

@media (max-width: 767px) {
  .feature-list-dashboard {
    margin-bottom: 30px; /* Adjust the value as needed */
  }
}
.gradient-text {
  background: transparent linear-gradient(270deg, #f39c12 10%, #d8578a 50%, #795aaa 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
}