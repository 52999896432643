@import '../../../assets/scss/colors_new';

.pay-purchase-inv-modal-dialog {
	.modal-dialog {
		max-width: 300px;
		margin: 1.75rem auto;
		text-align: center;

		.modal-title {
			display: flex;
			flex-direction: column;
			// gap: 5px;

			span {
				font-size: 50px;
			}
		}

		.modal-body {
			display: flex;
			flex-direction: column;

			gap: 10px;

			p.message {
				margin-bottom: 15px;
			}

			ul.products {
				font-size: 1rem;
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 15px;
			}
		}

		.modal-actions {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		input {
			box-shadow: none !important;
			border-color: #d1d3e2 !important;
			
			&.error-input {
				border-color: #F46B5B !important;
			}
		}

		.btn-confirm {
			border-color: $color-primary;
			color: $color-primary;
			background-color: $color-light-max;

			&:hover {
				border-color: $color-primary;
				background-color: $color-primary;
				color: $color-light-max;
			}

			&:focus {
				border-color: $color-primary;
				background-color: $color-primary;
				color: $color-light-max;
			}

			&.danger {
				border-color: red !important;
				color: red !important;

				&:focus {
					border-color: red !important;
					color: red !important;
				}
			}
		}
	}
}
