@import '../../assets/scss/colors';

.global-search-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    z-index: 3000;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(107, 122, 144, 0.2);
    display: flex;
    justify-content: center;
    padding-top: 7%;

    .g-search-container {
        width: 100%;
        max-width: 700px;
        border: 0.5px solid $color-scale-gray-2;
        height: fit-content;
        border-radius: 5px;
        overflow: hidden;

        .gs-input-group {
            display: flex;
            background-color: #fff;
            color: $color-light-scale-gray-9;
            gap: 20px;

            .icon {
                padding: 10px 0;
                padding-left: 20px;
                font-size: 20px;
            }

            input {
                background: transparent;
                border: none;
                outline: none;
                box-shadow: none;
                color: $color-light-scale-gray-9;
                padding: 10px 0;
                padding-right: 20px;
                font-size: 16px;
                width: 100%;
            }
        }

        .search-results {
            background-color: #fff;
            border-top: 0.5px solid $color-scale-gray-2;
            padding: 25px;

            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 20px;

            .search-result-group {
                flex: 48%;
                .sr-title {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: $color-light-scale-gray-9;
                    font-size: 15px;
                }

                .srg-items {
                    display: flex;
                    flex-direction: column;
                    padding-left: 20px;
                    gap: 5px;
                    font-size: 1rem;

                    .srg-item {
                        text-decoration: none;
                        color: $color-light-scale-gray-8;
                        cursor: pointer;

                        &:hover {
                            svg {
                                margin-left: 5px;
                                transition: margin 0.3s ease-out;
                            }
                        }

                    }
                }
            }
        }
    }
}
