.create-contact-container {
	display: flex;
	justify-content: center;

	.card {
		padding: 20px;
		margin-top: 20px;
		min-width: 768px;
		max-width: 768px;
		color: #000;
		font-size: 1rem;
	}

	.form-section-head {
		padding-bottom: 10px;
		border-bottom: 0.5px solid #e8e8ed;
		margin-bottom: 10px;

		h4 {
			font-size: 13px;
			margin: 0;
		}
	}

	.form-group {
		padding-left: 40px;
		margin-bottom: 5px;

		input,
		select:not(.PhoneInputCountrySelect),
		.rs-container,
		.vatCheck,
		.PhoneInput {
			width: 240px;
			color: #000 !important;
		}

		label {
			margin: 0;
			color: #000;
		}

		.form-group-body {
			display: grid;
			grid-template-columns: 20% 80%;
			align-items: center;
		}

		.form-group-error {
			padding-left: 20%;

			.error-message {
				font-size: 10px;
				position: relative;
			}
		}
	}
}

.action-appprove-reject {
	display: flex;
	gap: 5px;

}