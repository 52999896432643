.card {
	box-shadow: #e7e5e5 0px 0px 20px 0px;
	border-radius: 15px !important;
}

.card-header {
	border-radius: 15px !important;
	border: none;
}

.card-header-custom {
	padding: 10px !important;
	padding-bottom: 0 !important;
}

.card-body-custom {
	padding: 13px !important;
}

.x {
	color: #bbebc2;
}

.chart-labels {
	display: flex;
	justify-content: space-around;
	padding: 10px;
	padding-bottom: 20px;
}

.target-custom a {
	font-size: 13px !important;
	display: block;
	font-weight: 500 !important;
}

.target-custom b {
	font-size: 18px !important;
	display: block;
	font-weight: 600 !important;
}

.chart-label {
	display: flex;
	align-items: center;
}

.label-color {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 3px;
	margin-right: 5px;
}

.label-text {
	display: inline-block;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fade-in {
	animation: fadeIn 2s;
}

.winningProfit {
	padding-top: 20px;
	padding-left: 10px;
	padding-bottom: 10px;
}

.winningProfit h2 {
	font-weight: 500;
	color: #0166ff;
	font-size: 1.8rem;
}

.winningProfit a {
	color: #000000 !important;
	display: inline-block;
	font-weight: 200;
	padding-top: 3px !important;
	padding-bottom: 3px !important;
	font-size: 13px;
}

.card-special h5,
.card-special a {
	color: #000000 !important;
}

.pie-chart-custom {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.pie-chart-custom svg {
	width: 80% !important;
	height: 100% !important;
}

.recharts-wrapper {
	text-align: center !important;
}

//card flip
.chart-card {
	perspective: 1000px;
}

.card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: left;
	transition: transform 0.8s;
	transform-style: preserve-3d;
}

.card-flipped .card-inner {
	transform: rotateX(180deg);
}

.card-front,
.card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
}

.custom-card-body {
	padding-top: 10px !important;
}

.card-back {
	transform: rotateX(180deg);
	background-color: rgb(25, 106, 255);
	border-radius: 20px;
}

.card-back-special a {
	color: white !important;
}

.card-back-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
}

.comparison-card {
	padding-top: 20px;
}

.comparison-card h2,
.comparison-card h3,
.comparison-card a {
	color: white !important;
}

div.comparison-card a {
	padding: 5px !important;
	display: inline-block;
	font-size: 13px;
	font-weight: 300;
}

.comparison-card h3 {
	font-weight: 300;
	font-size: 1.4rem;
	color: #6dda52;
}

.chart-list a {
	color: rgb(0, 0, 0) !important;
	font-size: 13px;
	font-weight: 500;
}

.chart-list-one a {
	color: rgb(0, 0, 0) !important;
	font-size: 13px;
	font-weight: 300;
}

.progress-container label {
	display: block;
	margin-bottom: 5px;
}

.progress-bar-container {
	display: flex;
	align-items: center;
	border-radius: 100px;
	justify-content: space-between;
	background-color: #eee; // Add a background color to the progress bar container
	height: 20px; // Add a height to make the progress bar visible
}

.sales-bar {
	background-color: #fdd627; // Add a color to the progress bar
	height: 100%;
	border-radius: 100px;
}

.profit-bar {
	background-color: #6dda52; // Add a color to the progress bar
	height: 100%;
	border-radius: 100px;
}

.commission-bar {
	background-color: #e25151; // Add a color to the progress bar
	height: 100%;
	border-radius: 100px;
}

.progress-container h3 {
	font-size: 20px;
	font-weight: 400;
	color: rgb(112, 112, 112);
}

.progress-bar-value a {
	color: rgb(112, 112, 112) !important;
	font-size: 20px;
	font-weight: 500;
}

.progress-bar-value {
	padding-left: 0 !important;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.numbers-sales a {
	font-size: 28px !important;
	color: #f0cd34 !important;
}

.numbers-profit a {
	font-size: 28px !important;
	color: #6dda52 !important;
}

.numbers-commission a {
	font-size: 28px !important;
	color: #ee4747 !important;
}

.numbers {
	padding-left: 30px;
}

@media (min-width: 992px) {
	.custom-col-lg-4 {
		flex: 0 0 33.6%;
		max-width: 33.6%;
	}

	.custom-col-lg-3 {
		flex: 0 0 24%;
		max-width: 24%;
	}

	.custom-col-lg-2 {
		flex: 0 0 17.6666%;
		max-width: 17.6666%;
	}

	.custom-col-lg-3-5 {
		flex: 0 0 24.55%;
		max-width: 24.55%;
	}

	.custom-col-lg-8 {
		flex: 0 0 66.66%;
		max-width: 70%;
	}

	.custom-col-lg-1 {
		flex: 0 0 16.666%;
		max-width: 16.666%;
	}

	.custom-country-col {
		flex: 0 0 40%;
		max-width: 40%;
	}

	.custom-supplier-col {
		flex: 0 0 26.66%;
		max-width: 26.66%;
	}

	.custom-gross-profit {
		flex: 0 0 20%;
		max-width: 20%;
	}

	.custom-sales-target {
		flex: 0 0 21.55%;
		max-width: 21.55%;
	}

	.col-performance {
		flex: 0 0 27%;
		max-width: 27%;
	}

	.col-returns {
		flex: 0 0 23%;
		max-width: 23%;
	}

	.col-inventory {
		flex: 0 0 23%;
		max-width: 23%;
	}
}

.custom-col-lg-4,
.custom-col-lg-3,
.custom-col-lg-3-5,
.custom-col-lg-2,
.custom-country-col,
.custom-supplier-col,
.custom-contact-col,
.custom-gross-profit,
.custom-sales-target,
.col-performance,
.col-returns,
.col-inventory {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}

.estimated-commission h2 {
	font-weight: 500;
	color: #000000;
	font-size: 1.8rem;
	margin-bottom: 0 !important;
}

.estimated-commission a {
	color: rgb(124, 124, 124) !important;
	font-size: 13px;
}

.global-time {
	padding-left: 15px;
}

.global-time a {
	color: rgb(153, 152, 152) !important;
}

.global-time b {
	color: #999898 !important;
	padding-left: 10px;
	padding-right: 10px;
}

.custom-card-body {
	padding: 5px !important;
	color: #bbbbbb;
}

.custom-form-group {
	margin-top: 20px;
	margin-bottom: 20px;
}

.custom-form-group label {
	font-size: 12px;
	font-weight: 300;
	color: #2c2c2c;
}

.form-control.custom-form {
	background-image: none !important;
}

//change form-group label
.mini-widgets-sold h5 {
	font-weight: 600;
	font-size: 18px;
	margin-bottom: -5px;
}

.mini-widgets-sold b {
	font-weight: 400;
	font-size: 12px;
}

.mini-widgets-profit h5 {
	font-weight: 600;
}

.mini-widgets-profit b {
	font-weight: 400;
}

.mini-widgets-credit h5 {
	font-weight: 600;
}

.mini-widgets-credit b {
	font-weight: 400;
}

.contact-icon {
	border-radius: 50%;
	border: 2px solid rgb(255, 255, 255);
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact-icon span {
	font-size: 13px;
}

.contact-icon-admin {
	border-radius: 50%;
	border: 2px solid rgb(255, 255, 255);
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact-icon-admin span {
	font-size: 11px;
}

.mini-body {
	padding: 12px;
}

@media (max-width: 992px) {

	/* Medium devices (tablets, less than 992px) */
	.mini-body {
		padding-left: 40px;
		padding-right: 40px;
	}
}

@media (max-width: 576px) {

	/* Small devices (landscape phones, less than 576px) */
	.mini-body {
		padding-left: 13%;
		padding-right: 20px;
	}
}

@media (max-width: 650px) {

	/* Small devices (landscape phones, less than 576px) */
	.multiple-times {
		display: none;
	}
}

.graph-selector-line,
.graph-selector-bar {
	background-color: #ebeaea;
	color: #747474;
	border-radius: 8px;
	padding: 6px;
}

.graph-selector-line.active,
.graph-selector-bar.active {
	color: #008cff !important;
}

.graph-selector-line {
	margin-right: 5px;
}

.custom-form {
	width: 90px !important;
}

.custom-form-one {
	width: 100px !important;
}

.targetDropdown {
	padding: 15px;
	border-radius: 10px;
}

.targetDropdown a {
	padding-bottom: 10px;
}

.target-button {
	background-color: #838383;
	padding: 5px;
	border-radius: 7px;
}

.target-selector {
	color: #908e97;
	font-size: 10px !important;
	text-decoration: 'none' !important;
}

/* styles.css */

.icon-container {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-container.green {
	background-color: #e8f7f4;
}

.icon-container.red {
	background-color: #ffe0e0;
}

.icon.green {
	color: #4acd8a;
}

.icon.red {
	color: #ff1313;
}

.graph-label-div {
	padding: 0 !important;
	margin: 0 !important;
}

.graph-label-div p {
	padding: 0 !important;
	margin: 0 !important;
}

//.sticky-row {

//    position: sticky;
//  top: 20px;
//    z-index: 1000;
//}

.month-selector a {
	padding-right: 12px;
	font-weight: 500;
}

.month-selector {
	padding-left: 20px;
	padding-bottom: 10px;
}

.row-selector {
	position: relative;
	margin-bottom: 10px !important;

	@media (min-width: 768px) {
		// Medium devices (tablets, 768px and up)
		margin-bottom: 10px !important;
	}

	@media (min-width: 992px) {
		// Large devices (desktops, 992px and up)
		margin-bottom: 20px !important;
	}

	&::after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		width: 97%; // Adjust this to change the length of the border
		border-bottom: 1px solid #cfcfcf;
	}
}

.widget-selector {
	margin-right: 25px;
}

.widget-selector a {
	padding-left: 20px;
	font-weight: 500;
}

.stat-selector {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 260px;
	background-color: #f1f1f1;
	border-radius: 20px;
}

@media (max-width: 600px) {
	.stat-selector {
		overflow-x: auto;
		width: 100%;
	}

	.stat-selector__item {
		min-width: 50px;
		/* replace 50px with the desired minimum width for mobile view */
		font-size: 13px;
		/* replace 12px with the desired font size for mobile view */
	}
}

.stat-selector__item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35%;
	font-size: 13px;
	padding: 7px 15px;
	transition: transform 0.3s ease;
}

.stat-selector__item.active {
	background-color: #0059ff;
	color: #ffffff;
	border-radius: 20px;
	font-weight: 300;
	transform: scaleX(1.1);
}

.stats-date-custom {
	margin-top: 0px !important;
}

.revenue-stats {
	margin-top: 20px;
	margin-bottom: 10px;
}

.stats-date {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: #f1f1f1;
	border-radius: 20px;

	&>div {
		display: flex;
		flex-direction: row;
		overflow-x: auto;
		scrollbar-width: none;
		/* For Firefox */
		-ms-overflow-style: none;
		/* For Internet Explorer and Edge */

		&::-webkit-scrollbar {
			/* For Chrome, Safari and Opera */
			display: none;
		}
	}
}

.stats-date button {
	position: sticky;
	z-index: 1;
	border: none;
	padding: 5px 15px;
	background-color: transparent;
}

.stats-date button:first-child {
	left: 0;
}

.stats-date button:last-child {
	right: 0;
}

@media (max-width: 600px) {
	.stats-date__item {
		flex-shrink: 0;
	}
}

.stats-date__item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10%;
	font-size: 13px;
	padding: 5px 5px;
	transition: transform 0.3s ease;
	flex-shrink: 0;
	margin-right: 10px;

	&:hover {
		cursor: pointer;
		background-color: #e4e4e4;
		border-radius: 20px;
	}

	&:first-child {
		margin-right: 10px;
	}

	&:last-child {
		margin-left: 10px;
	}
}

.stats-date__item.active {
	background-color: #0059ff;
	color: white;
	border-radius: 20px;
	transform: scaleX(1.1);
}

.custom-tooltip-chart {
	background-color: white !important;
	box-shadow: 0px 0px 20px 0px #e7e5e5;
	border-radius: 8px;
	width: 240px;
	text-align: left;
	padding: 20px;
}

.custom-tooltip-revenue {
	background-color: white !important;
	box-shadow: 0px 0px 20px 0px #e7e5e5;
	border-radius: 8px;
	width: 150px;
	text-align: center;
	padding: 20px;
}

.custom-tooltip-revenue p {
	margin: 0;
	padding: 0 !important;
}

.chart-row {
	padding-left: 10px;
	padding-right: 20px;
}

.date-label {
	font-weight: 500;
}

.custom-tooltip-revenue {
	background-color: white !important;
	margin-left: 20px !important;
}

.total-chart {
	padding-top: 20px;
	font-size: 13px;
}

.total-chart p {
	margin: 0 !important;
	font-size: 16px;
	font-weight: 600;
}

.open-suppliers {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* Align items to the center of the cross axis */
	padding-bottom: 13px;
}

.open-suppliers-name {
	margin-bottom: auto;
	max-width: 60%;
	font-size: 13px;
	/* Push the name up */
}

.open-suppliers-value {
	margin-top: auto;
	/* Push the dollar amount down */
	font-size: 13px;
	font-weight: 600;
}

a.multiple-times:hover {
	text-decoration: none;
}

.recharts-cartesian-axis-tick:hover .tick-text {
	opacity: 1;
}

.stat-chart {
	width: 100%;
}

//sales target
.sales-target-table {
	width: 100%;
	border-collapse: collapse; // Add this to handle borders correctly
}

.sales-target-table th,
.sales-target-table td {
	padding: 10px;
	border: 1px solid #000;
	font-size: 13px;
}

.sales-target-table .revenue,
.sales-target-table .profit {
	width: 25%;
	font-weight: 500;
	/* adjust as needed */
}

.sales-target-table .percentage {
	width: 20%;
	font-weight: 500;
	/* adjust as needed */
}

.custom-th {
	background-color: black;
	color: white;
}

.month-table {
	font-weight: 600;
}

.percentage {
	background-color: #ececec;
	/* Light grey */
}

.configure-body {
	display: flex;
	justify-content: left;
	margin-bottom: 20px;
}

.configure-by {
	width: 40%;
}

.sales-person {
	padding-top: 5px !important;
}

.input-custom {
	border: none;
	outline: none;
}

.input-custom:hover {
	border: 1px solid #ccc;
}

.input-custom:focus {
	border: 1px solid #007bff;
}

.top-clients a {
	font-size: 13px;
}

.modal-enter {
	opacity: 0;
	transform: scale(0.9);
}

.modal-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}

.modal-exit {
	opacity: 1;
}

.modal-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

@keyframes sketchIn {
	0% {
		opacity: 0;
		transform: perspective(600px) rotateX(90deg);
	}

	100% {
		opacity: 1;
		transform: perspective(600px) rotateX(0deg);
	}
}

@keyframes sketchOut {
	0% {
		opacity: 1;
		transform: perspective(600px) rotateX(0deg);
	}

	100% {
		opacity: 0;
		transform: perspective(600px) rotateX(90deg);
	}
}

.sketch-enter {
	opacity: 0;
	transform: perspective(600px) rotateX(90deg);
}

.sketch-enter-active {
	opacity: 1;
	transform: perspective(600px) rotateX(0deg);
	transition: opacity 500ms, transform 500ms;
}

.sketch-exit {
	opacity: 1;
}

.sketch-exit-active {
	opacity: 0;
	transform: perspective(600px) rotateX(90deg);
	transition: opacity 500ms, transform 500ms;
}

.estimated-card a,
.estimated-card b,
.estimated-card c {
	display: block;
}

.current-inventory a,
.current-inventory b,
.current-inventory c {
	display: block;
}

.grid-container-custom {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 10px;
	row-gap: 20px;
	align-items: center;
	justify-items: center;
}

.circle-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 50px;
	height: 50px;
}

.circle {
	position: absolute;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background: #000;
}

.circular-chart {
	position: absolute;
	width: 60px;
	height: 60px;
	transform: rotate(180);
}

.circle-bg {
	fill: none;
	stroke: #ffffff;
	stroke-width: 2;
}

.circle {
	fill: none;
	stroke-width: 2;
	stroke-linecap: round;
}

.contact-col {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%; // or set a specific height
}

.contact-circle {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background: #d0d0d0;
	color: white;
	/* Add your other styles here */
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes marquee {
	0% {
		text-indent: 100%;
	}

	100% {
		text-indent: -100%;
	}
}

.small-dropdown {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border: none;
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}

	50%,
	100% {
		opacity: 0;
	}
}

@keyframes fade-in {

	0%,
	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 992px) {

	/* This will apply to tablets and mobile devices */
	.sticky-row {
		position: sticky;
		top: 0;
		z-index: 1;
		/* Adjust this value to control where the element sticks */
	}
}

.hover-effect {
	transition: background-color 0.3s ease, color 0.3s ease;
	border-radius: 10px;
}

.hover-effect:hover {
	background-color: #045aff;
	/* Change this to the color you want */
	cursor: pointer;
	color: white;
	border-radius: 10px;
}

.custom-no-scroll::-webkit-scrollbar {
	display: none;
}

.custom-no-scroll {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.high-z-index {
	z-index: 10;
	/* Adjust this value as needed */
}

.dropdown-menu-left {
	right: 100%;
	left: auto;
	margin-right: -1px;
}

.dropdown-menu-left.custom-inset {
	inset: 0px auto auto -115px !important;
}