//  Top Margins

.mt0 { margin-top: 0 !important; }

.mt2 { margin-top: 2px; }
.mt3 { margin-top: 3px; }
.mt5 { margin-top: 5px !important; }
.mt6 { margin-top: 6px !important; }
.mt8 { margin-top: 8px; }

.mt10 { margin-top: 10px; }
.mt12 { margin-top: 12px !important; }
.mt14 { margin-top: 14px !important; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px !important; }

.mt-5 { margin-top: -5px !important; }
.mt-8 { margin-top: -8px; }
.mt-16 { margin-top: -16px !important; }
.mt-19 { margin-top: -19px !important; }
.mt-20 { margin-top: 20px !important; }

//  Botom margins

.mb8 { margin-bottom: 8px; }
.mb10 { margin-bottom: 10px !important; }
.mb-5 { margin-bottom: -5px !important; }
.mb-10 { margin-bottom: -10px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb50 { margin-bottom: 50px !important; }





//  Left margins

.ml-15 { margin-left: -15px !important; }
.ml-16 { margin-left: -16px !important; }
.ml6 { margin-left: 6px !important; }
.ml15 { margin-left: 15px !important; }
.ml27 { margin-left: 27px !important; }
.ml23 { margin-left: 23px !important; }



//  Right margins
.mr0 { margin-right: 0 !important; }
.mr5 { margin-right: 5px !important; }
.mr15 { margin-right: 15px !important; }
.mr23 { margin-right: 23px !important; }
.mr-20 { margin-right: -20px; }

.mar1020 { margin: 10px 20px 10px 16px; }

.mar102000 { margin: 10px 20px 0px 0px; }
.mar10201316 { margin: 10px 20px 13px 16px; }



.pl-20 {
    padding-left: 20px;
}