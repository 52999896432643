.delete-confirmation-dialog {
	.modal-dialog {
		max-width: 300px;
		margin: 1.75rem auto;
		text-align: center;

		.modal-title {
			display: flex;
			flex-direction: column;
			// gap: 5px;

			span {
				font-size: 50px;
			}
		}

		.modal-body {
			display: flex;
			flex-direction: column;

			gap: 10px;
		}

		.modal-actions {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		.btn-dlt {
			border-color: red;
			color: red;
			background-color: #fff !important;

			&:hover {
				background-color: red !important;
				color: white;
			}

			&:active, 
			&:focus {
				background-color: red !important;
				color: white;
			}
		}
	}
}
