@import '../../../assets/scss/colors';

.process-status-indicator-container-client {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-bottom: 1rem;

	width: 100%;
	height: 100%;
	overflow: auto;

	@media screen and (max-width: 768px) {
		padding-bottom: 0;
	}
}
.name-indicator {
	position: absolute;
	top: 2rem;
	// left: -2rem;
	font-size: 11px;
	width: -moz-fit-content;
	width: fit-content;
	overflow: auto;
	text-wrap: nowrap;

	@media screen and (max-width: 1027px) {
		display: none;
	}
}

.process-status-indicator {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 600;
	width: 90% !important;
	height: 69px;
	color: $color-light-scale-gray-3;

	.process-connector-line {
		width: 100%;
		flex: 100%;
		height: 6px;
		background: #191919;
	}

	.process {
		display: flex;
		flex-direction: column;
		flex: auto;
		align-items: center;
		gap: 10px;
		position: relative;

		.indicator-check {
			border: $color-light-scale-gray-3 1px solid;
			color: $color-light-scale-gray-3;
			border-radius: 100px;
			width: fit-content;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				display: flex;
			}
		}

		&.completed {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: #fff;
				background-color: $color-primary-2;

				span {
					opacity: 1;
				}
			}

			& ~ div.process-connector-line {
				background: #34c880;
			}
		}

		&:not(.completed) ~ div.process-connector-line {
			background: #e2e2e2;
		}

		&.current {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: $color-primary-2;
			}

			&.inprogress .indicator-check {
				span {
					opacity: 1;
				}
			}
		}
	}
}

.process-status-indicator-mobile {
	display: flex;
	align-items: left;
	justify-content: left;
	font-size: 12px;
	font-weight: 600;

	color: $color-light-scale-gray-3;

	.process-connector-line {
		width: 5px;
		flex: 0 1 auto;
		margin-right: 21px;
		margin-left: 10px;
		background: #e2e2e2;
	}

	.process {
		display: flex;
		flex: auto;
		align-items: center;
		gap: 10px;
		position: relative;

		.indicator-check {
			border: $color-light-scale-gray-3 1px solid;
			color: $color-light-scale-gray-3;
			border-radius: 100px;
			width: fit-content;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				display: flex;
			}
		}

		&.completed {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: #fff;
				background-color: $color-primary-2;

				span {
					opacity: 1;
				}
			}

			.process-detail > .process-connector-line {
				background: #34c880 !important;
			}
		}

		&:not(.completed) .process-detail .process-connector-line {
			background: #e2e2e2;
		}

		&.current {
			color: $color-primary-2;

			.indicator-check {
				border: $color-primary-2 1px solid;
				color: $color-primary-2;
			}

			&.inprogress .indicator-check {
				span {
					opacity: 1;
				}
			}
		}
	}
}

.process-detail .completed {
	.process-connector-line {
		background: #34c880;
	}
}

.process-connector-line.completed {
	background: #34c880 !important;
}

.process-detail {
	display: flex;
}

.process-detail .process-connector-line {
	padding: 1px;
}
