// @import '../../assets/scss/colors';
@import '../../assets/scss/colors_new';

.filter-bg {
	position: fixed;
	width: 100dvw;
	height: 100dvh;
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	background-color: rgba(107, 122, 144, 0.2);
	top: 0;
	left: 0;
	z-index: 11;
}

.filter-container {
	position: relative;
	z-index: 12;

	.filter-buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		button {
			border: 1px solid $color-borders-3 !important;
			color: $color-borders-3 !important;
			border-radius: 10px;
			background-color: $color-light-max !important;

			&:active {
				background-color: $color-light-max !important;
			}
		}

		.selected-filter-pill {
			border: 1px solid $color-borders-3;
			color: $color-text-2;
			height: 100%;
			padding: 5px 10px;
			border-radius: 10px;
			font-size: 10px;
			display: flex;
			gap: 5px;
			justify-content: center;
			align-items: center;

			span {
				font-size: 1rem;
			}
		}
	}

	.filter-menu {
		position: absolute;
		top: calc(100% + 5px);
		z-index: 5;
		background: #fff;
		padding: 10px;
		border: 1px solid $color-borders-2;

		font-size: 1rem;
		animation: slideIn 0.3s ease-out;
		border-radius: 10px;

		p {
			color: $color-text-1;
		}

		.filter-content {
			display: flex;
			gap: 10px;
		}

		.filter-children {
			margin-top: 10px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			min-width: 250px;
		}

		.filter-submit {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			margin-top: 10px;

			// button {
			// 	padding: 5px 15px;
			// 	border-radius: 10px;
			// 	color: #fff;
			// 	font-size: 1rem;
			// 	background-color: $color-primary;
			// 	border: none !important;

			// 	&:hover {
			// 		background-color: darken($color: $color-primary, $amount: 5);
			// 	}
			// }
		}

		margin-bottom: 50px;
	}
}

@keyframes slideIn {
	0% {
		scale: 1;
	}

	50% {
		scale: 1.01;
	}

	100% {
		scale: 1;
	}
}

@media only screen and (max-width: 650px) {
	.filter-content {
		flex-direction: column;
	}

	.TableSerachBar {
		gap: 5px;
	}

	.filter-buttons {
		align-items: flex-start !important;
	}

	.filter-btn {
		margin: 0 !important;
	}

	.filter-container {
		position: static;
	}

	.filter-menu {
		z-index: 52 !important;
		position: fixed !important;
		top: 50% !important;
		left: 50% !important;
		transform: translateY(-50%) translateX(-50%) !important;
		max-height: 98dvh;
		overflow: scroll;
	}
}
