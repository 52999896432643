.badge {
    display: inline-block;
    padding: .50em .4em;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 7px;
    // border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    min-width: 70px;
}

.badge2 {
    display: inline-block;
    padding: .50em .4em;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding-left: 10px;
    padding-right: 10px;
}

.badge-warning {
    color: #1f2d3d;
    background-color: #ffc107;
}

.badge-warning.disabled {
    color: #1f2d3d;
    background-color: #ffd677;
}

.badge-dark-warning {
    color: #fff;
    background-color: #c49301;
}

.badge-dark-warning.disabled {
    color: #fff;
    background-color: #d5a208;
}

.badge-success {
    color: #fff;
    background-color: #34c880;
}

.badge-success.disabled {
    color: #fff;
    background-color: #7fc688;
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

.badge-danger.disabled {
    color: #fff;
    background-color: #eb7f87;
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.badge-info.disabled {
    color: #fff;
    background-color: #79c3d1;
}

.badge-light-gray {
    color: #fff;
    background-color: #6c757d;
}

.badge-light-gray.disabled {
    color: #fff;
    background-color: #a0a5aa;
}

.badge-light-gray2 {
    color: #000;
    background-color: #e3e6eb;
}

.badge-secondary {
    color: #fff;
    background-color: #707070;
}

.badge-secondary.disabled {
    color: #fff;
    background-color: #a0a5aa;
}

.badge-dark-gray {
    color: #fff;
    background-color: #343a3f;
}

.badge-dark-gray.disabled {
    color: #fff;
    background-color: #7b7f82;
}

.badge-primary {
    color: #fff;
    background-color: #007bff !important;
}

.badge-primary.disabled {
    color: #fff;
    background-color: #6fa9ff !important;
}

.badge-black {
    background-color: #000;
    color: #fff !important;
}

.badge-black.disabled {
    background-color: #595959;
    color: #fff !important;
}

.badge-orange {
    color: #fff !important;
    background-color: #c28100;
}

.bg-orange.disabled {
    background-color: #e49800;
}

.badge-light-orange {
    color: #fff !important;
    background-color: #F49C12;
}

.bg-light-orange.disabled {
    background-color: #f89b3d;
}
.inline-small-badge {
    .badge {
        font-size: 11px !important;
    }
}