.timeline {
    display: flex;
    position: relative;
    padding-top: .5rem
}

.timeline--success {
    --timeline-line-background: var(--g300);
    --timeline-outerdot-background: rgba(var(--g500-rgb), .1);
    --timeline-outerdot-border: rgba(var(--g500-rgb), .3);
    --timeline-innerdot-background: var(--g500)
}

.timeline--danger {
    --timeline-line-background: #ffbdbd;
    --timeline-outerdot-background: rgba(179, 0, 0, .1);
    --timeline-outerdot-border: rgba(179, 0, 0, .3);
    --timeline-innerdot-background: #b30000;

    .timeline__active-line {
        display: flex;
        height: .5rem;
        position: absolute;
        left: 0;
        align-items: center;
        justify-content: end;
        // background-color: #e8e8ed;
        background-color: #ffbdbd;
        border-radius: 4px
    }
}

.timeline--warning {
    --timeline-line-background: #ffdf9e;
    --timeline-outerdot-background: rgba(158, 105, 0, .1);
    --timeline-outerdot-border: rgba(158, 105, 0, .3);
    --timeline-innerdot-background: #9e6900;

    .timeline__active-line {
        display: flex;
        height: .5rem;
        position: absolute;
        left: 0;
        align-items: center;
        justify-content: end;
        // background-color: #e8e8ed;
        background-color: #ffdf9e;
        border-radius: 4px
    }
}

.timeline__step {
    height: .5rem;
    background-color: #e8e8ed;
}

.timeline__step:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.timeline__step:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.timeline__text {
    width: 2px;
    display: flex;
    padding-top: 1rem;
    justify-content: center;
    z-index: 1;
    position: relative
}

.timeline__text:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: .5rem;
    background-color: var(--app-background)
}

.timeline__text:first-of-type {
    width: 0;
    justify-content: start
}

.timeline__text:last-of-type {
    width: 0;
    justify-content: end
}

.timeline__active-line--0,
.timeline__active-line--100 {
    padding: 0 5px
}

.timeline__active-dot {
    z-index: 1;
    margin-right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 20px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--timeline-outerdot-background);
    box-shadow: 0 0 0 1px var(--timeline-outerdot-border)
}

.timeline__active-dot:before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--timeline-innerdot-background);
    display: block;
    content: ""
}



.timeline-accordion {
    // margin: 5px 0px 10px 0px;
    margin: -5px 0px 10px 0px;
    
    .accordion {
        box-shadow: 0px 0px 0px 1px #e0e0e0;
        border-radius: 12px;
        .accordion-item {
            border-radius: 12px;
            background: #fff;
            .open {
                &.accordion-header {
                    button {
                        width: 100%;
                        text-align: left;
                        border: 0;
                        padding: 10px;
                        font-size: 16px;
                        border-radius: 12px; /* Border radius for all corners when closed */
                        background: #fff;
                    }
                }
            }
            .closed.accordion-header {
                    button {
                        width: 100%;
                        text-align: left;
                        border: 0;
                        padding: 10px;
                        font-size: 16px;
                        border-radius: 12px 12px 0 0; /* Border radius for top corners when open */
                        background: #fff;
                    }
                }
            // .accordion-header {

            //     &.closed {
            //         border-radius: 12px; /* Border radius for all corners when closed */
            //         button {
            //             width: 100%;
            //             text-align: left;
            //             border: 0;
            //             padding: 10px;
            //             font-size: 16px;
            //         }
            //     }

            //     &.open {
            //         border-radius: 12px 12px 0 0; /* Border radius for top corners when open */
            //         button {
            //             width: 100%;
            //             text-align: left;
            //             border: 0;
            //             padding: 10px;
            //             font-size: 16px;
            //         }
            //     }
            //     // button {
            //     //     width: 100%;
            //     //     text-align: left;
            //     //     border: 0;
            //     //     padding: 10px;
            //     //     font-size: 16px;
            //     //     // border-top-right-radius: 12px;
            //     //     // border-top-left-radius: 12px;
            //     // }
            // }
            .accordion-collapse {
                    .accordion-body {
                        padding: 7px 15px 15px 15px;
                    }
            }
        }
    }
}