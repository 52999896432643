@import '../../../assets/scss/colors';

.payment-taskgrid-container {
	display: grid;
	grid-template-columns: calc(33% - 5px) calc(33% - 5px) calc(33% - 5px);
	gap: 12px;
	padding: 12px;
	justify-content: space-between;

	@media (max-width: 1250px) {
		grid-template-columns: calc(33% - 5px) calc(33% - 5px) calc(33% - 5px);
		gap: 5px;
		row-gap: 10px;
		padding: 10px;
	}

	@media (max-width: 950px) {
		grid-template-columns: calc(50% - 5px) calc(50% - 5px);
		gap: 5px;
		row-gap: 10px;
		padding: 10px;
	}

	@media (max-width: 768px) {
		grid-template-columns: 100%;
		gap: 5px;
		row-gap: 10px;
		padding: 0;
	}

	.task-card {
		padding: 10px;
		border: 1px solid $color-light-scale-gray-2-2;
		border-radius: 5px;
		position: relative;
		color: #000;

		--statusColor: red;

		.task-head {
			display: flex;
			justify-content: space-between;
			align-items: center;

			h6 {
				margin: 0;
				font-size: 13px;
			}

			.task-due {
				font-size: 11px;
				margin: 0;
				// border: 1px solid red;
				color: var(--statusColor);
				border-radius: 4px;
				padding: 1px 3px;
			}
		}

		.task-body {
			padding: 10px 0;

			.task-assignee {
				display: flex;
				align-items: center;
				gap: 5px;
				font-size: 1rem;
			}
		}

		&::before {
			content: '';
			height: 4px;
			border-radius: 0 0 5px 5px;
			top: -1px;
			// transform: translateX(-50%);
			width: 20%;
			position: absolute;
			left: 5%;
			background: var(--statusColor);
		}

		.task-actions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 5px;

			.task-qty {
				display: flex;
				align-items: center;
				gap: 2px;
				font-size: 1rem;
			}

			button {
				min-width: 50px;
				font-size: 10px;
			}
		}
	}
}

.imageIcon {
	justify-content: center;
	align-items: center;
	width: 80%;
	margin-left: 10%;
}

.PdfEye {
	color: #fff;
	font-size: 30px;
}

@media (max-width: 768px) {
	.estimate-option-card-head {
		.actions {
			display: none;
		}
	}
}