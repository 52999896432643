@import '../../assets/scss/colors';

.user-permissions-box {
	padding: 0.3rem;
	display: flex;
	flex-direction: column;
	gap: 10px;
	color: #000;

	h4 {
		font-size: 13px;
		font-weight: 500;
	}

	.permission-group-title {
		font-size: 1rem;
		padding-bottom: 0;
	}

	.user-permission-group {
		border-bottom: 1px solid $color-light-scale-gray-2-2;
		padding-bottom: 10px;

		&:last-child {
			border-bottom: none;
			padding-bottom: 10px;
		}
	}

	.permission-selectors {
		display: grid;
		grid-template-columns: 50% 50%;
		font-size: 1rem;
		justify-content: center;
		align-items: center;
		padding-bottom: 10px;

		.form-check {
			padding: 0;
			display: flex;
			align-items: center;
			gap: 5px;

			input[type='checkbox'] {
				margin: 0;
				position: static;
			}

			input[type='checkbox']:checked~label::before {
				background-color: green !important;
			}

			input[type='checkbox']:focus~label::before {
				box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
			}

			input[type='checkbox']:active~label::before {
				background-color: #c8ffc8;
			}
		}
	}
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background-color: $color-primary !important;
	border: 2px solid transparent;
}

.custom-control-label::before {
	border: 2px solid transparent;
	background-color: #ebebeb;
}

.custom-checkbox .custom-control-label::before {
	border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked:focus~.custom-control-label::before {
	box-shadow: none;
	border: 2px solid transparent;
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
	box-shadow: none;
	border: 2px solid transparent;
}

.custom-checkbox .custom-control-input:active~.custom-control-label::before {
	background-color: #ebebeb;
	border: 2px solid transparent;
	box-shadow: none;
}

.custom-control-label::after {
	background-repeat: no-repeat;
	background-size: 70%;
	background-position: 2px 1.5px;
	background-color: transparent;
	border: 2px solid transparent;
	border-radius: 3px;
	box-shadow: none;
}

.custom-control-label::before,
.custom-control-label::after {
	top: 2.5px !important;
	width: 14px !important;
	height: 14px !important;
}